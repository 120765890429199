import React from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';
import { IApolloState } from '../../types/types';

export interface TableProps {
  tableName?: string;
  apolloState: IApolloState;
  customStyleProps?: any;
  children: (props: { apolloState: IApolloState }) => React.ReactNode;
}

const Table: React.FC<TableProps> = ({
  tableName,
  apolloState,
  customStyleProps,
  children,
}) => {
  return (
    <Box as={'section'} height={'full'}>
      <Flex
        mx={'auto'}
        direction={'column'}
        height={'full'}
        w={'full'}
        {...customStyleProps}>
        {tableName && (
          <Heading size={'md'} as={'h1'} mb={'6'}>
            {tableName}
          </Heading>
        )}
        {children({ apolloState })}
      </Flex>
    </Box>
  );
};
export default Table;
