import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Spinner,
  Stack,
  Tag,
  Text,
  Textarea,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit3, FiPlus, FiSave, FiTrash, FiX } from 'react-icons/all';
import { Field, Form, Formik } from 'formik';
import { FieldInputProps, FormikHelpers, FormikValues } from 'formik/dist/types';
import { FetchResult } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import {
  ObjectPropertiesQuery,
  PropertiesOfObjectQuery,
  TagsQuery,
  useModifyObjectPropertyValuesMutation,
  useObjectPropertiesQuery,
  usePropertiesOfObjectQuery,
  useTagsQuery,
} from '../../graphql/types-and-hooks';
import State from '../loading/State';
import {
  IApolloState,
  ITagValue,
  ITagValues,
  Locales,
  PropertyType,
} from '../../types/types';
import {
  ParsedObjectProperty,
  ParsedObjectPropertyValue,
  parsePropertiesOfObject,
  parseProperty,
  parseValueToType,
  parseValueToString,
} from '../../util/query-data-parser/property-parser';
import { propertyValidator } from '../../util/form-validators/properties';
import { PropertiesSkeleton } from '../loading/skeleton/PropertiesSkeleton';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';

interface propertyInputTypeSwitchProps {
  ref: any;
  inEditMode: boolean;
  field: FieldInputProps<any>;
  property: ParsedObjectProperty;
  setFieldValue: FormikHelpers<any>['setFieldValue'];
  tags?: ITagValues;
}

const propertyInputTypeSwitch = (props: propertyInputTypeSwitchProps) => {
  const { property, inEditMode, ref, field, setFieldValue, tags } = props;
  const { type, constraints } = property;
  const { value, ...rest } = field;

  switch (type) {
    case PropertyType.text: {
      const customOnChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>,
        locale: string
      ) => {
        const newValue: string = e.target.value;
        if (inEditMode) {
          const textAlreadyExist: boolean = value.some(
            (item: any) => item.locale === locale
          );
          setFieldValue(
            property.name,
            textAlreadyExist
              ? [
                  ...value.map((item: any) =>
                    item.locale === locale ? { ...item, text: newValue } : item
                  ),
                ]
              : [...value, { locale, text: newValue }]
          );
        }
      };

      return (
        <Stack
          w={'full'}
          p={2}
          borderTop={'1px'}
          borderLeft={'1px'}
          borderBottom={'1px'}
          borderColor={
            inEditMode ? mode('gray.200', 'whiteAlpha.300') : 'rgba(188,188,188,0.1)'
          }
          borderRadius={'sm'}>
          {Object.values(Locales).map((locale, index) => {
            const text = value?.filter(
              (textValue: any) => textValue.locale === locale
            )[0];

            return (
              <React.Fragment key={index}>
                <Text fontSize={'xs'}>{locale}</Text>
                <Textarea
                  size={'sm'}
                  isReadOnly={!inEditMode}
                  disabled={!inEditMode}
                  bg={!inEditMode ? mode('gray.200', 'gray.900') : 'unset'}
                  style={{ userSelect: 'text' }}
                  value={text?.text ?? ''}
                  onChange={(e) => customOnChange(e, locale)}
                />
              </React.Fragment>
            );
          })}
        </Stack>
      );
    }
    case PropertyType.bool:
      return (
        <Select
          bg={!inEditMode ? mode('gray.200', 'gray.900') : 'unset'}
          value={value}
          h={'inherit'}
          ref={ref}
          disabled={!inEditMode}
          {...rest}>
          <option value={'true'}>True</option>
          <option value={'false'}>False</option>
        </Select>
      );
    case PropertyType.tags:
      return (
        <Stack
          opacity={inEditMode ? 1 : 0.4}
          cursor={inEditMode ? 'pointer' : 'not-allowed'}
          bg={!inEditMode ? mode('gray.200', 'gray.900') : 'unset'}
          w={'full'}
          direction={'row'}
          align={'center'}
          justify={'center'}
          p={1}
          flexWrap={'wrap'}
          borderTop={'1px'}
          borderLeft={'1px'}
          borderBottom={'1px'}
          borderColor={
            inEditMode ? mode('gray.200', 'whiteAlpha.300') : 'rgba(188,188,188,0.1)'
          }
          borderRadius={'sm'}>
          {tags?.map((tag: any, index: number) => {
            const selected = !!value?.filter(
              (item: ITagValue) => item.name === tag.name
            ).length;
            return (
              <Box ml={2} key={index}>
                <Tag
                  colorScheme={selected ? 'blue' : 'gray'}
                  variant={selected ? 'solid' : 'outline'}
                  size={'sm'}
                  h={'fit-content'}
                  flexBasis={'fit-content'}
                  m={1}
                  onClick={() => {
                    if (inEditMode) {
                      setFieldValue(
                        property.name,
                        selected
                          ? value?.filter((item: ITagValue) => item.name !== tag.name)
                          : [...value, tag]
                      );
                    }
                  }}
                  {...rest}>
                  {tag.displayName?.text ?? tag.name}
                </Tag>
              </Box>
            );
          })}
        </Stack>
      );
    default:
      // PropertyType.string | PropertyType.number | PropertyType.datetime | PropertyType.date
      if (constraints?.enum) {
        return (
          <Select
            bg={!inEditMode ? mode('gray.200', 'gray.900') : 'unset'}
            value={value}
            ref={ref}
            textTransform={'capitalize'}
            disabled={!inEditMode}
            placeholder={'Select'}
            {...rest}>
            {Object.values(constraints?.enum).map((enumValue: any, index) => (
              <option key={index} value={enumValue}>
                {enumValue}
              </option>
            ))}
          </Select>
        );
      }
      return (
        <Input
          ref={ref}
          disabled={!inEditMode}
          type={(() => {
            switch (type) {
              case PropertyType.string:
                return 'text';
              case PropertyType.datetime:
                return 'datetime-local';
              default:
                return type;
            }
          })()}
          value={value}
          bg={!inEditMode ? mode('gray.200', 'gray.900') : 'unset'}
          h={'inherit'}
          {...rest}
        />
      );
  }
};

interface PropertyProps {
  property: ParsedObjectProperty;
  initialValue: any;
  isNew?: boolean;
  availableTags?: ITagValues;
  modifyPropertyMutation: (
    propertyId: string,
    value: string
  ) => Promise<FetchResult<any>>;
}

const Property: React.FC<PropertyProps> = (props) => {
  const { property, initialValue, isNew, availableTags, modifyPropertyMutation } =
    props;

  const [inEditMode, setInEditMode] = useState<boolean>(!!isNew);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inEditMode) {
      inputRef.current?.focus();
    }
  }, [inEditMode]);

  return (
    <Formik
      initialValues={{
        [property.name]: parseValueToString(initialValue, property.type),
      }}
      enableReinitialize={true}
      validate={(values: any) => propertyValidator(property, values)}
      onSubmit={(values: FormikValues, actions: FormikHelpers<FormikValues>) => {
        const value = JSON.stringify(
          parseValueToType(values[property.name], property.type)
        );

        const propertyMutation: Promise<unknown> = modifyPropertyMutation(
          property.id,
          value
        );

        propertyMutation
          .catch((e) => actions.setFieldError(property.name, e.message))
          .finally(() => {
            actions.setSubmitting(false);
            setInEditMode(false);
          });
      }}>
      {({ isSubmitting, submitForm, resetForm, setFieldValue }) => (
        <Form>
          <Field name={property.name}>
            {({ field, form }: any) => (
              <FormControl
                isInvalid={form.errors[property.name] && form.touched[property.name]}>
                <InputGroup size={'sm'} h={'auto'}>
                  {propertyInputTypeSwitch({
                    ref: inputRef,
                    inEditMode,
                    field,
                    property,
                    setFieldValue,
                    tags: availableTags,
                  })}
                  <InputRightAddon h={'inherit'} px={0}>
                    {isSubmitting && <Spinner m={2} size={'sm'} />}
                    {inEditMode && !isSubmitting ? (
                      <>
                        <IconButton
                          size={'sm'}
                          variant={'ghost'}
                          colorScheme={'green'}
                          icon={isNew ? <FiPlus /> : <FiSave />}
                          aria-label={isNew ? 'Add Property' : 'Change Property'}
                          onClick={async () => {
                            await submitForm();
                          }}
                        />
                        {!isNew && (
                          <IconButton
                            size={'sm'}
                            variant={'ghost'}
                            colorScheme={'red'}
                            icon={<FiX />}
                            aria-label={'Discard'}
                            onClick={() => {
                              resetForm();
                              setInEditMode(false);
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <IconButton
                        size={'sm'}
                        variant={'ghost'}
                        icon={<FiEdit3 />}
                        aria-label={'Edit'}
                        onClick={() => {
                          setInEditMode(true);
                        }}
                      />
                    )}
                  </InputRightAddon>
                </InputGroup>
                <FormErrorMessage>{form.errors[property.name]}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Form>
      )}
    </Formik>
  );
};

const PropertyWithTagData: React.FC<PropertyProps> = ({ property, ...rest }) => {
  const [, tagsParsedData] = useQueryWrapper({
    query: useTagsQuery,
    options: { variables: { type: property.name } },
    parser: useCallback<(data: TagsQuery) => ITagValues>(
      (data) => data?.tags.map((tag: any) => tag),
      []
    ),
  });

  return <Property availableTags={tagsParsedData} property={property} {...rest} />;
};

interface PropertyWrapperProps {
  name?: string;
  removeCallback?: () => Promise<any>;
}

const PropertyWrapper: React.FC<PropertyWrapperProps> = (props) => {
  const { children, removeCallback, name } = props;

  const [removing, setRemoving] = useState<boolean>(false);

  return (
    <Stack p={2} w={'full'}>
      <Stack direction={'row'} spacing={1} align={'center'}>
        {name && (
          <Text fontSize={'sm'} fontWeight={'medium'} textTransform={'capitalize'}>
            {name}
          </Text>
        )}
        <IconButton
          disabled={!removeCallback || removing}
          size={'sm'}
          variant={'ghost'}
          colorScheme={'red'}
          icon={<FiTrash />}
          aria-label={'Remove Property'}
          onClick={() => {
            if (removeCallback && !removing) {
              setRemoving(true);
              removeCallback().finally(() => setRemoving(false));
            }
          }}
        />
        {removing && <Spinner size={'sm'} />}
      </Stack>
      {children}
    </Stack>
  );
};

interface PropertiesBaseProps {
  objectId: string;
}

interface PropertiesWrapperProps {
  properties?: any[];
  objectId: string;
  isNew?: boolean;
  modifyPropertyMutation: (
    propertyId: string,
    value: string
  ) => Promise<FetchResult<any>>;
  removeCallback?: (id: string) => Promise<any>;
}

const PropertiesWrapper: React.FC<PropertiesWrapperProps> = (props) => {
  const { properties, removeCallback, ...rest } = props;

  return (
    <Stack spacing={2}>
      {properties?.map((item, index) => {
        const property = item.property ?? item;
        const value =
          item.value ??
          (PropertyType.tags === property.type || PropertyType.text === property.type
            ? []
            : '');

        return (
          <PropertyWrapper
            key={index}
            name={property.displayName}
            removeCallback={
              removeCallback ? () => removeCallback(property.id) : undefined
            }>
            {PropertyType.tags === property.type ? (
              <PropertyWithTagData
                key={index}
                property={property}
                initialValue={value}
                {...rest}
              />
            ) : (
              <Property
                key={index}
                property={property}
                initialValue={value}
                {...rest}
              />
            )}
          </PropertyWrapper>
        );
      })}
    </Stack>
  );
};

interface PropertiesProps extends PropertiesBaseProps {
  propertiesOfObject: ParsedObjectPropertyValue[] | undefined;
  objectProperties: ParsedObjectProperty[] | undefined;
  apolloState: IApolloState;
  refetchPropertiesOfObject: () => Promise<ApolloQueryResult<any>>;
  modifyObjectPropertyValues: (
    propertyId: string,
    value?: string
  ) => Promise<FetchResult<any>>;
}

export const Properties: React.FC<PropertiesProps> = (props) => {
  const {
    objectId,
    propertiesOfObject,
    objectProperties,
    apolloState,
    refetchPropertiesOfObject,
    modifyObjectPropertyValues,
  } = props;
  const { loading, error } = apolloState;

  const [newPropertiesOfObject, setNewPropertiesOfObject] = useState<
    ParsedObjectProperty[]
  >([]);
  const [usedPropertyIds, setUsedPropertyIds] = useState<string[]>([]);

  useEffect(() => {
    if (propertiesOfObject) {
      setUsedPropertyIds((oldState) =>
        oldState.concat(propertiesOfObject.map((property) => property.property.id))
      );
      setNewPropertiesOfObject((oldState) =>
        oldState.filter(
          (newP) =>
            !propertiesOfObject
              .map((property) => property.property.id)
              .some((propertyId) => propertyId === newP.id)
        )
      );
    }
  }, [propertiesOfObject]);

  const addLocalProperty = (property: ParsedObjectProperty) => {
    setNewPropertiesOfObject((oldState) => [...oldState, property]);
    setUsedPropertyIds((oldState) => oldState.concat([property.id]));
  };

  const removeLocalProperty = (idOfPropertyToRemove: string) => {
    return new Promise((resolve) => {
      setNewPropertiesOfObject((oldState) =>
        oldState.filter((p) => p.id !== idOfPropertyToRemove)
      );
      setUsedPropertyIds((oldState) =>
        oldState.filter((id) => id !== idOfPropertyToRemove)
      );
      resolve({
        removed: idOfPropertyToRemove,
      });
    });
  };

  const modifyPropertyMutation = (propertyId: string, value: string) => {
    return modifyObjectPropertyValues(propertyId, value).then(() =>
      refetchPropertiesOfObject()
    );
  };

  const removePropertyMutation = (id: string) => {
    return modifyObjectPropertyValues(id)
      .then(() => refetchPropertiesOfObject())
      .then(() => removeLocalProperty(id));
  };

  return (
    <State
      loading={loading}
      error={error}
      customLoadingElement={<PropertiesSkeleton />}>
      <Stack margin={'auto'} spacing={4} w={'100%'} maxW={'xl'}>
        <Box>
          <Menu>
            <MenuButton
              w={'min-content'}
              size={'xs'}
              as={Button}
              colorScheme={'blue'}
              leftIcon={<FiPlus />}>
              Add Property
            </MenuButton>
            <MenuList>
              {objectProperties
                ?.filter((property) => !usedPropertyIds.includes(property.id))
                .map((property, index) => (
                  <MenuItem
                    key={index}
                    fontSize={'sm'}
                    textTransform={'capitalize'}
                    onClick={() => addLocalProperty(property)}>
                    {property.displayName}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </Box>
        {newPropertiesOfObject.length > 0 && (
          <PropertiesWrapper
            objectId={objectId}
            properties={newPropertiesOfObject}
            removeCallback={removeLocalProperty}
            modifyPropertyMutation={modifyPropertyMutation}
            isNew={true}
          />
        )}
        <PropertiesWrapper
          properties={propertiesOfObject}
          objectId={objectId}
          modifyPropertyMutation={modifyPropertyMutation}
          removeCallback={removePropertyMutation}
        />
      </Stack>
    </State>
  );
};

export const PropertiesWithData: React.FC<PropertiesBaseProps> = (props) => {
  const { objectId } = props;

  const [objectPropertiesQueryResult, objectPropertiesParsedData] = useQueryWrapper({
    query: useObjectPropertiesQuery,
    parser: useCallback<(data: ObjectPropertiesQuery) => ParsedObjectProperty[]>(
      (data) => data.objectProperties.map((property) => parseProperty(property)),
      []
    ),
  });

  const [propertiesOfObjectQueryResult, propertiesOfObjectParsedData] = useQueryWrapper(
    {
      query: usePropertiesOfObjectQuery,
      options: {
        variables: { objectId },
      },
      parser: useCallback<
        (data: PropertiesOfObjectQuery) => ParsedObjectPropertyValue[]
      >((data) => parsePropertiesOfObject(data.propertiesOfObject), []),
    }
  );

  const [modifyObjectPropertyValues] = useModifyObjectPropertyValuesMutation();

  const modifyObjectPropertyValuesHandler = (propertyId: string, value?: string) => {
    return modifyObjectPropertyValues({
      variables: {
        objectId,
        input: [{ propertyId, value }],
      },
    });
  };

  return (
    <Properties
      propertiesOfObject={propertiesOfObjectParsedData}
      objectProperties={objectPropertiesParsedData}
      apolloState={{
        loading:
          (propertiesOfObjectQueryResult.loading &&
            !propertiesOfObjectQueryResult.refetching) ||
          (objectPropertiesQueryResult.loading &&
            !objectPropertiesQueryResult.refetching),
        error: propertiesOfObjectQueryResult.error || objectPropertiesQueryResult.error,
      }}
      refetchPropertiesOfObject={propertiesOfObjectQueryResult.refetch}
      modifyObjectPropertyValues={modifyObjectPropertyValuesHandler}
      {...props}
    />
  );
};
