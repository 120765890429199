import React, { ReactElement, useState } from 'react';
import { Flex, IconButton, Skeleton, Spinner, Stack, Text } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiWifiOff } from 'react-icons/all';
import { ClipboardButton } from '../buttons/ClipboardButton';
import { IApolloState } from '../../types/types';

interface ExpandErrorMessageProps {
  message?: string;
  genericMessage?: string;
  color?: string;
}

export const ExpandErrorMessage: React.FC<ExpandErrorMessageProps> = (props) => {
  const {
    message,
    genericMessage = 'An error has occurred while loading the data!',
    color = 'red',
  } = props;

  const [expandError, setExpandError] = useState<boolean>(false);

  return (
    <Stack
      width={'full'}
      height={'full'}
      justify={'center'}
      direction={'column'}
      align={'center'}>
      <Text color={color} fontSize={'sm'}>
        {genericMessage}
      </Text>
      {message && (
        <>
          <IconButton
            onClick={() => setExpandError((expand) => !expand)}
            variant={'ghost'}
            size={'xs'}
            aria-label={'Expand Error Message'}
            icon={
              expandError ? (
                <FiChevronUp size={'18px'} />
              ) : (
                <FiChevronDown size={'18px'} />
              )
            }
          />

          {expandError && (
            <>
              <ClipboardButton
                colorScheme={color}
                title={'Copy Error Message'}
                valueToCopy={message}
              />
              <Text mW={'inherit'} color={color} fontSize={'xs'}>
                {message}
              </Text>
            </>
          )}
        </>
      )}
    </Stack>
  );
};

interface StateProps extends IApolloState {
  customErrorElement?: React.ReactNode;
  customLoadingElement?: React.ReactNode;
  skeletonWrapper?: boolean;
}

const State: React.FC<StateProps> = (props): ReactElement<any, any> | null => {
  const {
    loading,
    skeletonWrapper,
    children,
    error,
    customErrorElement,
    customLoadingElement,
  } = props;

  if (error) {
    if (!navigator.onLine && error?.message === 'Failed to fetch') {
      return (
        <Flex width={'full'} height={'full'} justify={'center'} align={'center'} p={2}>
          <FiWifiOff />
        </Flex>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{customErrorElement ?? <ExpandErrorMessage message={error?.message} />}</>;
  }

  if (loading && !skeletonWrapper)
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {customLoadingElement ?? (
          <Flex width={'full'} height={'full'} justify={'center'} align={'center'}>
            <Spinner speed={'0.65s'} emptyColor={'gray.200'} color={'blue.500'} />
          </Flex>
        )}
      </>
    );

  return skeletonWrapper && children ? (
    <Skeleton isLoaded={!loading}>{children}</Skeleton>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export default State;
