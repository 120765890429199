import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  StackDivider,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { ChatWithData } from '../components/chat/Chat';
import ActiveChats from '../components/chat/ActiveChats';
import { parseQueriedChatsToMap } from '../util/query-data-parser/chat-parser';
import { useQuery } from '../util/hooks/useQuery';
import {
  ChatsFilterOptionsQuery,
  ChatsQuery,
  LandlordType,
  useChatsFilterOptionsQuery,
  useChatsQuery,
} from '../graphql/types-and-hooks';
import { useQueryWrapper } from '../util/hooks/useQueryWrapper';
import { RefetchButton } from '../components/buttons/RefetchButton';
import { AddFilter } from '../components/filter/AddFilter';
import { FilterTypeEnum, IFilters } from '../types/types';
import { FilterWrapper } from '../components/filter/FilterWrapper';
import { Filters } from '../components/filter/Filters';
import { useFilter } from '../util/hooks/useFilter';

type TChatsFilterAccessors = 'landlordId' | 'objectId' | 'messageCount';

const chatsFilters: IFilters<TChatsFilterAccessors> = [
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Company',
    accessor: 'landlordId',
    defaultValue: null,
    options: [{ value: null, label: 'All' }],
    loadingInitialOptions: true,
  },
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Object',
    accessor: 'objectId',
    defaultValue: null,
    options: [{ value: null, label: 'Select an object...' }],
    loadingInitialOptions: true,
    addedByDefault: true,
    hiddenInMenu: true,
  },
  {
    __typename: FilterTypeEnum.NUMBER,
    label: 'Message Count',
    accessor: 'messageCount',
    defaultValue: '2',
  },
];

const Chats = () => {
  const { filterValues, statefulFilters, replaceInitialFilters } = useFilter<
    TChatsFilterAccessors,
    string | null
  >({ filters: chatsFilters });

  const [chatIdsQueryResult, chatIdsParsedData] = useQueryWrapper({
    options: {
      variables: {
        filter: {
          // TODO: This is a workaround for now. The query is not executed (skip) when objectId is null.
          //  Once executed the objectId cannot be null.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          objectId: filterValues.objectId!,
          messageCount: Number(filterValues.messageCount),
        },
      },
      skip: filterValues.objectId === null,
    },
    query: useChatsQuery,
    parser: useCallback<(data: ChatsQuery) => Map<string, ChatsQuery['chats'][0]>>(
      (data) => parseQueriedChatsToMap(data.chats),
      []
    ),
  });

  const chatIds = useMemo(() => {
    if (filterValues.objectId === null) return undefined;
    return chatIdsParsedData;
  }, [chatIdsParsedData, filterValues.objectId]);

  const [, chatsFilterOptionsParsedData] = useQueryWrapper({
    options: {
      variables: {
        objectsFilter: {
          search: filterValues?.landlordId,
          landlordType: LandlordType.Company,
        },
      },
    },
    query: useChatsFilterOptionsQuery,
    parser: useCallback<
      (data: ChatsFilterOptionsQuery) => {
        companies: { value: string; label: string }[];
        objects: { value: string; label: string }[];
      }
    >(
      (data) => ({
        companies: data.companies.map((company) => ({
          value: company.landlordId,
          label: company.name,
        })),
        objects: data.objects.data.map((object) => ({
          value: object.id,
          label: object.internalNote ?? object.id,
        })),
      }),
      []
    ),
  });

  const history = useHistory();
  const { query } = useQuery();
  const chatId = query.get('chatId') ?? '';

  const [open, setOpen] = useState<boolean>(chatId === '');

  useEffect(() => {
    if (chatId === '') {
      setOpen(true);
    }
  }, [chatId]);

  useEffect(() => {
    if (chatsFilterOptionsParsedData !== undefined) {
      const filters = chatsFilters.map((filter) => {
        let data: any[] | undefined;
        if (filter.accessor === 'objectId') {
          data = chatsFilterOptionsParsedData?.objects;
        } else if (filter.accessor === 'landlordId') {
          data = chatsFilterOptionsParsedData?.companies;
        }
        if (data === undefined)
          return {
            ...filter,
          };
        return {
          ...filter,
          options: filter.__typename === 'Select' ? [...filter.options, ...data] : [],
          loadingInitialOptions: false,
        };
      });
      replaceInitialFilters(filters);
    }
  }, [chatsFilterOptionsParsedData, replaceInitialFilters]);

  const openCallback = useCallback(() => {
    setOpen(true);
  }, []);

  if (
    chatIds?.get(chatId) === undefined &&
    chatId !== '' &&
    !chatIdsQueryResult.loading
  ) {
    history.push('/chats');
  }

  return (
    <Stack height={'full'} direction={'column'}>
      <Flex mb={'6'}>
        <Heading size={'md'} as={'h1'} mr={2} lineHeight={''}>
          Chat View
        </Heading>
        <RefetchButton />
      </Flex>
      <Stack
        m={0}
        height={'calc(100% - 24px - var(--chakra-space-6) - 1rem)'}
        divider={
          <StackDivider
            borderColor={mode('gray.200', 'gray.800')}
            display={{ base: 'none', lg: 'block' }}
          />
        }
        spacing={1}
        direction={'row'}
        justifyContent={'center'}>
        <Stack
          width={{
            base: '90vw',
            md: `calc(100vw - var(--sidebar-width) - (2 * var(--chakra-space-3)) - (2 * var(--content-p-inner-md)) - (2 * var(--content-p-outer-md)))`,
            lg: '250px',
          }}
          divider={<StackDivider />}
          display={{ base: open ? 'flex' : 'none', lg: 'flex' }}>
          <FilterWrapper filters={statefulFilters}>
            {({ filters, filterAccessorsInUse, addFilter, removeFilter }) => (
              <>
                <AddFilter
                  filters={filters}
                  filterAccessorsInUse={filterAccessorsInUse}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                />
                <Filters
                  filters={filters}
                  filterAccessorsInUse={filterAccessorsInUse}
                />
              </>
            )}
          </FilterWrapper>
          <ActiveChats
            openCallback={() => setOpen(false)}
            chats={filterValues.objectId !== null ? chatIds : undefined}
            apolloState={{
              loading: chatIdsQueryResult.loading && !chatIdsQueryResult.refetching,
              error: chatIdsQueryResult.error,
            }}
          />
        </Stack>
        <Box
          ml={{ base: 0, lg: -1 }}
          borderRightWidth={{ base: 0, lg: '1px' }}
          borderRightColor={mode('gray.200', 'gray.800')}
          width={{ base: '100%', lg: 'calc(100% - 250px - 0.5rem)' }}
          display={{ base: open ? 'none' : 'block', lg: 'block' }}>
          {chatId !== '' ? (
            <ChatWithData openCallback={openCallback} chatId={chatId} />
          ) : (
            <Flex h={'full'} w={'full'} justify={'center'} align={'center'}>
              Click on a chat to open it.
            </Flex>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Chats;
