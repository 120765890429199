import { IDict, IFormData } from '../../types/types';

export const createB2bUserValidator = (values: IFormData) => {
  const errors: IDict<any> = {};

  if ('email' in values) {
    if (values.email === '') errors.email = 'This Field is required!';
  }

  return errors;
};
