import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormWrapper } from '../../../components/form/Form';
import { createCompanyFormMapping } from '../../../data/valueMapping';
import { useCreateCompanyMutation } from '../../../graphql/types-and-hooks';
import { Modal } from '../../../components/modal/Modal';
import { createCompanyValidator } from '../../../util/form-validators/create-company';

export const CreateCompanyFormModal = ({
  title,
  modalDisclosure,
}: {
  title: string;
  modalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}) => {
  const history = useHistory();

  const [createCompanyMutation, createCompanyMutationResult] =
    useCreateCompanyMutation();

  return (
    <FormWrapper
      initialData={{ companyName: '' }}
      dataMapping={createCompanyFormMapping}
      inEditMode={true}
      submitFunction={(formData) =>
        createCompanyMutation({
          variables: {
            data: {
              companyName: formData?.companyName,
            },
          },
        }).then(() => history.push(`/landlords`))
      }
      validator={createCompanyValidator}>
      {(props) => (
        <Modal
          title={title}
          primaryAction={{
            title: 'Create',
            callback: () => props.formik.submitForm(),
            loading: createCompanyMutationResult.loading,
          }}
          secondaryAction={{
            title: 'Cancel',
            callback: () => props.formik.resetForm(),
          }}
          disclosureOverwrite={modalDisclosure}>
          <Form {...props} />
        </Modal>
      )}
    </FormWrapper>
  );
};
