import { Box, HStack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { BsCaretRightFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

interface NavItemProps extends InnerNavItemProps {
  href?: string;
}

interface InnerNavItemProps {
  label: string;
  subtle?: boolean;
  active?: boolean;
  disabled?: boolean;
  icon: React.ReactElement;
  endElement?: React.ReactElement;
  children?: React.ReactNode;
}

const InnerNavItem = (props: InnerNavItemProps) => {
  const { active, subtle, icon, children, label, endElement, disabled } = props;
  return (
    <HStack
      w={'full'}
      px={'3'}
      py={'2'}
      cursor={disabled ? 'not-allowed' : 'pointer'}
      userSelect={'none'}
      rounded={'md'}
      transition={'all 0.2s'}
      bg={active ? 'gray.700' : undefined}
      _hover={disabled ? {} : { bg: 'gray.700' }}
      _active={disabled ? {} : { bg: 'gray.600' }}>
      <Box fontSize={'lg'} color={active ? 'currentcolor' : 'gray.400'}>
        {icon}
      </Box>
      <Box
        flex={'1'}
        fontWeight={'inherit'}
        color={subtle || disabled ? 'gray.400' : undefined}>
        {label}
      </Box>
      {disabled ? (
        <Text color={'gray.400'} fontSize={12}>
          Coming Soon
        </Text>
      ) : (
        endElement && !children && <Box>{endElement}</Box>
      )}
      {children && <Box fontSize={'xs'} flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  );
};

export const NavItem = (props: NavItemProps) => {
  const { href } = props;
  return href ? (
    <Link to={href}>
      <InnerNavItem {...props} />
    </Link>
  ) : (
    <InnerNavItem {...props} />
  );
};
