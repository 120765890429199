import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Stack, Heading, Flex, Box, useDisclosure } from '@chakra-ui/react';
import { FiMoreHorizontal, FiPlus } from 'react-icons/all';
import { FieldGroupViewSkeleton } from '../../components/loading/skeleton/FieldGroupSkeleton';
import { CompanyQuery, useCompanyQuery } from '../../graphql/types-and-hooks';
import State from '../../components/loading/State';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { Tabs } from '../../components/tabs/Tabs';
import { ClipboardButton } from '../../components/buttons/ClipboardButton';
import { TableActions } from '../../components/table/TableActions';
import { TableContent } from '../../components/table/TableContent';
import { b2bUserColumns } from './data/columns';
import Table from '../../components/table/Table';
import { AddUserToCompany } from './components/AddUserToCompany';
import { CreateB2bUser } from './components/CreateB2bUser';

type CompanyParserResult = CompanyQuery['company'];

const Company = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const [companyQueryResult, companyParsedData] = useQueryWrapper({
    query: useCompanyQuery,
    options: { variables: { id: companyId } },
    parser: useCallback<(data: CompanyQuery) => CompanyParserResult>(
      (data) => data.company,
      []
    ),
  });

  const addUserModalDisclosure = useDisclosure();
  const createB2bUserModalDisclosure = useDisclosure();

  useEffect(() => {
    if (!addUserModalDisclosure.isOpen && !createB2bUserModalDisclosure.isOpen)
      companyQueryResult.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserModalDisclosure.isOpen, createB2bUserModalDisclosure.isOpen]);

  return (
    <>
      <AddUserToCompany
        modalDisclosure={addUserModalDisclosure}
        title={'Add User'}
        existingUsers={companyParsedData?.users.map((user) => user.id) ?? []}
      />
      <CreateB2bUser
        modalDisclosure={createB2bUserModalDisclosure}
        title={'Create new User'}
      />
      <State
        loading={companyQueryResult.loading && !companyQueryResult.refetching}
        customLoadingElement={<FieldGroupViewSkeleton repeat={1} />}
        error={companyQueryResult.error}>
        {companyParsedData && (
          <Stack height={'full'}>
            <Heading size={'sm'}>{companyParsedData.name}</Heading>
            <Stack direction={'row'} align={'center'} mt={1} flexWrap={'wrap'}>
              <ClipboardButton
                title={'Copy Company ID'}
                valueToCopy={companyParsedData.id}
              />
              <ClipboardButton
                title={'Copy Landlord ID'}
                valueToCopy={companyParsedData.landlordId}
              />
            </Stack>
            <Tabs
              panels={[
                {
                  title: 'Users',
                  child: (
                    <Table
                      apolloState={{
                        loading:
                          companyQueryResult.loading && !companyQueryResult.refetching,
                        error: companyQueryResult.error,
                      }}>
                      {({ apolloState }) => (
                        <>
                          <Flex justify={'end'}>
                            <Box w={'min-content'}>
                              <TableActions
                                actions={[
                                  {
                                    icon: <FiMoreHorizontal />,
                                    actions: [
                                      {
                                        title: 'Add user',
                                        icon: <FiPlus />,
                                        onClick: () => addUserModalDisclosure.onOpen(),
                                      },
                                      {
                                        title: 'Create new user',
                                        icon: <FiPlus />,
                                        onClick: () =>
                                          createB2bUserModalDisclosure.onOpen(),
                                      },
                                    ],
                                  },
                                ]}
                              />
                            </Box>
                          </Flex>
                          <TableContent
                            apolloState={apolloState}
                            columns={b2bUserColumns}
                            data={companyParsedData.users.map(
                              (user) => new Map(Object.entries(user))
                            )}
                          />
                        </>
                      )}
                    </Table>
                  ),
                },
              ]}
            />
          </Stack>
        )}
      </State>
    </>
  );
};

export default Company;
