import React, { useEffect, useRef, useState } from 'react';
import {
  Input,
  Flex,
  Box,
  InputRightElement,
  InputGroup,
  Spinner,
} from '@chakra-ui/react';
import { ApolloQueryResult } from '@apollo/client';
import { FiEdit3 } from 'react-icons/all';
import { toast } from 'react-toastify';

interface EditableTagProps {
  text?: string;
  placeholder?: string;
  submitCallback?: (
    updatedNote: string | undefined
  ) => Promise<Promise<ApolloQueryResult<any>> | ApolloQueryResult<any>>;
}
export const EditableTag: React.FC<EditableTagProps> = (props) => {
  const { text, placeholder = '...', submitCallback } = props;

  const [textState, setTextState] = useState<string | undefined>(text);
  const [inputWidth, setInputWidth] = useState<number>(50);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const hiddenText = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const discardChanges = () => {
    setTextState(text);
  };

  useEffect(() => {
    if (hiddenText.current !== null) {
      setInputWidth(
        hiddenText.current.offsetWidth < 15 ? 15 : hiddenText.current.offsetWidth
      );
    }
  }, [textState]);

  const inputHandler = ({ target: { value } }: { target: { value: string } }) => {
    setTextState(value);
  };

  const submit = () => {
    if (submitCallback && textState !== text) {
      setSubmitting(true);
      const promise = submitCallback(textState);
      if (promise !== undefined) {
        promise
          .catch((error: Error) => toast.error(error.message))
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  };

  return (
    <Flex direction={'row'} align={'space-between'}>
      <Box
        as={'span'}
        position={'absolute'}
        whiteSpace={'pre'}
        fontWeight={'bold'}
        fontSize={'xs'}
        opacity={0}
        ref={hiddenText}>
        {textState === '' || !textState ? placeholder : textState}
      </Box>
      <InputGroup borderRadius={6} overflow={'hidden'} size={'xs'}>
        <Input
          cursor={submitting ? 'not-allowed' : 'inherit'}
          disabled={submitting}
          ref={inputRef}
          backgroundColor={'blue.100'}
          px={1}
          py={'3px'}
          color={'blue.800'}
          fontSize={'xs'}
          fontWeight={'bold'}
          variant={'unstyled'}
          colorScheme={'blue'}
          value={textState ?? ''}
          _placeholder={{ color: 'gray.400' }}
          placeholder={placeholder}
          width={`${inputWidth + 8 + 20}px`}
          onChange={inputHandler}
          onBlur={() => discardChanges()}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              submit();
            }
          }}
        />

        <InputRightElement
          onClick={() => {
            if (!submitting) {
              inputRef?.current?.focus();
            }
          }}
          cursor={submitting ? 'not-allowed' : 'pointer'}>
          {submitting ? <Spinner color={'blue.800'} size={'sm'} /> : <FiEdit3 />}
        </InputRightElement>
      </InputGroup>
    </Flex>
  );
};
