import React, { useState } from 'react';
import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Stack,
  Box,
  Flex,
} from '@chakra-ui/react';
import { UserCell } from '../cell/UserCell';
import { ChatParticipantType, ChatQuery } from '../../graphql/types-and-hooks';
import { AvatarGroup } from '../avatar/AvatarGroup';

type ParticipantPopoverProps = {
  participants: ChatQuery['chat']['participants'];
  objectId: ChatQuery['chat']['objectData']['id'];
};

const ParticipantPopover: React.FC<ParticipantPopoverProps> = (props) => {
  const { participants, objectId } = props;

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Popover isOpen={open} onClose={() => setOpen(false)} placement={'bottom-start'}>
      <PopoverTrigger>
        <Box width={'fit-content'} onClick={() => setOpen(true)} cursor={'pointer'}>
          <AvatarGroup participants={participants} />
        </Box>
      </PopoverTrigger>
      <PopoverContent maxW={'full'} mt={2} maxH={'250px'} overflowY={'auto'}>
        <PopoverBody>
          <Stack>
            {participants.map((participant, index) => {
              const isLandlord = participant.type === ChatParticipantType.Landlord;
              const isMe = participant.me;

              return (
                <Stack
                  direction={'row'}
                  justify={'space-between'}
                  align={'center'}
                  key={index}>
                  <UserCell
                    image={participant.image?.url}
                    firstName={participant.name?.text}
                    pathnameToOpen={
                      !isMe || isLandlord
                        ? `${
                            isLandlord
                              ? `/objects/${objectId}`
                              : `/users/${participant?.userId}`
                          }`
                        : undefined
                    }
                  />
                  <Flex align={'center'}>
                    {isMe && (
                      <Text fontSize={'sm'} color={'gray.500'} mr={2}>
                        (you)
                      </Text>
                    )}
                  </Flex>
                </Stack>
              );
            })}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ParticipantPopover;
