import { Avatar, AvatarBadge, Box, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FiExternalLink } from 'react-icons/all';
import { useHistory } from 'react-router-dom';

interface UserDataProps {
  firstName?: string;
  lastName?: string;
  email?: string;
  emailLink?: boolean;
  phoneNumber?: string;
  phoneNumberLink?: boolean;
}

const UserData: React.FC<UserDataProps> = (props) => {
  const { email, emailLink, phoneNumber, phoneNumberLink, firstName, lastName } = props;
  const name = `${firstName ?? ''} ${lastName ?? ''}`;

  return (
    <>
      <Box fontSize={'sm'} fontWeight={'medium'}>
        <Text isTruncated={true}>{name}</Text>
      </Box>
      {phoneNumber && (
        <Box fontSize={'xs'} opacity={'0.7'}>
          {phoneNumberLink ? (
            <Link display={'block'} isTruncated={true} href={`tel://${phoneNumber}`}>
              {phoneNumber}
            </Link>
          ) : (
            <Text isTruncated={true}>{phoneNumber}</Text>
          )}
        </Box>
      )}
      <Box fontSize={'xs'} opacity={'0.7'}>
        {emailLink ? (
          <Link display={'block'} isTruncated={true} href={`mailto:${email}`}>
            {email}
          </Link>
        ) : (
          <Text isTruncated={true}>{email}</Text>
        )}
      </Box>
    </>
  );
};

export interface UserCellProps extends UserDataProps {
  image: string;
  imageSize?: string;
  pathnameToOpen?: string;
}

export const UserCell = (props: UserCellProps) => {
  const { image, firstName, lastName, imageSize, pathnameToOpen, ...rest } = props;
  const name = `${firstName ?? ''} ${lastName ?? ''}`;

  const history = useHistory();

  return (
    <Stack direction={'row'} spacing={2} align={'center'}>
      <Box flexShrink={0}>
        <Avatar
          cursor={'pointer'}
          onClick={() => {
            if (pathnameToOpen) {
              history.push(pathnameToOpen);
            }
          }}
          name={name.trim()}
          objectFit={'cover'}
          size={imageSize ?? 'sm'}
          src={image}>
          {pathnameToOpen && (
            <AvatarBadge
              bg={'blue.600'}
              p={'2px'}
              borderColor={'white'}
              borderWidth={'1px'}>
              <FiExternalLink color={'white'} />
            </AvatarBadge>
          )}
        </Avatar>
      </Box>
      <Box lineHeight={4}>
        <UserData firstName={firstName} lastName={lastName} {...rest} />
      </Box>
    </Stack>
  );
};
