import { Operation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  customMutationToastMessages,
  defaultMutationToastInstructions,
  defaultMutationToastMessages,
  IMutationInstructions,
  IMutationMessages,
  mutationToastInstructions,
  mutationToastMessagesAppendix,
} from '../../data/mutationToast';

const getToastMessages = (operationName: string): IMutationMessages => {
  return customMutationToastMessages[operationName] ?? defaultMutationToastMessages;
};

const getToastInstructions = (operationName: string): IMutationInstructions => {
  return {
    ...defaultMutationToastInstructions,
    ...(mutationToastInstructions[operationName] ?? {}),
  };
};

export const initMutationToast = (operation: Operation) => {
  if (getToastInstructions(operation.operationName).loading) {
    const toastMessages = getToastMessages(operation.operationName);

    return toast.loading(toastMessages.loading + mutationToastMessagesAppendix.loading);
  }
  return null;
};

export const updateMutationToast = (
  operation: Operation,
  type: 'error' | 'success'
) => {
  if (getToastInstructions(operation.operationName)[type]) {
    const toastMessages = getToastMessages(operation.operationName);
    const toastId = operation.getContext()?.toastId;

    if (toastId)
      toast.update(toastId, {
        render: toastMessages[type] + mutationToastMessagesAppendix[type],
        type,
        isLoading: false,
        autoClose: type === toast.TYPE.ERROR ? 5000 : 3000,
        closeButton: true,
        closeOnClick: false,
      });
    else
      toast(toastMessages[type] + mutationToastMessagesAppendix[type], {
        type,
        isLoading: false,
        autoClose: type === toast.TYPE.ERROR ? 5000 : 3000,
        closeButton: true,
        closeOnClick: false,
      });
  }
};
