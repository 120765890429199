import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { FiWifi, FiWifiOff } from 'react-icons/all';
import { ApolloClient } from '@apollo/client/core';

interface OnlineStatusProps {
  refetchActiveQueries?: boolean;
  client?: ApolloClient<any>;
}

export const useOnlineStatus = (props: OnlineStatusProps) => {
  const { refetchActiveQueries = true, client } = props;
  const [status, setStatus] = useState<'online' | 'offline'>(
    navigator.onLine ? 'online' : 'offline'
  );

  useEffect(() => {
    const updateOnlineStatus = () => {
      const condition: 'online' | 'offline' = navigator.onLine ? 'online' : 'offline';
      const online = condition === 'online';

      setStatus(condition);

      if (refetchActiveQueries && online && client) {
        client.refetchQueries({
          include: 'active',
        });
      }

      const ONLINE_STATUS_ID = 'onlineStatusId';

      const content = online
        ? 'The connection is back!'
        : 'The app is running offline.';
      const type = online ? toast.TYPE.SUCCESS : toast.TYPE.ERROR;
      const autoClose = online ? 5000 : false;
      const closeButton = online;
      const closeOnClick = online;
      const draggable = online;
      const icon = online ? FiWifi : FiWifiOff;

      if (!toast.isActive(ONLINE_STATUS_ID)) {
        toast(content, {
          toastId: ONLINE_STATUS_ID,
          type,
          autoClose,
          closeButton,
          closeOnClick,
          draggable,
          icon,
          theme: 'colored',
        });
      } else {
        toast.update(ONLINE_STATUS_ID, {
          type,
          autoClose,
          closeButton,
          closeOnClick,
          draggable,
          icon,
          render: content,
        });
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, [refetchActiveQueries, client]);

  return useMemo(
    () => ({
      status,
    }),
    [status]
  );
};
