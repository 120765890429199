// Custom Errors

// eslint-disable-next-line max-classes-per-file
export class NothingChangedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'NothingChangedError';
  }
}

export class SubmissionError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = 'SubmissionError';
  }
}
