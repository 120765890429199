import React from 'react';
import { Text } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../components/buttons/ColorModeSwitcher';

const Settings = () => (
  <Text fontSize={'sm'}>
    Color Mode: <ColorModeSwitcher />
  </Text>
);

export default Settings;
