import React from 'react';
import {
  AspectRatio,
  BorderProps,
  Box,
  Flex,
  Image,
  useColorModeValue as mode,
} from '@chakra-ui/react';

interface ImageCardProps {
  imageUrl?: string;
  width?: number;
  ratio?: number;
  noImageElement?: React.ReactNode;
  borderColor?: BorderProps['borderColor'];
}

export const ImageCard: React.FC<ImageCardProps> = (props) => {
  const {
    imageUrl,
    width = 75,
    ratio = 8 / 10,
    noImageElement = <>No Image</>,
    borderColor,
  } = props;

  return (
    <Box
      width={'min-content'}
      border={borderColor ? '2px' : 0}
      borderColor={borderColor ?? ''}
      borderRadius={12}
      overflow={'hidden'}>
      {imageUrl ? (
        <AspectRatio ratio={ratio} w={`${width}px`}>
          <Image src={imageUrl} objectFit={'cover'} />
        </AspectRatio>
      ) : (
        <Flex
          justify={'center'}
          align={'center'}
          bg={mode('gray.200', 'gray.800')}
          w={`${width}px`}
          h={`${width / ratio}px`}>
          {noImageElement}
        </Flex>
      )}
    </Box>
  );
};
