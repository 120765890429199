import {
  Box,
  Button,
  Flex,
  FormControl,
  Spinner,
  Stack,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { breakpoints } from '../../data/breakpoints';

interface ActionProps {
  actionType: 'toggle' | 'button';
  title: string;
  description: string | React.ReactNode;
  toggle?: {
    onToggle: (e: React.ChangeEvent<HTMLInputElement>) => Promise<unknown>;
    isChecked: boolean;
    disabled?: boolean;
  };
  button?: {
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<unknown>;
    colorScheme?: string;
    title: string;
    disabled?: boolean;
  };
}

const Action: React.FC<ActionProps> = (props) => {
  const { actionType, title, description, toggle, button } = props;

  if (actionType !== 'toggle' && actionType !== 'button')
    throw new Error("Action type must be 'toggle' or 'button'");

  const [handlingClick, setHandlingClick] = useState<boolean>(false);

  const onToggleHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandlingClick(true);
    toggle
      ?.onToggle(e)
      ?.catch((error: Error) => toast.error(error.message))
      .finally(() => setHandlingClick(false));
  };

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setHandlingClick(true);
    button
      ?.onClick(e)
      ?.catch((error: Error) => toast.error(error.message))
      .finally(() => setHandlingClick(false));
  };

  return (
    <Stack
      width={'full'}
      spacing={'4'}
      justify={'space-between'}
      direction={{ base: 'column', [breakpoints.content]: 'row' }}
      opacity={button?.disabled || toggle?.disabled ? 0.3 : 1}>
      <Box>
        <Text fontWeight={'bold'}>{title}</Text>
        {typeof description === 'string' ? (
          <Text fontSize={'sm'} maxW={'450px'}>
            {description}
          </Text>
        ) : (
          <Box fontSize={'sm'} maxW={'450px'}>
            {description}
          </Box>
        )}
      </Box>
      <Flex align={'center'}>
        {actionType === 'toggle' ? (
          <FormControl>
            {handlingClick && <Spinner mr={2} size={'sm'} />}
            <Switch
              size={'md'}
              isReadOnly={handlingClick}
              colorScheme={handlingClick ? 'inherit' : 'blue'}
              onChange={onToggleHandler}
              {...toggle}
            />
          </FormControl>
        ) : (
          <Button
            isLoading={handlingClick}
            size={'sm'}
            fontSize={'sm'}
            {...button}
            onClick={onClickHandler}>
            {button?.title}
          </Button>
        )}
      </Flex>
    </Stack>
  );
};

interface ActionZoneProps {
  actions: ActionProps[];
}

export const ActionZone: React.FC<ActionZoneProps> = (props) => {
  const { actions } = props;

  return (
    <VStack width={'full'} spacing={'4'}>
      {actions.map((action, index) => (
        <Action key={index} {...action} />
      ))}
    </VStack>
  );
};
