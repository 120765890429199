import React from 'react';
import {
  Grid,
  GridProps,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButtonProps,
} from '@chakra-ui/react';
import { breakpoints } from '../../data/breakpoints';
import { RefetchButton } from './RefetchButton';

interface IAction {
  action?: () => void;
  actionTitle?: string;
}

interface ActionMenuProps extends IconButtonProps {
  menuItems: Array<IAction>;
}

export const ActionMenu = (props: ActionMenuProps) => {
  const { menuItems, ...rest } = props;

  return (
    <Menu>
      <MenuButton size={'xs'} as={IconButton} {...rest} />
      <MenuList>
        {menuItems.map((item, index) => (
          <MenuItem onClick={item.action} isDisabled={!item.action} key={index}>
            {item.actionTitle}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

interface ActionButtonProps extends IAction, IconButtonProps {}

export const ActionButton = (props: ActionButtonProps) => {
  const { action, disabled, ...rest } = props;

  return (
    <IconButton size={'xs'} onClick={action} disabled={!action || disabled} {...rest} />
  );
};

interface ActionButtonGroupProps extends GridProps {
  items: Array<ActionButtonProps | ActionMenuProps>;
  includeRefetch?: boolean;
}

export const ActionButtonGroup = (props: ActionButtonGroupProps) => {
  const { items, includeRefetch = false, ...rest } = props;

  return (
    <Grid
      w={'fit-content'}
      gap={4}
      templateColumns={{
        base: 'repeat(4, 1fr)',
        [breakpoints.content]: 'repeat(4, 1fr)',
      }}
      {...rest}>
      {items.map((item, index) =>
        'menuItems' in item ? (
          <ActionMenu key={index} {...item} />
        ) : (
          <ActionButton key={index} {...item} />
        )
      )}
      {includeRefetch && (
        <RefetchButton size={'xs'} isRound={false} variant={'ghost'} />
      )}
    </Grid>
  );
};
