import React, { useState } from 'react';
import { FiEdit3, FiSave, FiX } from 'react-icons/all';
import {
  Button,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { ApolloQueryResult } from '@apollo/client';
import { FormikProps, FormikValues, useFormik } from 'formik';
import {
  CheckboxStates,
  IDict,
  IFormData,
  IFormMapping,
  IFormValue,
} from '../../types/types';
import {
  CheckboxField,
  InputField,
  InputFieldBaseProps,
  SelectField,
  SwitchField,
  TextAreaField,
} from './InputField';
import { ExtendableFieldGroupProps, FieldGroup } from '../field-group/FieldGroup';
import { ActionButtonGroup } from '../buttons/ActionButtons';
import { PlaceAutocomplete } from '../google/PlaceAutocomplete';
import { NothingChangedError, SubmissionError } from '../../types/errors';

interface InputFieldSwitchProps extends InputFieldBaseProps, IFormValue {
  setFieldValue: (
    key: string,
    value: string | boolean | { value: string | number; options?: string[] }
  ) => void;
  type: string;
  forceOnChangeOverwrite?: boolean;
}

const InputFieldSwitch: React.FC<InputFieldSwitchProps> = ({
  setFieldValue,
  type,
  value,
  additionalInputProps,
  forceOnChangeOverwrite,
  ...rest
}) => {
  switch (type) {
    case 'toggle':
      return (
        <SwitchField
          additionalInputProps={{
            ...additionalInputProps,
            isChecked: value as boolean,
          }}
          {...rest}
        />
      );
    case 'textarea':
      return (
        <TextAreaField
          additionalInputProps={{ ...additionalInputProps, value }}
          {...rest}
        />
      );
    case 'select':
      return (
        <SelectField
          additionalInputProps={{
            ...additionalInputProps,
            onChange: (selectedValue: string | number) =>
              setFieldValue(rest.inputName, {
                options: additionalInputProps.options,
                value: selectedValue,
              }),
            value,
          }}
          {...rest}
        />
      );
    case 'placeId':
      return (
        <PlaceAutocomplete
          inputProps={{
            ...additionalInputProps,
            isDisabled: rest.inEditMode,
            value: value as string,
          }}>
          <InputField
            additionalInputProps={{
              ...additionalInputProps,
              onChange: () => undefined,
            }}
            {...rest}
          />
        </PlaceAutocomplete>
      );
    case 'checkbox':
      return (
        <CheckboxField
          additionalInputProps={{
            ...additionalInputProps,
            onChange: forceOnChangeOverwrite
              ? additionalInputProps.onChange
              : () => {
                  let newValue: string;
                  if (value === CheckboxStates.CHECKED) {
                    newValue = CheckboxStates.EMPTY;
                  } else if (
                    value === CheckboxStates.EMPTY &&
                    additionalInputProps.indeterminate
                  ) {
                    newValue = CheckboxStates.INDETERMINATE;
                  } else {
                    newValue = CheckboxStates.CHECKED;
                  }
                  setFieldValue(rest.inputName, newValue);
                },
            isIndeterminate: value === CheckboxStates.INDETERMINATE,
            isChecked: value === CheckboxStates.CHECKED,
          }}
          {...rest}
        />
      );
    default:
      return (
        <InputField
          additionalInputProps={{
            ...additionalInputProps,
            value,
            type,
          }}
          {...rest}
        />
      );
  }
};

interface FormBaseProps {
  dataMapping: IFormMapping;
  inEditMode: boolean;
  submitButton?: boolean;
}

interface FormWrapperProps<T> extends FormBaseProps {
  initialData: T;
  validator?: (values: T) => IDict<string>;
  submitFunction?: (data: T) => Promise<any> | undefined;
  children: (props: FormProps<T>) => React.ReactNode;
}

export const FormWrapper = <T extends FormikValues>(props: FormWrapperProps<T>) => {
  const { initialData, validator, submitFunction, children, ...rest } = props;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validate: validator,
    onSubmit: (values, { setSubmitting, setStatus }) => {
      if (submitFunction) {
        setStatus(undefined);
        const submitPromise = submitFunction?.(values);
        if (!submitPromise) {
          setStatus({
            status: 'error',
            message: 'Submit Function returned undefined...',
          });
          setSubmitting(false);
        } else {
          submitPromise
            .then(() =>
              setStatus({
                status: 'success',
                message: 'Submission successful!',
              })
            )
            .catch((e) => {
              if (e.name === new NothingChangedError().name) {
                setStatus({
                  status: 'info',
                  message: 'Nothing has changed.',
                });
              } else if (e.name === new SubmissionError().name) {
                setStatus({
                  status: 'error',
                  message: 'Something went wrong while submitting the data!',
                });
              } else {
                setStatus({
                  status: 'error',
                  message: 'Something unexpected went wrong!',
                });
              }
            })
            .finally(() => setSubmitting(false));
        }
      } else {
        setStatus({ status: 'error', message: 'Submit Function is undefined...' });
        setSubmitting(false);
      }
    },
  });

  const returnProps = { formik, ...rest };

  return <>{children(returnProps)}</>;
};

interface FormProps<T> extends FormBaseProps {
  formik: FormikProps<T>;
}

export const Form = <T extends FormikValues>(props: FormProps<T>) => {
  const { dataMapping, inEditMode, submitButton, formik } = props;

  return (
    <form style={{ width: 'inherit' }} onSubmit={formik.handleSubmit}>
      <VStack width={'full'} spacing={'4'}>
        {Object.entries(dataMapping).map(([key, mappingValue], index) => {
          const { handleChange: onChange, values } = formik;

          if (mappingValue.type === 'checkbox-group') {
            const {
              label: groupLabel,
              checkboxes,
            }: { label: string; checkboxes: IDict<any>[] } = mappingValue;

            return (
              <Stack key={index} w={'inherit'} py={2}>
                <Heading as={'h2'} size={'sm'}>
                  {groupLabel}
                </Heading>
                <Wrap>
                  {checkboxes.map((checkboxMappingValues, checkboxIndex) => {
                    const {
                      key: checkboxKey,
                      label,
                      isRequired,
                      isReadOnly,
                      ...rest
                    } = checkboxMappingValues;
                    return (
                      <WrapItem key={checkboxIndex}>
                        <InputFieldSwitch
                          key={checkboxIndex}
                          inputName={checkboxKey}
                          isReadOnly={isReadOnly}
                          label={label}
                          isRequired={isRequired}
                          type={'checkbox'}
                          value={values[key]?.[checkboxKey] ?? false}
                          forceOnChangeOverwrite={true}
                          additionalInputProps={{
                            ...rest,
                            onChange: () => {
                              let newValue: string;
                              if (
                                !values[key]?.[checkboxKey] ||
                                values[key]?.[checkboxKey] === CheckboxStates.EMPTY
                              ) {
                                newValue = CheckboxStates.INDETERMINATE;
                              } else if (
                                values[key]?.[checkboxKey] ===
                                CheckboxStates.INDETERMINATE
                              ) {
                                newValue = CheckboxStates.CHECKED;
                              } else {
                                newValue = CheckboxStates.EMPTY;
                              }
                              formik.setFieldValue(key, {
                                ...values[key],
                                [checkboxKey]: newValue,
                              });
                            },
                            name: checkboxKey,
                          }}
                          inEditMode={inEditMode}
                          setFieldValue={formik.setFieldValue}
                          error={formik.errors[checkboxKey] as string}
                        />
                      </WrapItem>
                    );
                  })}
                </Wrap>
              </Stack>
            );
          }
          const { type, label, isRequired, helperText, isReadOnly, ...rest } =
            mappingValue;
          const value = values[key];
          return (
            <InputFieldSwitch
              key={index}
              inputName={key}
              isReadOnly={isReadOnly}
              helperText={helperText}
              label={label}
              isRequired={isRequired}
              type={type}
              value={type === 'select' ? value?.value ?? '' : value ?? ''}
              additionalInputProps={{
                ...rest,
                options: value?.options,
                onChange,
                name: key,
              }}
              inEditMode={inEditMode}
              setFieldValue={formik.setFieldValue}
              error={formik.errors[key] as string}
            />
          );
        })}
        {formik.status && (
          <Text
            fontSize={'sm'}
            color={(() => {
              switch (formik.status.status) {
                case 'error':
                  return 'red.400';
                case 'success':
                  return 'green.400';
                default:
                  return 'blue.400';
              }
            })()}>
            {formik.status.message}
          </Text>
        )}
        {submitButton && (
          <Button isLoading={formik.isSubmitting} type={'submit'}>
            Submit
          </Button>
        )}
      </VStack>
    </form>
  );
};

interface FormFieldGroupProps extends ExtendableFieldGroupProps {
  initialData: IFormData;
  dataMapping: IFormMapping;
  submitCallback?: (data: IFormData) => Promise<ApolloQueryResult<any>>;
}

export const FormFieldGroup = (props: FormFieldGroupProps) => {
  const { initialData, fieldGroupProps, dataMapping, submitCallback } = props;

  const [inEditMode, setEditMode] = useState<boolean>(false);
  const submit = (data: IFormData) => submitCallback?.(data);

  const toggleEditMode = () => {
    setEditMode((oldState) => !oldState);
  };

  return (
    <FormWrapper
      initialData={initialData}
      inEditMode={inEditMode}
      dataMapping={dataMapping}
      validator={() => ({})}
      submitFunction={submit}>
      {({ formik, ...restOfFormProps }) => (
        <FieldGroup
          stickyElement={
            initialData && (
              <ActionButtonGroup
                templateColumns={'repeat(1)'}
                items={[
                  {
                    'aria-label': inEditMode ? 'Discard Changes' : 'Edit',
                    action: () => {
                      toggleEditMode();
                      formik.resetForm();
                    },
                    icon: inEditMode ? <FiX /> : <FiEdit3 />,
                    colorScheme: inEditMode ? 'red' : 'gray',
                  },
                  {
                    'aria-label': 'Save Changes',
                    action: () => formik.submitForm(),
                    isLoading: formik.isSubmitting,
                    icon: <FiSave />,
                    colorScheme: 'green',
                    display: inEditMode ? 'flex' : 'none',
                  },
                ]}
              />
            )
          }
          {...fieldGroupProps}>
          {initialData && (
            <VStack width={'full'} spacing={'4'}>
              <Form formik={formik} {...restOfFormProps} />
              {inEditMode && (
                <HStack>
                  <Button
                    isLoading={formik.isSubmitting}
                    onClick={() => formik.submitForm()}
                    colorScheme={'green'}
                    size={'sm'}
                    fontSize={'sm'}>
                    Save
                  </Button>
                  <Button
                    colorScheme={'red'}
                    size={'sm'}
                    fontSize={'sm'}
                    onClick={() => {
                      toggleEditMode();
                      formik.resetForm();
                    }}>
                    Discard
                  </Button>
                </HStack>
              )}
            </VStack>
          )}
        </FieldGroup>
      )}
    </FormWrapper>
  );
};
