import {
  Tabs as ChakraTabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '../../util/hooks/useQuery';

const parseTabQueryParameter = (
  query: URLSearchParams,
  panelsLength: number
): number | null => {
  const tabParam = query.get('tab');
  let parsedIndex: number;

  if (tabParam !== null) {
    parsedIndex = parseInt(tabParam, 10);
    if (!Number.isNaN(parsedIndex)) {
      if (parsedIndex <= panelsLength - 1 && parsedIndex >= 0) {
        return parsedIndex;
      }
    }
  }

  return null;
};

interface TabsProps {
  panels: {
    child: React.ReactNode;
    title: string;
  }[];
}

export const Tabs: React.FC<TabsProps> = ({ panels }) => {
  const { query, updateQuery } = useQuery();
  const panelsLength = useMemo(() => panels.length, [panels]);
  const [tabIndex, setTabIndex] = useState<number>(
    parseTabQueryParameter(query, panelsLength) ?? 0
  );

  useEffect(() => {
    const parsedIndex = parseTabQueryParameter(query, panelsLength);
    setTabIndex((oldTabIndex) =>
      oldTabIndex !== parsedIndex && parsedIndex !== null ? parsedIndex : oldTabIndex
    );
  }, [query, panelsLength]);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    updateQuery({ tab: index });
  };

  return (
    <ChakraTabs
      index={tabIndex}
      onChange={handleTabsChange}
      h={'full'}
      overflow={'hidden'}
      size={'sm'}
      variant={'enclosed'}>
      <Box overflowX={'auto'}>
        <TabList whiteSpace={'nowrap'} minW={'fit-content'} w={'full'} mb={0}>
          {panels.map(({ title }, index) => (
            <Tab key={index}>{title}</Tab>
          ))}
        </TabList>
      </Box>
      <TabPanels
        h={`calc(100% - 42px)`}
        overflowY={'auto'}
        p={2}
        pt={'6'}
        display={'flex'}
        justifyContent={'center'}>
        {panels.map(({ child }, index) => (
          <TabPanel p={0} key={index} w={'full'} maxW={'3xl'}>
            {child}
          </TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};
