import { PDFDocument, PageSizes, degrees, PDFImage } from 'pdf-lib';
import fileDownload from 'js-file-download';

enum LegalMimeTypes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
}

/**
 *
 * @param coverImage      A `Blob` (image) that contains all the user data created with `html-to-image` lib.
 * @param documentName    Name of the PDF.
 * @param documents       Array of urls that must point to a PDF. Those PDF's are appended after the cover.
 */
export const createUserPdf = async (
  coverImage: Blob,
  documentName: string,
  documents?: string[]
) => {
  const pdfDoc = await PDFDocument.create();
  pdfDoc.setTitle(documentName);

  const coverImgArrayBuffer = await coverImage.arrayBuffer();
  const coverImg = await pdfDoc.embedPng(coverImgArrayBuffer);

  const page = pdfDoc.addPage(PageSizes.A4);
  const { width: pageW, height: pageH } = page.getSize();

  page.drawImage(coverImg, {
    x: 0,
    y: 0,
    width: pageW,
    height: pageH,
  });

  if (documents) {
    // eslint-disable-next-line no-restricted-syntax
    for await (const documentUrl of documents) {
      const response = await fetch(documentUrl).then(async (res) => {
        const blob = await res.blob();
        const arrayBuffer = await blob.arrayBuffer();
        return {
          type: res.headers.get('Content-Type'),
          arrayBuffer,
          blob,
        };
      });
      if (response.type === 'application/pdf') {
        const documentPdfDoc = await PDFDocument.load(response.arrayBuffer);
        const documentPages = await pdfDoc.copyPages(
          documentPdfDoc,
          documentPdfDoc.getPages().map((_, index) => index)
        );
        documentPages.forEach((documentPage) => pdfDoc.addPage(documentPage));
      } else if (
        response.type === LegalMimeTypes.JPEG ||
        response.type === LegalMimeTypes.PNG
      ) {
        let img: PDFImage;

        if (response.type === LegalMimeTypes.JPEG) {
          img = await pdfDoc.embedJpg(response.arrayBuffer);
        } else {
          img = await pdfDoc.embedPng(response.arrayBuffer);
        }

        const { width: imgW, height: imgH } = img;
        const imgRatio = imgW / imgH;

        const imgPage = pdfDoc.addPage(PageSizes.A4);

        const { width: imgPageW, height: imgPageH } = imgPage.getSize();
        const shouldRotate = imgRatio > imgPageW / imgPageH && imgRatio > 1;

        const scaledImg = img.scaleToFit(
          (shouldRotate ? imgPageH : imgPageW) - 40,
          (shouldRotate ? imgPageW : imgPageH) - 40
        );

        imgPage.drawImage(img, {
          x: 20,
          y: shouldRotate ? imgPageH - 20 : imgPageH - 20 - scaledImg.height,
          width: scaledImg.width,
          height: scaledImg.height,
          rotate: degrees(shouldRotate ? -90 : 0),
        });
      }
    }
  }

  const pdfBytes = await pdfDoc.save();
  const pdfFile = new File([pdfBytes], documentName, { type: 'application/pdf' });

  fileDownload(pdfFile, documentName, 'application/pdf');
};
