import React, { useCallback, useState } from 'react';
import { Flex, Divider, IconButton, useColorModeValue as mode } from '@chakra-ui/react';
import moment from 'moment';
import { FiArrowLeft } from 'react-icons/all';
import { ApolloError, useApolloClient } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import MessageFeed from './MessageFeed';
import ParticipantPopover from './ParticipantPopover';
import MessageInput from './MessageInput';
import {
  usePostChatMessageMutation,
  useChatQuery,
  ChatQuery,
  useChatMessageSubscription,
  useChatMessagesQuery,
  ChatMessagesQuery,
  ChatsDocumentNode,
} from '../../graphql/types-and-hooks';
import State from '../loading/State';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { IPostChatMessage, IUnsentMessage } from '../../types/types';
import { updateCacheWithNewMessage } from '../../util/apollo/updateCache';

interface ChatBaseProps {
  openCallback: () => void;
}

interface ChatProps extends ChatBaseProps {
  chat: ChatQuery['chat'];
  messagesLoading: boolean;
  messagesLoadingError?: ApolloError;
  messages?: ChatMessagesQuery['chatMessages'];
  postChatMessage: (message: IPostChatMessage) => Promise<{ success: boolean }>;
}

const Chat: React.FC<ChatProps> = (props) => {
  const {
    chat,
    openCallback,
    messages,
    messagesLoading,
    messagesLoadingError,
    postChatMessage,
  } = props;

  const me = chat.participants.filter((participant) => participant.me)[0];

  const [unsentMessages, setUnsentMessages] = useState<IUnsentMessage[]>([]);

  const sendMessage = (unsentMessage: IUnsentMessage) => {
    postChatMessage({
      chatId: chat.id,
      message: unsentMessage.simpleMessage,
      companyId: me.companyId ?? '',
      unsentMessageId: unsentMessage.id,
    })
      .then(() => {
        setUnsentMessages((oldState) =>
          oldState.filter((message) => message.id !== unsentMessage.id)
        );
      })
      .catch((postError) => {
        setUnsentMessages((oldState) =>
          oldState.map((message) =>
            message.id === unsentMessage.id ? { ...message, error: postError } : message
          )
        );
      });
  };

  const setUnsentMessagesHelper = (simpleMessage: string) => {
    if (simpleMessage !== '') {
      const messageId = uuidv4();
      const unsentMessage: IUnsentMessage = {
        senderId: me.id,
        simpleMessage,
        sentAt: moment(),
        chatId: chat.id,
        id: messageId,
      } as IUnsentMessage;

      setUnsentMessages((oldState) => [...oldState, unsentMessage]);

      sendMessage(unsentMessage);
    }
  };

  const retrySendingMessage = (unsentMessageId: string) => {
    const unsentMessage = unsentMessages.filter(({ id }) => id === unsentMessageId)[0];

    if (unsentMessage) {
      sendMessage(unsentMessage);
    }
  };

  return (
    <Flex w={'full'} h={'full'} direction={'column'} justify={'space-between'}>
      <Flex alignItems={'center'} ml={1}>
        <IconButton
          display={{ base: 'flex', lg: 'none' }}
          size={'sm'}
          aria-label={'Go back to chats'}
          mr={2}
          variant={'ghost'}
          onClick={openCallback}
          icon={<FiArrowLeft />}
        />
        {chat && (
          <ParticipantPopover
            participants={chat.participants}
            objectId={chat.objectData.id}
          />
        )}
      </Flex>
      <Divider borderColor={mode('gray.200', 'gray.800')} mt={2} />
      <State loading={messagesLoading} error={messagesLoadingError}>
        <MessageFeed
          me={me}
          unsentMessages={unsentMessages}
          messages={messages}
          participants={chat.participants}
          retrySendingMessage={retrySendingMessage}
        />
        <MessageInput handleSending={setUnsentMessagesHelper} disabled={!me} />
      </State>
    </Flex>
  );
};

interface ChatWithDataProps extends ChatBaseProps {
  chatId: string;
}

export const ChatWithData: React.FC<ChatWithDataProps> = (props) => {
  const { chatId, openCallback } = props;

  const apolloClient = useApolloClient();

  const [chatQueryResult, chatParsedData] = useQueryWrapper({
    query: useChatQuery,
    options: { variables: { id: chatId } },
    parser: useCallback<(data: ChatQuery) => ChatQuery['chat']>(
      (data) => data.chat,
      []
    ),
  });

  const [chatMessagesQueryResult, chatMessagesParsedData] = useQueryWrapper({
    query: useChatMessagesQuery,
    options: {
      variables: { chatId },
      nextFetchPolicy: 'cache-first',
    },
    parser: useCallback<(data: ChatMessagesQuery) => ChatMessagesQuery['chatMessages']>(
      (data) => data.chatMessages,
      []
    ),
  });

  const [postChatMessage] = usePostChatMessageMutation();

  useChatMessageSubscription({
    variables: { chatId },
    onSubscriptionData: ({ client, subscriptionData: { data } }) => {
      const { cache } = client;
      const newMessage = data?.chatMessage;
      if (
        newMessage?.senderId !==
        chatParsedData?.participants.find((participant) => participant.me)?.id
      ) {
        updateCacheWithNewMessage(chatId, cache, newMessage);
        apolloClient.refetchQueries({ include: [ChatsDocumentNode] });
      }
    },
  });

  const postChatMessageHandler = (message: IPostChatMessage) => {
    return postChatMessage({
      variables: { ...message },
      update: (cache, data) => {
        const newMessage = data.data?.postChatMessage
          ? {
              ...data.data?.postChatMessage,
              readBy: [],
            }
          : undefined;
        updateCacheWithNewMessage(chatId, cache, newMessage);
      },
    }).then((res) => {
      apolloClient.refetchQueries({ include: [ChatsDocumentNode] });
      return { success: !!res.data?.postChatMessage.id };
    });
  };

  return (
    <State loading={chatQueryResult.loading} error={chatQueryResult.error}>
      {chatParsedData && (
        <Chat
          chat={chatParsedData}
          openCallback={openCallback}
          messages={chatMessagesParsedData}
          messagesLoading={chatMessagesQueryResult.loading}
          messagesLoadingError={chatMessagesQueryResult.error}
          postChatMessage={postChatMessageHandler}
        />
      )}
    </State>
  );
};

export default Chat;
