import {
  AttributeType,
  AutoConsent,
  ModifyAction,
  ModifyObjectAutoConsentsInput,
  Scalars,
} from '../../graphql/types-and-hooks';
import { CheckboxStates } from '../../types/types';

export type AutoConsentsFormData = Record<
  'document' | 'other' | 'userProperty',
  Record<Scalars['ID'], CheckboxStates>
>;

const reduceAutoConsentToFormData = (a: any, v: AutoConsent) => ({
  ...a,
  [v.attribute.id]: v.required ? CheckboxStates.CHECKED : CheckboxStates.INDETERMINATE,
});

export const parseAutoConsentsToFormData = (
  autoConsents: AutoConsent[]
): AutoConsentsFormData => {
  return {
    document: autoConsents
      .filter(({ attribute: { type } }: AutoConsent) => type === AttributeType.Document)
      .reduce(reduceAutoConsentToFormData, {}),
    other: autoConsents
      .filter(({ attribute: { type } }: AutoConsent) => type === AttributeType.Other)
      .reduce(reduceAutoConsentToFormData, {}),
    userProperty: autoConsents
      .filter(
        ({ attribute: { type } }: AutoConsent) => type === AttributeType.UserProperty
      )
      .reduce(reduceAutoConsentToFormData, {}),
  };
};

export const parseFormDataToAutoConsents = (
  formData: AutoConsentsFormData,
  autoConsents: AutoConsent[]
): ModifyObjectAutoConsentsInput[] => {
  const mergedCheckboxes: [string, CheckboxStates][] = [];

  mergedCheckboxes.push(...Object.entries(formData?.document));
  mergedCheckboxes.push(...Object.entries(formData?.other));
  mergedCheckboxes.push(...Object.entries(formData?.userProperty));

  return mergedCheckboxes
    .filter(([id, value]) => {
      // compare FormData to existing auto consents
      const alreadyExistingAutoConsent = autoConsents.find(
        (autoConsent: AutoConsent) => autoConsent.attribute.id === id
      );

      let filterOut;
      const checkboxRequired =
        // eslint-disable-next-line no-nested-ternary
        value === CheckboxStates.CHECKED
          ? true
          : value === CheckboxStates.INDETERMINATE
          ? false
          : null;

      if (alreadyExistingAutoConsent) {
        filterOut = alreadyExistingAutoConsent.required === checkboxRequired;
      } else {
        filterOut = value === CheckboxStates.EMPTY;
      }

      return !filterOut;
    })
    .map(([id, value]) => {
      let action: ModifyAction;

      // Check if value existed before
      const alreadyExistingAutoConsent = autoConsents.find(
        (autoConsent: AutoConsent) => autoConsent.attribute.id === id
      );

      // Determine action
      if (
        !alreadyExistingAutoConsent &&
        (value === CheckboxStates.CHECKED || value === CheckboxStates.INDETERMINATE)
      ) {
        action = ModifyAction.Add;
      } else {
        action =
          value === CheckboxStates.EMPTY ? ModifyAction.Delete : ModifyAction.Update;
      }

      return {
        attributeId: id,
        required: value === CheckboxStates.CHECKED,
        action,
      };
    });
};
