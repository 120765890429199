import { IDict } from '../types/types';

interface ITimeout {
  id: ReturnType<typeof setTimeout>;
  isRunning: boolean;
}

const timeouts: IDict<ITimeout> = {};

const clearTimeoutHelper = (timeoutName: string) => {
  const timeout = timeouts[timeoutName];
  timeout.isRunning = false;
  clearTimeout(timeout.id);
};

const makeTimeout = (fn: any, ms: number, timeoutName: string, instantRun = false) => {
  const timeout = timeouts[timeoutName] as ITimeout;

  timeout.isRunning = true;

  if (instantRun) {
    fn();
  }

  timeout.id = setTimeout(() => {
    if (!instantRun) {
      fn();
    }
    timeout.isRunning = false;
  }, ms);
};

const initTimeout = (timeoutName: string) => {
  timeouts[timeoutName] = {
    id: setTimeout(() => undefined, 0),
    isRunning: false,
  };
  return timeouts[timeoutName];
};

export const debounce = (
  fn: any,
  ms: number,
  timeoutName: string,
  instantFeedback = true
) => {
  let timeout = timeouts[timeoutName] as ITimeout;

  if (timeout === undefined) {
    // When object doesn't exist yet
    timeout = initTimeout(timeoutName);
  }

  if (timeout.isRunning) {
    // Actual Debounce effect
    clearTimeoutHelper(timeoutName);
    makeTimeout(fn, ms, timeoutName);
  } else {
    // When there is no timeout running (instant feedback)
    makeTimeout(fn, ms, timeoutName, instantFeedback);
  }
};
