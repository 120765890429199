import React from 'react';
import { Badge, Text } from '@chakra-ui/react';
import moment from 'moment';
import { ColumnProps } from '../../../components/table/TableContent';

export const b2bUserColumns: Array<ColumnProps> = [
  {
    header: 'Email',
    accessors: ['email'],
    Cell: (email: string) => <Text>{email}</Text>,
  },
  {
    header: 'Signup Date',
    accessors: ['signupDate'],
    Cell: (signupDate: string) => (
      <Badge m={1} w={'min-content'} variant={'outline'}>
        {moment(signupDate).format('DD.MM.YYYY hh:mm')}
      </Badge>
    ),
  },
  {
    header: 'Id',
    accessors: ['id'],
    Cell: (id: string) => <Text>{id}</Text>,
  },
];
