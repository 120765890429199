import React from 'react';
import moment from 'moment';
import { Flex, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { ChatMessagesQuery, ChatQuery } from '../../graphql/types-and-hooks';
import { ReadCheckIcon } from './ReadCheck';
import { IUnsentMessage } from '../../types/types';

interface MessageProps {
  message: ChatMessagesQuery['chatMessages'][0] | IUnsentMessage;
  prevMessage?: ChatMessagesQuery['chatMessages'][0] | IUnsentMessage;
  nextMessage?: ChatMessagesQuery['chatMessages'][0] | IUnsentMessage;
  isMine: boolean;
  isUnsent: boolean;
  participants: ChatQuery['chat']['participants'];
}

export const MessageBox: React.FC<MessageProps> = ({
  message,
  prevMessage,
  nextMessage,
  isUnsent,
  isMine,
  participants,
}) => {
  const sameSenderAsPrevMessage = message.senderId === prevMessage?.senderId;
  const sameSenderAsNextMessage = message.senderId === nextMessage?.senderId;

  return (
    <Flex
      mt={sameSenderAsPrevMessage ? 0 : 2}
      mb={sameSenderAsNextMessage ? 0 : 2}
      py={0.5}
      width={'100%'}>
      <Flex width={'100%'}>
        <Flex
          maxW={'85%'}
          bg={isMine ? mode('blue.100', 'teal.700') : mode('gray.200', 'gray.700')}
          direction={'column'}
          pr={2}
          py={2}
          pl={3}
          borderRadius={12}
          boxShadow={'0 2px 2px #0f0f0f0f'}
          ml={isMine ? 'auto' : undefined}
          mr={isMine ? undefined : 'auto'}>
          {!sameSenderAsPrevMessage && !isMine && (
            <Text color={'green'} fontSize={14}>
              {
                participants.filter(
                  (participant) => participant.id === message.senderId
                )[0]?.name?.text
              }
            </Text>
          )}

          <Text fontSize={14} maxWidth={400}>
            {message?.simpleMessage
              .trim()
              .split('\n')
              .map((paragraph, index) => (
                <React.Fragment key={index}>
                  {paragraph}
                  <br />
                </React.Fragment>
              ))}
          </Text>
          <Flex
            ml={'auto'}
            mt={'auto'}
            px={1}
            alignItems={'center'}
            justifyContent={'flex-end'}>
            {isUnsent ? (
              <>
                <Text
                  opacity={mode(1, 0.5)}
                  fontSize={11}
                  color={
                    isMine ? mode('gray.600', 'white') : mode('gray.500', 'white')
                  }>
                  {moment().format('hh:mm A')}
                </Text>
                {isMine ? <ReadCheckIcon isUnsent={true} /> : null}
              </>
            ) : (
              <>
                <Text
                  opacity={mode(1, 0.5)}
                  fontSize={11}
                  color={
                    isMine ? mode('gray.600', 'white') : mode('gray.500', 'white')
                  }>
                  {moment(message?.sentAt).format('hh:mm A')}
                </Text>
                {isMine ? (
                  <ReadCheckIcon
                    isUnsent={false}
                    participants={participants}
                    readBy={message?.__typename === 'ChatMessage' ? message.readBy : []}
                  />
                ) : null}
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
