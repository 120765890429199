import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import mimeTypeFromURL from '../../util/mime-type-checker';
import State from '../loading/State';

const mimeTypeSwitch = (url: string, title: string, mimeType: string) => {
  switch (mimeType) {
    case 'image/webp':
    case 'image/jpeg':
      return <Image width={'100%'} src={url} alt={title} loading={'lazy'} />;
    case 'application/pdf':
      return (
        <object
          data={url}
          style={{ height: '100%', width: '100%' }}
          type={'application/pdf'}>
          <a href={url} rel={'noreferrer'} target={'_blank'}>
            Cannot load PDF. Click to Open the file in a new tab.
          </a>
        </object>
      );
    default:
      return `Not able to open file with mime-type: ${mimeType}`;
  }
};

interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  url: string;
  defaultMimeType?: string;
}

export const MediaModal = (props: MediaModalProps) => {
  const { isOpen, onClose, title = '', url, defaultMimeType = '' } = props;

  const [mimeType, setMimeType] = useState<string>(defaultMimeType);
  const [errorLoadingMime, setError] = useState<Error>();

  useEffect(() => {
    if (defaultMimeType === '') {
      mimeTypeFromURL(url)
        .then((mime) => setMimeType(mime))
        .catch((error) => {
          setError(error);
        });
    }
  }, [url, defaultMimeType]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
      <ModalOverlay />
      <ModalContent h={'calc(100% - 7.5rem)'}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={'inherit'}>
          <Box w={'100%'} h={'100%'} overflowY={'scroll'}>
            <State loading={mimeType === ''} error={errorLoadingMime}>
              {mimeType && mimeTypeSwitch(url, title, mimeType)}
            </State>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
