import React, { useState } from 'react';
import { HStack, Stack, Text, IconButton } from '@chakra-ui/react';
import { FiDownload, FiEye } from 'react-icons/all';
import downloadFile from '../../util/download-file';
import { UserDocument } from '../../graphql/types-and-hooks';
import { MediaModal } from '../modal/MediaModal';

interface DocumentFieldProps {
  title: string;
  url: string;
  nameOfUser: string;
}

export const DocumentField = (props: DocumentFieldProps) => {
  const { title, url, nameOfUser } = props;

  const [isOpen, setOpen] = useState<boolean>(false);
  const [isPreparingDownload, setIsPreparingDownload] = useState<boolean>(false);

  return (
    <>
      <Text fontSize={'sm'}>{title}</Text>
      {url && (
        <>
          <HStack>
            <IconButton
              aria-label={'View'}
              icon={<FiEye />}
              size={'sm'}
              onClick={() => setOpen(true)}
            />
            <IconButton
              aria-label={'Download'}
              icon={<FiDownload />}
              size={'sm'}
              isLoading={isPreparingDownload}
              onClick={() => {
                setIsPreparingDownload(true);
                downloadFile(url, `${nameOfUser.trim()} ${title.trim()}`).finally(() =>
                  setIsPreparingDownload(false)
                );
              }}
            />
          </HStack>
          <MediaModal
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            url={url}
            title={title}
          />
        </>
      )}
    </>
  );
};

interface DocumentFieldGroupProps {
  documents: Array<UserDocument>;
  nameOfUser: string;
}

export const Documents = (props: DocumentFieldGroupProps) => {
  const { documents, nameOfUser } = props;

  return documents.length > 0 ? (
    <Stack width={'full'} spacing={'4'}>
      {documents.map((document, index) => (
        <HStack key={index} justify={'space-between'} width={'full'}>
          <DocumentField
            key={index}
            title={document.displayName.text}
            nameOfUser={nameOfUser}
            url={document.asset?.url}
          />
        </HStack>
      ))}
    </Stack>
  ) : null;
};
