import React, { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Stack, Tag, Text } from '@chakra-ui/react';
import { FiDownloadCloud, FiMessageSquare } from 'react-icons/all';
import moment from 'moment';
import { ActionButtonGroup } from '../components/buttons/ActionButtons';
import { Documents } from '../components/document/Documents';
import { Stats } from '../components/stat/Stats';
import { FormFieldGroup } from '../components/form/Form';
import { userFormMapping } from '../data/valueMapping';
import { FieldGroupViewSkeleton } from '../components/loading/skeleton/FieldGroupSkeleton';
import { parseQueriedUserToMap } from '../util/query-data-parser/user-parser';
import {
  Asset,
  UserDocument,
  UserDocumentNode,
  useRejectAccountDeletionMutation,
  UserMode,
  UserQuery,
  useToggleUserVerifiedStatusMutation,
  useUserQuery,
} from '../graphql/types-and-hooks';
import { Images } from '../components/image/Images';
import State from '../components/loading/State';
import { useQueryWrapper } from '../util/hooks/useQueryWrapper';
import { Tabs } from '../components/tabs/Tabs';
import { ClipboardButton } from '../components/buttons/ClipboardButton';
import { IdentityProvider } from '../types/types';
import { IdentityProviderTag } from '../components/tags/IdentityProviderTag';
import { useUserPdf } from '../util/hooks/useUserPdf';
import { DateTag } from '../components/tags/DateTag';
import { ActionZone } from '../components/action-zone/ActionZone';
import { VerifiedShieldBadge } from '../components/badge/VerifiedShieldBadge';

const User = () => {
  const { userId } = useParams<{ userId: string }>();

  const [userQueryResult, userParsedData] = useQueryWrapper({
    query: useUserQuery,
    options: { variables: { userId } },
    parser: useCallback<(data: UserQuery) => Map<string, any>>(
      (data) => parseQueriedUserToMap(data.user),
      []
    ),
  });

  const [rejectAccountDeletion] = useRejectAccountDeletionMutation();
  const rejectAccountDeletionHandler = useCallback(() => {
    return rejectAccountDeletion({
      variables: { userId },
      refetchQueries: [UserDocumentNode],
    });
  }, [rejectAccountDeletion, userId]);

  const [toggleUserVerifiedStatus] = useToggleUserVerifiedStatusMutation();
  const toggleUserVerifiedStatusHandler = useCallback(() => {
    return toggleUserVerifiedStatus({
      variables: { userId },
      refetchQueries: [UserDocumentNode],
    });
  }, [toggleUserVerifiedStatus, userId]);

  const { exportPdf, UserPdfCoverComponent } = useUserPdf({
    userData: {
      firstName: userParsedData?.get('firstName'),
      lastName: userParsedData?.get('lastName'),
      email: userParsedData?.get('email'),
      phoneNumber: userParsedData?.get('phoneNumber'),
      status: userParsedData?.get('status'),
      birthdate: userParsedData?.get('birthdate'),
      tenantDescription: userParsedData?.get('tenantDescription'),
      locale: userParsedData?.get('locale'),
      imageUrl: userParsedData?.get('pictures')[0]?.largestUrl,
    },
    documentName: `${userParsedData?.get('firstName')}_${userParsedData?.get(
      'lastName'
    )}`,
  });

  const actionButtonItems = useMemo(() => {
    return [
      {
        // TODO: add action
        'aria-label': 'Chat with User',
        icon: <FiMessageSquare />,
      },
      {
        'aria-label': 'Download User Data',
        icon: <FiDownloadCloud />,
        menuItems: [
          {
            actionTitle: 'with Documents',
            action: () =>
              exportPdf(
                userParsedData
                  ?.get('documents')
                  .filter(({ asset }: UserDocument) => !!asset)
                  .map(({ asset }: { asset: Asset }) => asset.url)
              ),
          },
          {
            actionTitle: 'without Documents',
            action: () => exportPdf(),
          },
        ],
      },
    ];
  }, [exportPdf, userParsedData]);

  const userModeTags = useMemo(() => {
    const isLandlord = !!userParsedData?.get('landlordId');
    const currentMode = userParsedData?.get('currentUserMode');
    const otherMode =
      currentMode === UserMode.Tenant ? UserMode.Landlord : UserMode.Tenant;

    return (
      <>
        {currentMode && (
          <Tag
            colorScheme={currentMode === UserMode.Tenant ? 'blue' : 'orange'}
            fontSize={'xs'}>
            <Text textTransform={'capitalize'} fontWeight={'bold'}>
              {currentMode}
            </Text>
          </Tag>
        )}
        {isLandlord && otherMode && (
          <Tag
            colorScheme={'gray'}
            opacity={0.4}
            fontSize={'xs'}
            textTransform={'capitalize'}>
            {otherMode}
          </Tag>
        )}
      </>
    );
  }, [userParsedData]);

  return (
    <State
      loading={userQueryResult.loading && !userQueryResult.refetching}
      customLoadingElement={<FieldGroupViewSkeleton repeat={1} />}
      error={userQueryResult.error}>
      {userParsedData && (
        <Stack height={'full'}>
          <UserPdfCoverComponent />
          <Stack direction={'row'} align={'center'} mt={1} flexWrap={'wrap'}>
            <ClipboardButton title={'Copy ID'} valueToCopy={userParsedData.get('id')} />
            {userParsedData.get('identityProvider') && (
              <IdentityProviderTag
                provider={userParsedData.get('identityProvider') as IdentityProvider}
              />
            )}
            <DateTag date={userParsedData.get('signupDate')} title={'Signup'} />
            {userModeTags}
            {userParsedData.get('accountDeletionRequest') && (
              <Link to={`/users/${userId}?tab=4`}>
                <Tag colorScheme={'red'} fontSize={'xs'}>
                  Requested Deletion
                </Tag>
              </Link>
            )}
            <Link to={`/users/${userId}?tab=4`}>
              <VerifiedShieldBadge verified={userParsedData.get('verified')} />
            </Link>
          </Stack>
          <ActionButtonGroup pt={1} items={actionButtonItems} includeRefetch={true} />
          <Tabs
            panels={[
              {
                title: 'User Info',
                child: (
                  <FormFieldGroup
                    fieldGroupProps={{ divider: undefined, spacing: 0 }}
                    initialData={Object.fromEntries(userParsedData)}
                    dataMapping={userFormMapping}
                  />
                ),
              },
              {
                title: 'Images',
                child: <Images images={userParsedData.get('pictures')} />,
              },
              {
                title: 'Status and Stats',
                child: (
                  <Stats
                    statsList={[
                      {
                        label: 'Status',
                        data: userParsedData.get('status') ?? '-',
                      },
                    ]}
                  />
                ),
              },
              {
                title: 'Documents',
                child: (
                  <Documents
                    documents={userParsedData.get('documents')}
                    nameOfUser={`${userParsedData.get(
                      'firstName'
                    )} ${userParsedData.get('lastName')}`}
                  />
                ),
              },
              {
                title: 'Actions',
                child: (
                  <Stack direction={'column'} spacing={10}>
                    <ActionZone
                      actions={[
                        {
                          title: 'Verified',
                          description: 'Toggle whether the user is verified or not.',
                          actionType: 'toggle',
                          toggle: {
                            onToggle: toggleUserVerifiedStatusHandler,
                            isChecked: userParsedData.get('verified'),
                          },
                        },
                      ]}
                    />
                    <ActionZone
                      actions={[
                        {
                          title: 'Reject User Deletion',
                          description: userParsedData.get('accountDeletionRequest') ? (
                            <Text>
                              Automatic deletion day and time:{' '}
                              {moment(
                                userParsedData.get('accountDeletionRequest')
                                  .timeOfDeletion
                              ).format('LLL')}
                            </Text>
                          ) : (
                            <Text>
                              Disabled because user has not requested Account deletion
                            </Text>
                          ),
                          actionType: 'button',
                          button: {
                            onClick: rejectAccountDeletionHandler,
                            colorScheme: 'red',
                            title: 'Reject Deletion',
                            disabled: !userParsedData.get('accountDeletionRequest'),
                          },
                        },
                      ]}
                    />
                  </Stack>
                ),
              },
            ]}
          />
        </Stack>
      )}
    </State>
  );
};

export default User;
