import { UsersQuery } from '../../graphql/types-and-hooks';

export const parseQueriedUserToMap = (
  user: Partial<UsersQuery['users']['data'][0]>
) => {
  const userMap = new Map<string, any>(Object.entries(user));
  user.values?.forEach(({ value, property: { name } }) =>
    userMap.set(name, JSON.parse(value))
  );
  userMap.delete('values');
  return userMap;
};
