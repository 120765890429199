import React, { useState } from 'react';
import { Button, Flex } from '@chakra-ui/react';

interface OpposingOptionProps<LeftOption, RightOption> {
  leftOption: {
    option: LeftOption;
    title: string;
  };
  rightOption: {
    option: RightOption;
    title: string;
  };
  initial?: LeftOption | RightOption;
  changeCallback?: (option: LeftOption | RightOption) => void;
}

export const OpposingOption = <LeftOption, RightOption>({
  initial,
  leftOption,
  rightOption,
  changeCallback,
}: OpposingOptionProps<LeftOption, RightOption>) => {
  const [option, setOption] = useState<LeftOption | RightOption>(
    initial ?? leftOption.option
  );

  const setOptionHelper = (newOption: LeftOption | RightOption) => {
    changeCallback?.(newOption);
    setOption(newOption);
  };

  return (
    <Flex w={'full'}>
      <Button
        w={'full'}
        size={'xs'}
        borderRightRadius={0}
        colorScheme={option === leftOption.option ? 'blue' : 'gray'}
        onClick={() => setOptionHelper(leftOption.option)}>
        {leftOption.title}
      </Button>
      <Button
        w={'full'}
        size={'xs'}
        borderLeftRadius={0}
        colorScheme={option === rightOption.option ? 'blue' : 'gray'}
        onClick={() => setOptionHelper(rightOption.option)}>
        {rightOption.title}
      </Button>
    </Flex>
  );
};
