import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {
  IImage,
  ImageField,
  ImageFieldDnD,
  ImageUploadField,
  ImageUploadFieldProps,
} from './ImageField';

export interface ImagesBaseProps {
  images?: IImage[];
}

interface ImagesProps extends ImagesBaseProps {
  imageUpload?: ImageUploadFieldProps;
  imageReorderMutation?: (imageIds: string[]) => Promise<unknown>;
}

export const Images: React.FC<ImagesProps> = ({
  images = [],
  imageUpload,
  imageReorderMutation,
}) => {
  const [sortedImages, setSortedImages] = useState(images);
  const [isDragging, setIsDragging] = useState(false);
  const [dragDisabled, setDragDisabled] = useState(false);
  const [orderChanged, setOrderChanged] = useState(false);

  const moveImage = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragImage = sortedImages[dragIndex];
      const updatedSortedImages = update(sortedImages, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragImage],
        ],
      });
      setSortedImages(updatedSortedImages);
      setOrderChanged(
        updatedSortedImages.some(({ id }, index) => id !== images[index].id)
      );
    },
    [sortedImages, images]
  );

  useEffect(() => {
    if (imageReorderMutation) {
      if (orderChanged && !isDragging) {
        setDragDisabled(true);
        imageReorderMutation(sortedImages.map(({ id }) => id)).finally(() =>
          setDragDisabled(false)
        );
      }
    }
  }, [orderChanged, sortedImages, isDragging, imageReorderMutation]);

  useEffect(() => {
    setSortedImages(images);
    setOrderChanged(false);
  }, [images]);

  return (
    <Grid templateColumns={'repeat(12, 1fr)'} gap={4}>
      {imageReorderMutation && !dragDisabled ? (
        <DndProvider backend={HTML5Backend}>
          {sortedImages.map((image, index) => (
            <GridItem key={image.id} colSpan={{ base: 6, sm: 4, lg: 3 }}>
              <Flex justify={'center'} w={'100%'}>
                <ImageFieldDnD
                  image={image}
                  orderIndex={index}
                  moveImage={moveImage}
                  setIsDragging={setIsDragging}
                />
              </Flex>
            </GridItem>
          ))}
        </DndProvider>
      ) : (
        sortedImages.map((image) => (
          <GridItem key={image.id} colSpan={{ base: 6, sm: 4, lg: 3 }}>
            <Flex justify={'center'} w={'100%'}>
              <ImageField image={image} />
            </Flex>
          </GridItem>
        ))
      )}
      {imageUpload && (
        <GridItem key={images.length} colSpan={{ base: 6, sm: 4, lg: 3 }}>
          <Flex justify={'center'} w={'100%'}>
            <ImageUploadField {...imageUpload} />
          </Flex>
        </GridItem>
      )}
    </Grid>
  );
};
