import { IDict, IFormData } from '../../types/types';

export const addB2bUserToCompanyValidator = (values: IFormData) => {
  const errors: IDict<any> = {};

  if ('user' in values) {
    if (values.user.value !== '') return errors;
  }

  errors.user = 'This Field is required!';

  return errors;
};
