import React, { useState } from 'react';
import {
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Textarea,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FiSend } from 'react-icons/all';

type MessageInputProps = {
  handleSending: (message: string) => void;
  disabled: boolean;
};

const MessageInput: React.FC<MessageInputProps> = (props) => {
  const { handleSending, disabled } = props;
  const [input, setInput] = useState<string>('');

  const inputSubmitHelper = () => {
    if (!disabled) {
      handleSending(input);
      setInput('');
    }
  };

  return (
    <Flex
      alignItems={'center'}
      justify={'center'}
      bg={mode('gray.200', 'gray.800')}
      p={1}>
      <Flex w={'85%'}>
        <InputGroup>
          <Textarea
            borderRadius={12}
            border={0}
            resize={'none'}
            rows={2}
            disabled={disabled}
            placeholder={'Type a message'}
            value={input}
            onChange={({ target: { value } }) => {
              setInput(value === '\n' ? '' : value);
            }}
            fontSize={'14'}
            bg={mode('white', 'gray.700')}
            _focus={{
              outline: 'none',
            }}
            onKeyPress={(event) => {
              if (!event.shiftKey && event.key === 'Enter') {
                inputSubmitHelper();
              }
            }}
          />
          <InputRightElement height={'full'} mr={1}>
            <IconButton
              disabled={disabled}
              variant={'ghost'}
              color={'blue.400'}
              aria-label={'Send Message'}
              icon={<FiSend />}
              onClick={() => inputSubmitHelper()}
            />
          </InputRightElement>
        </InputGroup>
      </Flex>
    </Flex>
  );
};

export default MessageInput;
