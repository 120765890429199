import { SyntheticEvent } from 'react';
import { debounce } from './debounce';

const defaultOptions = {
  instantFeedback: true,
};

export const filterCallback = (
  e: SyntheticEvent,
  updateValue: any,
  accessor: string,
  options?: {
    instantFeedback?: boolean;
  }
) => {
  const { instantFeedback } = { ...defaultOptions, ...options };
  let newValue = (e.target as HTMLFormElement).value;
  newValue = typeof newValue === 'string' ? newValue.trim() : newValue;
  if (newValue === '') newValue = null;
  debounce(
    () => {
      updateValue(newValue, accessor);
    },
    300,
    'filterCallback',
    instantFeedback
  );
};
