import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from './useQuery';
import {
  FilterTypeEnum,
  IFilter,
  IFilters,
  IFilterValue,
  IStatefulFilter,
} from '../../types/types';

const validateFilterValue = (value: IFilterValue, filter: IFilter) => {
  let valueIsValid = true;
  if (value !== null) {
    if (filter.__typename === FilterTypeEnum.SELECT) {
      valueIsValid = filter.options.some(
        ({ value: optionValue }) => optionValue === value
      );
    }
  }
  return valueIsValid;
};

const mapFilterToStatefulFilter = (filterType: FilterTypeEnum): FilterTypeEnum => {
  switch (filterType) {
    case FilterTypeEnum.NUMBER:
      return FilterTypeEnum.STATEFUL_NUMBER;
    case FilterTypeEnum.SELECT:
      return FilterTypeEnum.STATEFUL_SELECT;
    case FilterTypeEnum.SEARCH:
      return FilterTypeEnum.STATEFUL_SEARCH;
    default:
      throw new Error(
        'Filter Type must be a valid one (must not be stateful and must be in `FilterTypeEnum`)'
      );
  }
};

type TFilterState<T> = {
  value: T;
  added: boolean;
  lazyParamCheck?: boolean;
};

const parseFiltersToFiltersState = <T extends string, V extends IFilterValue>(
  filters: IFilters<T>,
  persistent: boolean,
  queryUtil: ReturnType<typeof useQuery>,
  oldState?: Record<T, TFilterState<V>>
): Record<T, TFilterState<V>> => {
  const initializing = oldState === undefined;

  return Object.assign(
    {},
    ...filters.map((filter) => {
      let checkParam = persistent;
      let removeParam = false;

      const tempFilterState: TFilterState<IFilterValue> = {
        value: initializing ? filter.defaultValue ?? null : null,
        added: initializing ? !!filter.addedByDefault : false,
      };

      // Skip when initializing or late param check
      if (!initializing) {
        const oldFilterState = oldState[filter.accessor];
        // Skip when lazyParamCheck is true
        if (!oldFilterState?.lazyParamCheck) {
          // when previous state reflects that the filter isn't added
          // remove searchParam blindly and skip param check
          if (!oldFilterState.added) {
            checkParam = false;
            removeParam = true;
          } else {
            // Otherwise validate value of oldState
            const valueIsValid = validateFilterValue(oldFilterState.value, filter);
            if (valueIsValid) tempFilterState.value = oldFilterState.value;
            tempFilterState.added = oldFilterState.added;
            checkParam = !valueIsValid;
          }
        }
      }

      if (persistent && checkParam) {
        const param = queryUtil.query.get(filter.accessor);
        const paramIsValid = validateFilterValue(param, filter);

        // Remove SearchParam when param is not valid or null
        if (
          (!paramIsValid || param === null) &&
          (filter.__typename === FilterTypeEnum.SELECT ||
            filter.__typename === FilterTypeEnum.NUMBER)
        ) {
          if (filter.__typename === FilterTypeEnum.SELECT) {
            // Remove only when not loadingInitialOptions
            if (!filter.loadingInitialOptions && !filter.addedByDefault)
              removeParam = true;
          } else {
            removeParam = true;
          }
        }
        tempFilterState.value = paramIsValid ? param : null;
        tempFilterState.added = tempFilterState.added || !removeParam;
        // Set lazyParamCheck to true when loadingInitialOptions
        // This will postpone the param check to the end of the loading
        // and not remove the param beforehand
        tempFilterState.lazyParamCheck =
          filter.__typename === FilterTypeEnum.SELECT
            ? filter.loadingInitialOptions
            : false;
      }

      if (removeParam) {
        queryUtil.removeQuery(filter.accessor);
      }

      return {
        [filter.accessor]: { ...tempFilterState },
      };
    })
  );
};

export const useFilter = <
  TFilterAccessors extends string,
  TFilterValues extends IFilterValue
>({
  filters,
  persistent = true,
}: {
  filters: IFilters<TFilterAccessors>;
  persistent?: boolean;
}) => {
  const [_filters, _setFilters] = useState<IFilters<TFilterAccessors>>(filters);
  const [replaceState, setReplaceState] = useState(false);

  const queryUtil = useQuery();
  const { updateQuery, removeQuery } = queryUtil;

  const [filtersState, setFiltersState] = useState<
    Record<TFilterAccessors, TFilterState<TFilterValues>>
  >(() =>
    parseFiltersToFiltersState<TFilterAccessors, TFilterValues>(
      _filters,
      persistent,
      queryUtil
    )
  );

  const updateValueHandler = useCallback(
    (newValue: TFilterValues, accessor: TFilterAccessors) => {
      setFiltersState((oldState) => {
        if (newValue) updateQuery({ [accessor]: newValue });
        else removeQuery(accessor);
        return {
          ...oldState,
          [accessor]: { ...oldState[accessor], value: newValue, added: true },
        };
      });
    },
    [removeQuery, updateQuery]
  );

  const removeFilterHandler = useCallback(
    (accessor: TFilterAccessors) => {
      setFiltersState((oldState) => {
        removeQuery(accessor);
        return {
          ...oldState,
          [accessor]: { ...oldState[accessor], value: null, added: false },
        };
      });
    },
    [removeQuery]
  );

  const statefulFilters = useMemo(() => {
    return _filters.map(
      (filter) =>
        ({
          ...filter,
          __typename: mapFilterToStatefulFilter(filter.__typename),
          updateValue: (newValue: TFilterValues) =>
            updateValueHandler(newValue, filter.accessor),
          removeFilter: () => removeFilterHandler(filter.accessor),
          value: filtersState[filter.accessor].value,
          added: filtersState[filter.accessor].added,
        } as IStatefulFilter<TFilterAccessors, TFilterValues>)
    );
  }, [_filters, updateValueHandler, removeFilterHandler, filtersState]);

  const filterValues = useMemo(() => {
    const filterValuesObject: Record<string, any> = {};
    Object.entries<TFilterState<TFilterValues>>(filtersState).forEach(
      ([key, state]) => {
        filterValuesObject[key] = state.value;
      }
    );
    return filterValuesObject as Record<TFilterAccessors, TFilterValues>;
  }, [filtersState]);

  useEffect(() => {
    if (replaceState) {
      setFiltersState((oldState) =>
        parseFiltersToFiltersState<TFilterAccessors, TFilterValues>(
          _filters,
          persistent,
          queryUtil,
          oldState
        )
      );
      setReplaceState(false);
    }
  }, [replaceState, queryUtil, persistent, _filters]);

  const replaceInitialFilters = useCallback(
    (newFilters: IFilters<TFilterAccessors>) => {
      _setFilters(newFilters);
      setReplaceState(true);
    },
    []
  );

  return {
    filterValues,
    statefulFilters,
    replaceInitialFilters,
  };
};
