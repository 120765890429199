import { useEffect, useMemo, useState } from 'react';
import { NetworkStatus, QueryHookOptions } from '@apollo/client';
import { QueryResult } from '@apollo/client/react/types/types';

interface QueryWrapperProps<TParsedData, TData, TVariables> {
  query: (
    baseOptions: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>;
  options?: QueryHookOptions<TData, TVariables>;
  parser: (data: TData) => TParsedData;
}

interface CustomQueryResult<TData, TVariables> extends QueryResult<TData, TVariables> {
  refetching: boolean;
  fetchingMore: boolean;
}

export const useQueryWrapper = <TParsedData, TData, TVariables>(
  props: QueryWrapperProps<TParsedData, TData, TVariables>
): [CustomQueryResult<TData, TVariables>, TParsedData | undefined] => {
  const { query, options = {}, parser } = props;

  const queryResult = query({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-and-network', // Used for subsequent executions
    ...options,
  });

  const [parsedData, setParsedData] = useState<TParsedData>();
  const [loading, setLoading] = useState<boolean>(queryResult.loading);
  const [refetching, setRefetching] = useState<boolean>(
    queryResult.networkStatus === NetworkStatus.refetch
  );
  const [fetchingMore, setFetchingMore] = useState<boolean>(
    queryResult.networkStatus === NetworkStatus.fetchMore
  );

  useEffect(() => {
    if (queryResult.loading) {
      setLoading(true);
      setRefetching(queryResult.networkStatus === NetworkStatus.refetch);
      setFetchingMore(queryResult.networkStatus === NetworkStatus.fetchMore);
    } else {
      setLoading(false);
      setRefetching(false);
      setFetchingMore(false);
    }
  }, [queryResult]);

  useEffect(() => {
    if (queryResult.data && !queryResult.loading) {
      setParsedData(parser(queryResult.data));
    }
  }, [queryResult.data, parser, queryResult.loading]);

  return [
    {
      ...queryResult,
      loading: useMemo(
        () => queryResult.loading || loading,
        [loading, queryResult.loading]
      ),
      refetching: useMemo(
        () => queryResult.networkStatus === NetworkStatus.refetch || refetching,
        [queryResult.networkStatus, refetching]
      ),
      fetchingMore: useMemo(
        () => queryResult.networkStatus === NetworkStatus.fetchMore || fetchingMore,
        [fetchingMore, queryResult.networkStatus]
      ),
    },
    parsedData,
  ];
};
