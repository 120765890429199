import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import useAuth from '../../util/hooks/useAuth';

const PrivateRoute = (props: RouteProps) => {
  const { children, ...rest } = props;

  const { user } = useAuth();

  return (
    <Route {...rest}>
      {user ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/auth',
            state: { from: rest.location },
          }}
        />
      )}
    </Route>
  );
};

export default PrivateRoute;
