import React from 'react';
import { Box, Flex, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { FiExternalLink, FiHome } from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import { ImageCard } from '../image/ImageCard';

interface ObjectDataProps {
  description?: string;
  title?: string;
}

const ObjectData: React.FC<ObjectDataProps> = (props) => {
  const { description, title } = props;

  return (
    <>
      <Box fontSize={'sm'} fontWeight={'medium'}>
        <Text isTruncated={true}>{title}</Text>
      </Box>
      <Box fontSize={'sm'}>
        <Text isTruncated={true}>{description}</Text>
      </Box>
    </>
  );
};

export interface ObjectCellProps extends ObjectDataProps {
  imageUrl: string;
  pathnameToOpen: string;
}

export const ObjectCell: React.FC<ObjectCellProps> = (props) => {
  const { imageUrl, pathnameToOpen, ...rest } = props;

  const history = useHistory();

  const width = useBreakpointValue({ base: 45, sm: 50, lg: 60 });

  return (
    <Stack direction={'row'} spacing={2} align={'center'}>
      <Box flexShrink={0}>
        <Box
          position={'relative'}
          w={'fit-content'}
          cursor={'pointer'}
          onClick={() => (pathnameToOpen ? history.push(pathnameToOpen) : {})}>
          <ImageCard
            imageUrl={imageUrl}
            width={width}
            noImageElement={<FiHome size={'1.7rem'} />}
          />
          <Flex
            position={'absolute'}
            right={0}
            bottom={0}
            bg={'blue.600'}
            p={'3px'}
            border={'1px'}
            borderRadius={20}
            borderColor={'white'}
            transform={'translate(25%, 25%)'}>
            <FiExternalLink color={'white'} />
          </Flex>
        </Box>
      </Box>
      <Box>
        <ObjectData {...rest} />
      </Box>
    </Stack>
  );
};
