import { IFormMapping } from '../types/types';

export const userFormMapping: IFormMapping = {
  firstName: {
    type: 'text',
    minLength: 2,
    label: 'First Name',
  },
  lastName: {
    type: 'text',
    minLength: 2,
    label: 'Last Name',
  },
  email: {
    type: 'email',
    label: 'Email',
  },
  phoneNumber: {
    type: 'tel',
    label: 'Phone',
  },
  birthdate: {
    type: 'date',
    label: 'Birthdate',
  },
  tenantDescription: {
    type: 'textarea',
    label: 'Description',
  },
};

export const objectFormMapping: IFormMapping = {
  active: {
    type: 'toggle',
    label: 'Active',
  },
  locality: {
    type: 'text',
    label: 'Locality',
  },
};

export const baseObjectFormMapping: IFormMapping = {
  internalNote: {
    type: 'text',
    label: 'Internal Note',
    isRequired: true,
  },
  placeId: {
    type: 'placeId',
    label: 'Address',
    isRequired: true,
  },
  type: {
    type: 'select',
    label: 'Object Type',
    isRequired: true,
  },
  landlord: {
    type: 'select',
    label: 'Landlord',
    isRequired: true,
  },
};

export const createCompanyFormMapping: IFormMapping = {
  companyName: {
    type: 'text',
    label: 'Company Name',
    isRequired: true,
  },
};

export const addB2bUserToCompanyFormMapping: IFormMapping = {
  user: {
    type: 'select',
    label: 'User',
    isRequired: true,
  },
};

export const createB2bUserFormMapping: IFormMapping = {
  email: {
    type: 'email',
    label: 'Email',
    isRequired: true,
  },
};
