import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Flex, Spinner } from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoUser } from 'amazon-cognito-identity-js';
import { authToast } from '../toast/auth';

export interface UserAttributes {
  email: string;
  sub: string;
  // eslint-disable-next-line camelcase
  given_name: string;
  // eslint-disable-next-line camelcase
  family_name: string;
  gender: string;
}

export interface AuthContextType {
  user: CognitoUser | null;
  loading: boolean;
  error?: string;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
  userAttributes: UserAttributes | null;
  firstLogin: boolean;
  completeNewPassword: (password: string) => void;
}

const getUserAttr = (user: CognitoUser) =>
  new Promise((resolve, reject) => {
    user?.getUserAttributes((_error, attributes) => {
      if (_error) {
        reject(_error);
        return;
      }
      const userAttributesArray = attributes?.map((attribute) => [
        attribute.getName(),
        attribute.getValue(),
      ]);

      if (userAttributesArray) {
        const userAttributes = Object.fromEntries(userAttributesArray);
        resolve(userAttributes);
      } else {
        resolve(null);
      }
    });
  });

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [userAttributes, setUserAttributes] = useState<UserAttributes | null>(null);
  const [error, setError] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [firstLogin, setFirstLogin] = useState<boolean>(false);
  const history = useHistory<{
    from: { pathname: string };
  }>();
  const { pathname } = useLocation();

  useEffect(() => {
    setError(null);
  }, [pathname]);

  useEffect(() => {
    if (user) {
      getUserAttr(user).then((attr) => {
        setUserAttributes(attr as unknown as UserAttributes);
      });
    }
  }, [user]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((u) => setUser(u))
      .catch(() => undefined)
      .finally(() => setLoadingInitial(false));
  }, []);

  const signIn = useCallback(
    (email: string, password: string) => {
      if (email === '' || password === '') {
        setError('Incorrect username or password.');
      } else {
        setLoading(true);
        Auth.signIn({
          username: email,
          password,
        })
          .catch((e) => {
            authToast('login-failed');
            setError(e.message);
          })
          .then((res: CognitoUser) => {
            setUser(res);
            if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setFirstLogin(true);
            } else {
              authToast('login-success');
              history.push(history.location.state?.from ?? '/');
            }
          })
          .finally(() => setLoading(false));
      }
    },
    [history]
  );

  const signOut = useCallback(() => {
    Auth.signOut().then(() => {
      setUser(null);
      authToast('logout-success');
      history.push('/auth');
    });
  }, [history]);

  const completeNewPassword = useCallback(
    async (password: string) => {
      setLoading(true);
      Auth.completeNewPassword(user, password)
        .then((u) => {
          setUser(u);
          setFirstLogin(false);
        })
        .catch(() => {
          setError('Failed to complete new password.');
        })
        .finally(() => setLoading(false));
    },
    [user]
  );

  // Make the provider update only when it should
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      signIn,
      signOut,
      userAttributes,
      firstLogin,
      completeNewPassword,
    }),
    [
      user,
      loading,
      error,
      signIn,
      signOut,
      userAttributes,
      firstLogin,
      completeNewPassword,
    ]
  );

  return (
    <AuthContext.Provider value={memoedValue as AuthContextType}>
      {!loadingInitial ? (
        children
      ) : (
        <Flex justify={'center'} align={'center'} h={'100vh'}>
          <Spinner />
        </Flex>
      )}
    </AuthContext.Provider>
  );
};

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
