import React from 'react';
import { Tag, TagProps } from '@chakra-ui/react';
import { AiFillApple, AiOutlineGoogle, FaFacebookF, MdEmail } from 'react-icons/all';
import { IdentityProvider } from '../../types/types';

export const IdentityProviderTag = ({
  provider,
  tagProps,
}: {
  provider: IdentityProvider;
  tagProps?: TagProps;
}) => {
  return (
    <Tag fontSize={'sm'} {...tagProps}>
      {{
        [IdentityProvider.EMAIL]: <MdEmail />,
        [IdentityProvider.APPLE]: <AiFillApple />,
        [IdentityProvider.FACEBOOK]: <FaFacebookF />,
        [IdentityProvider.GOOGLE]: <AiOutlineGoogle />,
      }[provider] || provider}
    </Tag>
  );
};
