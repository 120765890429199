import React from 'react';
import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

interface ModalProps {
  title: string;
  manualClose?: boolean;
  primaryAction: {
    title: string;
    callback: () => void;
    disabled?: boolean;
    loading?: boolean;
  };
  secondaryAction?: {
    title: string;
    callback?: () => void;
    disabled?: boolean;
    loading?: boolean;
  };
  OpenElement?: React.FC<any>;
  disclosureOverwrite?: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    title,
    primaryAction,
    secondaryAction,
    OpenElement,
    disclosureOverwrite,
    children,
    manualClose,
  } = props;
  const disclosure = useDisclosure();

  const { isOpen, onOpen, onClose } = disclosureOverwrite ?? disclosure;

  const secondaryActionHelper = () => {
    if (manualClose) return;
    if (!primaryAction?.loading || !secondaryAction?.loading) {
      if (secondaryAction?.callback) {
        secondaryAction?.callback();
      }
      onClose();
    }
  };

  return (
    <>
      {OpenElement && <OpenElement onClick={onOpen} />}
      <ChakraModal
        isCentered={true}
        size={'2xl'}
        isOpen={isOpen}
        onClose={secondaryActionHelper}>
        <ModalOverlay />
        <ModalContent
          maxH={{
            base: 'calc(100% - var(--chakra-space-20))',
            md: 'calc(100% - var(--chakra-space-20))',
          }}
          my={{ base: 10, md: 10 }}>
          <ModalHeader fontSize={'md'}>{title}</ModalHeader>
          {!manualClose && <ModalCloseButton />}
          <ModalBody maxH={'full'} overflowY={'auto'}>
            {children}
          </ModalBody>

          <ModalFooter>
            <Button
              size={'sm'}
              colorScheme={'blue'}
              isLoading={primaryAction?.loading}
              isDisabled={
                primaryAction?.loading ||
                secondaryAction?.loading ||
                primaryAction?.disabled
              }
              mr={3}
              onClick={() => {
                if (!primaryAction?.loading || !secondaryAction?.loading) {
                  primaryAction.callback();
                }
              }}>
              {primaryAction.title}
            </Button>
            {!manualClose && (
              <Button
                size={'sm'}
                isLoading={secondaryAction?.loading}
                isDisabled={
                  primaryAction?.loading ||
                  secondaryAction?.loading ||
                  manualClose ||
                  secondaryAction?.disabled
                }
                variant={'ghost'}
                onClick={secondaryActionHelper}>
                {secondaryAction?.title ?? 'Close'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
};
