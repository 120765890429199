import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { Form, FormWrapper } from '../../../components/form/Form';
import { addB2bUserToCompanyFormMapping } from '../../../data/valueMapping';
import { Modal } from '../../../components/modal/Modal';
import { useQueryWrapper } from '../../../util/hooks/useQueryWrapper';
import {
  B2bUsersQuery,
  useAddB2bUserToCompanyMutation,
  useB2bUsersQuery,
} from '../../../graphql/types-and-hooks';
import State from '../../../components/loading/State';
import { addB2bUserToCompanyValidator } from '../../../util/form-validators/add-b2b-user-to-company';

type B2bUsersParserResult = B2bUsersQuery['b2bUsers'];

export const AddUserToCompany = ({
  title,
  modalDisclosure,
  existingUsers,
}: {
  title: string;
  modalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
  existingUsers: string[];
}) => {
  const history = useHistory();

  const [b2bUsersQueryResult, b2bUsersParsedData] = useQueryWrapper({
    query: useB2bUsersQuery,
    parser: useCallback<(data: B2bUsersQuery) => B2bUsersParserResult>(
      (data) => data.b2bUsers,
      []
    ),
  });

  const [addUserToCompany, addUserToCompanyResult] = useAddB2bUserToCompanyMutation();

  const initialData = useMemo(() => {
    return {
      user: {
        options:
          b2bUsersParsedData
            ?.filter((user) => !existingUsers.includes(user.id))
            .map((user) => ({
              title: user.email,
              value: user.id,
            })) ?? [],
        value: '',
      },
    };
  }, [existingUsers, b2bUsersParsedData]);

  return (
    <FormWrapper
      initialData={initialData}
      dataMapping={addB2bUserToCompanyFormMapping}
      inEditMode={true}
      submitFunction={(formData) =>
        addUserToCompany({
          variables: {
            companyId: history.location.pathname.split('/')[2],
            userId: formData.user.value,
          },
        }).then(() => modalDisclosure.onClose())
      }
      validator={addB2bUserToCompanyValidator}>
      {(props) => (
        <Modal
          title={title}
          primaryAction={{
            title: 'Add User',
            callback: () => props.formik.submitForm(),
            loading: addUserToCompanyResult.loading,
          }}
          secondaryAction={{
            title: 'Cancel',
            callback: () => props.formik.resetForm(),
          }}
          disclosureOverwrite={modalDisclosure}>
          <Box>
            <State loading={b2bUsersQueryResult.loading}>
              <Form {...props} />
            </State>
          </Box>
        </Modal>
      )}
    </FormWrapper>
  );
};
