import React from 'react';
import { Link } from '@chakra-ui/react';

interface CustomLinkProps {
  href: string;
  isExternal?: boolean;
}

export const CustomLink: React.FC<CustomLinkProps> = (props) => {
  const { href, isExternal, children } = props;

  return (
    <Link
      lineHeight={'20px'}
      textDecoration={'none'}
      color={'#3182ce'}
      borderBottom={'1px'}
      borderColor={'#3182ce80'} // 80 = 50% opacity
      _hover={{ textDecoration: 'none', borderColor: '#3182ce' }}
      isExternal={!!isExternal}
      href={href}>
      {children}
    </Link>
  );
};
