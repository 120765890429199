import { ApolloLink } from '@apollo/client';
import { toast } from 'react-toastify';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getOperationDefinition } from '@apollo/client/utilities/graphql/getFromAST';
import { initMutationToast, updateMutationToast } from '../toast/mutation';

/**
 * Custom Apollo Link that "toasts" the user about the mutation state:
 *    - loading
 *    - error
 *    - success
 */
export const mutationToastLink = new ApolloLink((operation, forward) => {
  const definition = getMainDefinition(operation.query);

  if (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'mutation'
  ) {
    const toastId = initMutationToast(operation);

    if (toastId) {
      operation.setContext({ toastId });
    }

    return forward(operation).map((data) => {
      const error = data.errors?.[0];

      updateMutationToast(operation, error ? 'error' : 'success');

      return data;
    });
  }
  return forward(operation);
});

/**
 * "Toast" all the graphql or network errors
 */
export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const definition = getOperationDefinition(operation.query);
  const isMutation = definition?.operation === 'mutation';

  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (isMutation) {
        updateMutationToast(operation, 'error');
      } else {
        toast.error(`${operation.operationName}: ${message}`);
      }
    });
  if (networkError) {
    if (isMutation) {
      updateMutationToast(operation, 'error');
    } else if (networkError.message !== 'Failed to fetch') {
      toast.error(`${operation.operationName}: ${networkError.message}`);
    }
  }
});

export const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: () => navigator.onLine,
  },
});
