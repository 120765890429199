import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbProps,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface NavBreadcrumbProps extends BreadcrumbProps {
  location: { pathname: string };
}

const splitPathname = (pathname: string) =>
  pathname === '/' ? [] : pathname.split('/');

const generatePathnameFromArray = (breadcrumbs: Array<string>, index: number) =>
  breadcrumbs.slice(0, index + 1).join('/');

export const NavBreadcrumb = ({ location, ...rest }: NavBreadcrumbProps) => {
  const breadcrumbs: Array<string> = splitPathname(location.pathname);

  return (
    <Breadcrumb
      fontSize={'sm'}
      {...rest}
      separator={
        <Box color={'gray.400'} fontSize={'md'} pos={'relative'}>
          /
        </Box>
      }>
      {breadcrumbs.map((breadcrumb, index: number) => (
        <BreadcrumbItem
          key={breadcrumb}
          color={'inherit'}
          isCurrentPage={index + 1 === breadcrumbs.length}>
          <BreadcrumbLink as={Link} to={generatePathnameFromArray(breadcrumbs, index)}>
            {breadcrumb === ''
              ? 'Home'
              : breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
