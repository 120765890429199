import React from 'react';
import {
  Box,
  Skeleton as ChakraSkeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  StackDivider,
  useBreakpointValue,
} from '@chakra-ui/react';
import { breakpoints } from '../../../data/breakpoints';
import { FieldGroup } from '../../field-group/FieldGroup';

export const FieldGroupSkeleton = () => {
  return (
    <FieldGroup
      description={
        <>
          <SkeletonText skeletonHeight={'8'} mt={'4'} noOfLines={1} spacing={'4'} />
          <SkeletonText skeletonHeight={'4'} mt={'4'} noOfLines={2} spacing={'4'} />
        </>
      }>
      <SkeletonCircle size={'20'} />
      <SkeletonText skeletonHeight={'5'} mt={'4'} noOfLines={8} spacing={'6'} />
    </FieldGroup>
  );
};

export const FieldGroupViewSkeleton = (props: { repeat: number }) => {
  const { repeat } = props;

  const mainStackDivider = useBreakpointValue({
    [breakpoints.content]: <StackDivider />,
  });

  return (
    <Stack
      height={'full'}
      mt={'12'}
      justify={'center'}
      divider={mainStackDivider}
      direction={{ base: 'column-reverse', [breakpoints.content]: 'row' }}
      spacing={'4'}>
      <Box maxWidth={'3xl'} width={'100%'}>
        {Array(repeat)
          .fill(null)
          .map((_, index) => (
            <FieldGroupSkeleton key={index} />
          ))}
      </Box>
      <Box height={'full'}>
        <Box width={'full'} position={'sticky'} top={'2'}>
          <Stack direction={'row'}>
            <ChakraSkeleton height={'32px'} width={'32px'} />
            <ChakraSkeleton height={'32px'} width={'32px'} />
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
