import React from 'react';
import { AspectRatio, Box, Image, Text, useBreakpointValue } from '@chakra-ui/react';
import { CustomLink } from '../link/CustomLink';

const baseMapStaticUrl = 'https://maps.googleapis.com/maps/api/staticmap?';
const GOOGLE_MAP_API_KEY = 'AIzaSyCv7oynHtfZAO3G6qEFyxZvmdw58XsjX0U';

enum MarkerColor {
  black = 'black',
  brown = 'brown',
  green = 'green',
  purple = 'purple',
  yellow = 'yellow',
  blue = 'blue',
  gray = 'gray',
  orange = 'orange',
  red = 'red',
  white = 'white',
}

enum MarkerSize {
  tiny = 'tiny',
  mid = 'mid',
  small = 'small',
}

interface StaticMapProps {
  width?: number;
  height?: number;
  scale?: 1 | 2;
  marker: {
    color?: MarkerColor;
    size?: MarkerSize;
    label?: string;
    location: {
      long: number;
      lat: number;
    };
  };
  zoom?: 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20;
  placeId?: string;
  address?: string;
}

export const StaticMap: React.FC<StaticMapProps> = (props) => {
  const {
    width = 800,
    height = 500,
    scale = 2,
    zoom = 14,
    marker,
    placeId,
    address,
  } = props;
  const {
    color: markerColor = MarkerColor.red,
    size: markerSize = MarkerSize.mid,
    label: markerLabel = '',
    location: { long, lat },
  } = marker;

  const dimensionDivider = useBreakpointValue({ base: 2, sm: 1.5, md: 1 });

  const alphanumericUppercaseRegex = /^[A-Z1-9]$/;

  const checkedMarkerLabel = alphanumericUppercaseRegex.test(markerLabel)
    ? markerLabel
    : '';

  const sizeParam = `size=${Math.round(width / (dimensionDivider ?? 1))}x${Math.round(
    height / (dimensionDivider ?? 1)
  )}`;
  const scaleParam = `scale=${scale}`;
  const markerParam = `markers=size:${markerSize}|color:${markerColor}|label:${checkedMarkerLabel}|${lat}, ${long}`;
  const zoomParam = `zoom=${zoom}`;
  const apiKeyParam = `key=${GOOGLE_MAP_API_KEY}`;

  return (
    <Box
      position={'relative'}
      mx={{ sm: 10, md: 0, lg: 10 }}
      borderRadius={12}
      overflow={'hidden'}>
      <AspectRatio ratio={width / height}>
        <Image
          src={`${baseMapStaticUrl}${sizeParam}&${scaleParam}&${markerParam}&${zoomParam}&${apiKeyParam}`}
        />
      </AspectRatio>
      {address && (
        <Box
          bottom={3}
          left={3}
          position={'absolute'}
          width={'fit-content'}
          bg={'white'}
          borderRadius={8}
          boxShadow={'0 2px 5px #0f0f0f66'}
          p={2}>
          <Text color={'black'} fontSize={'sm'}>
            {address}
          </Text>
        </Box>
      )}
      {placeId && (
        <Box
          top={3}
          right={3}
          position={'absolute'}
          width={'fit-content'}
          bg={'white'}
          borderRadius={8}
          boxShadow={'0 2px 5px #0f0f0f66'}
          p={2}>
          <CustomLink
            href={`https://www.google.com/maps/place/?q=place_id:${placeId}`}
            isExternal={true}>
            Open in Google Maps
          </CustomLink>
        </Box>
      )}
    </Box>
  );
};
