import React from 'react';
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  WrapItem,
} from '@chakra-ui/react';
import { FiFilter, FiX } from 'react-icons/all';
import { FilterWrapperChildProps } from './FilterWrapper';
import { IFilterValue } from '../../types/types';

export const AddFilter = <A extends string = string, V extends IFilterValue = string>({
  filters,
  filterAccessorsInUse,
  addFilter,
  removeFilter,
}: FilterWrapperChildProps<A, V>) => {
  return (
    <WrapItem>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FiFilter />}
          size={'sm'}
          aria-label={'Chats Filter'}
        />
        <MenuList zIndex={11}>
          {filters
            .filter(({ hiddenInMenu }) => !hiddenInMenu)
            .map((filter, index) => (
              <MenuItem
                key={index}
                // 24px   IconButton height when size "xs"
                // 0.8rem Two times the y padding of MenuItem (2 x 0.4rem)
                minH={'calc(24px + 0.8rem)'}
                justifyContent={'space-between'}
                alignItems={'center'}
                fontSize={'sm'}
                isDisabled={
                  filterAccessorsInUse.includes(filter.accessor) || filter.disabled
                }
                _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                onClick={() => addFilter(filter)}>
                <Text
                  disabled={
                    filterAccessorsInUse.includes(filter.accessor) || filter.disabled
                  }
                  _disabled={{ opacity: 0.4 }}>
                  {filter.label}
                </Text>
                {filterAccessorsInUse.includes(filter.accessor) && (
                  <Flex
                    justify={'center'}
                    align={'center'}
                    height={'24px'}
                    width={'24px'}
                    cursor={'pointer'}
                    color={'red.400'}
                    _hover={{ color: 'red' }}
                    onClick={() => {
                      removeFilter(filter);
                    }}>
                    <FiX fontSize={'xs'} />
                  </Flex>
                )}
              </MenuItem>
            ))}
        </MenuList>
      </Menu>
    </WrapItem>
  );
};
