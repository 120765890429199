import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  Switch,
  Textarea,
  TextareaProps,
  useColorModeValue as mode,
  UseCheckboxProps,
  Checkbox,
  Flex,
} from '@chakra-ui/react';
import { Select, Options } from 'chakra-react-select';
import { ISelectOption } from '../../types/types';

export interface InputFieldBaseProps<TAdditionalInputProps = any>
  extends InputFieldWrapperProps {
  inputName: string;
  inEditMode?: boolean;
  isReadOnly?: boolean;
  additionalInputProps: TAdditionalInputProps;
}

interface InputFieldWrapperProps {
  inputName: string;
  error: string;
  label?: string;
  helperText?: string;
  isRequired?: boolean;
}

const InputFieldWrapper: React.FC<InputFieldWrapperProps> = (props) => {
  const { inputName, error, label, children, helperText, isRequired = false } = props;

  return (
    <FormControl name={inputName} isInvalid={!!error} isRequired={isRequired}>
      {label && <FormLabel fontSize={'sm'}>{label}</FormLabel>}
      {children}
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export type InputFieldProps = InputFieldBaseProps<InputProps>;

export const InputField = (props: InputFieldProps) => {
  const { inEditMode, isReadOnly, additionalInputProps, ...wrapperProps } = props;

  const isReadOnlyOrNotInEditMode = isReadOnly || !inEditMode;

  return (
    <InputFieldWrapper {...wrapperProps}>
      <Input
        size={'sm'}
        color={isReadOnlyOrNotInEditMode ? mode('gray', 'gray.400') : 'inherit'}
        bg={isReadOnlyOrNotInEditMode ? mode('gray.200', 'gray.600') : 'unset'}
        style={{ userSelect: 'text' }}
        isReadOnly={isReadOnlyOrNotInEditMode}
        {...additionalInputProps}
      />
    </InputFieldWrapper>
  );
};

type TextAreaFieldProps = InputFieldBaseProps<TextareaProps>;

export const TextAreaField = (props: TextAreaFieldProps) => {
  const { inEditMode, isReadOnly, additionalInputProps, ...wrapperProps } = props;

  const isReadOnlyOrNotInEditMode = isReadOnly || !inEditMode;

  return (
    <InputFieldWrapper {...wrapperProps}>
      <Textarea
        size={'sm'}
        color={isReadOnlyOrNotInEditMode ? mode('gray', 'gray.400') : 'inherit'}
        bg={isReadOnlyOrNotInEditMode ? mode('gray.200', 'gray.600') : 'unset'}
        style={{ userSelect: 'text' }}
        isReadOnly={isReadOnlyOrNotInEditMode}
        type={'text-area'}
        {...additionalInputProps}
      />
    </InputFieldWrapper>
  );
};

type SwitchFieldProps = InputFieldBaseProps<UseCheckboxProps>;

export const SwitchField: React.FC<SwitchFieldProps> = (props) => {
  const { inEditMode, isReadOnly, additionalInputProps, ...wrapperProps } = props;

  const isReadOnlyOrNotInEditMode = isReadOnly || !inEditMode;

  return (
    <InputFieldWrapper {...wrapperProps}>
      <Switch
        size={'md'}
        isReadOnly={isReadOnlyOrNotInEditMode}
        colorScheme={isReadOnlyOrNotInEditMode ? 'inherit' : 'blue'}
        {...additionalInputProps}
        type={''}
      />
    </InputFieldWrapper>
  );
};

interface SelectPropsWithOptions {
  options: ISelectOption[];
  onChange?: (value: string | number) => void;
  value?: string | number;
}

type SelectFieldProps = InputFieldBaseProps<SelectPropsWithOptions>;

export const SelectField: React.FC<SelectFieldProps> = (props) => {
  const {
    inEditMode,
    isReadOnly,
    additionalInputProps: additional,
    ...wrapperProps
  } = props;
  const { options, onChange, value, ...additionalInputProps } = additional;

  const isReadOnlyOrNotInEditMode = isReadOnly || !inEditMode;

  const selectedOption = options.find((o) => o.value === value);

  return (
    <InputFieldWrapper {...wrapperProps}>
      <Select
        size={'sm'}
        isReadOnly={isReadOnlyOrNotInEditMode}
        isDisabled={isReadOnlyOrNotInEditMode}
        colorScheme={isReadOnlyOrNotInEditMode ? 'gray' : 'inherit'}
        useBasicStyles
        menuPortalTarget={document.body}
        placeholder={'Select option'}
        value={
          selectedOption
            ? {
                label: selectedOption.title,
                value: selectedOption.value,
              }
            : undefined
        }
        options={
          options?.map((optionValue) => ({
            value: optionValue.value,
            label: optionValue.title,
          })) as Options<any>
        }
        onChange={(selectedValue) => {
          if (selectedValue) {
            onChange?.(selectedValue.value);
          }
        }}
        styles={{
          menuPortal: (provided) => ({
            ...provided,
            zIndex: 'var(--chakra-zIndices-tooltip)',
          }),
        }}
        {...additionalInputProps}
      />
    </InputFieldWrapper>
  );
};

type CheckboxFieldProps = InputFieldBaseProps<UseCheckboxProps>;

export const CheckboxField: React.FC<CheckboxFieldProps> = (props) => {
  const { inEditMode, isReadOnly, additionalInputProps, ...wrapperProps } = props;

  const isReadOnlyOrNotInEditMode = isReadOnly || !inEditMode;

  return (
    <InputFieldWrapper
      inputName={wrapperProps.inputName}
      error={wrapperProps.error}
      isRequired={wrapperProps.isRequired}
      helperText={wrapperProps.helperText}>
      <Flex>
        <Checkbox
          size={'md'}
          isReadOnly={isReadOnlyOrNotInEditMode}
          colorScheme={isReadOnlyOrNotInEditMode ? 'inherit' : 'blue'}
          isInvalid={!!wrapperProps.error}
          {...additionalInputProps}
          type={''}>
          <FormLabel marginBottom={0} fontSize={'sm'}>
            {wrapperProps.label}
          </FormLabel>
        </Checkbox>
      </Flex>
    </InputFieldWrapper>
  );
};
