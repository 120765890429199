import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { Form, FormWrapper } from '../../../components/form/Form';
import { createB2bUserFormMapping } from '../../../data/valueMapping';
import { Modal } from '../../../components/modal/Modal';
import {
  CreateB2bUserResponse,
  useAddB2bUserToCompanyMutation,
  useCreateB2bUserMutation,
} from '../../../graphql/types-and-hooks';
import { createB2bUserValidator } from '../../../util/form-validators/create-b2b-user';
import { ClipboardButton } from '../../../components/buttons/ClipboardButton';

export const CreateB2bUser = ({
  title,
  modalDisclosure,
}: {
  title: string;
  modalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}) => {
  const [createdUser, setCreatedUser] = useState<CreateB2bUserResponse | null>(null);
  const [passwordCopied, setPasswordCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const [createB2bUser] = useCreateB2bUserMutation();
  const [addUserToCompany] = useAddB2bUserToCompanyMutation();

  const handleSubmit = async (data: { email: string }) => {
    setLoading(true);

    const userCreationResponse = await createB2bUser({
      variables: {
        email: data.email,
      },
    });

    if (!userCreationResponse?.data?.createB2bUser) return;

    await addUserToCompany({
      variables: {
        userId: userCreationResponse.data.createB2bUser.id,
        companyId: history.location.pathname.split('/')[2],
      },
    });

    setLoading(false);
    setCreatedUser(userCreationResponse.data.createB2bUser);
  };

  useEffect(() => {
    if (!modalDisclosure.isOpen) setPasswordCopied(false);
  }, [modalDisclosure.isOpen]);

  const showPasswordCopyScreen = useMemo(() => !!createdUser, [createdUser]);

  return (
    <FormWrapper
      initialData={{
        email: '',
      }}
      dataMapping={createB2bUserFormMapping}
      inEditMode={true}
      submitFunction={handleSubmit}
      validator={createB2bUserValidator}>
      {(props) => (
        <Modal
          manualClose={showPasswordCopyScreen}
          title={title}
          primaryAction={{
            title: showPasswordCopyScreen ? 'Close' : 'Create user',
            disabled: !passwordCopied && showPasswordCopyScreen,
            callback: async () => {
              if (showPasswordCopyScreen) {
                modalDisclosure.onClose();
              } else {
                await props.formik.submitForm();
              }
            },
            loading,
          }}
          secondaryAction={{
            title: 'Cancel',
            callback: () => props.formik.resetForm(),
          }}
          disclosureOverwrite={modalDisclosure}>
          <Box>
            {`${loading}`}
            {showPasswordCopyScreen && (
              <Flex direction={'column'} justify={'center'} align={'center'}>
                <Heading size={'sm'} mb={'4'}>
                  User has been created!
                </Heading>
                <Flex mb={'4'}>
                  <Box mr={'2'}>
                    <Text>Email:</Text>
                    <Text>Password:</Text>
                  </Box>
                  <Box ml={'2'}>
                    <Text>{createdUser?.email}</Text>
                    <ClipboardButton
                      onCopyCallback={() => setPasswordCopied(true)}
                      title={'⋆'.repeat(12)}
                      valueToCopy={createdUser?.password ?? ''}
                    />
                  </Box>
                </Flex>
                <Text fontSize={'xs'} color={'red'}>
                  A temporary password has been created. It must be used for the first
                  login in order to set a new password. It&apos;s recommended to copy
                  the password now and safe it somewhere secure.
                </Text>
              </Flex>
            )}
            {!showPasswordCopyScreen && <Form {...props} />}
          </Box>
        </Modal>
      )}
    </FormWrapper>
  );
};
