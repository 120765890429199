import React, { useCallback } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { FiArrowRight, FiBook, FiCheck, FiUsers } from 'react-icons/all';
import { StatCard, StatCardProps } from '../components/stat/StatCard';
import { StatsQuery, useStatsQuery } from '../graphql/types-and-hooks';
import { useQueryWrapper } from '../util/hooks/useQueryWrapper';

type Icons = Record<string, { icon: React.ElementType; color: string }>;

const icons: Icons = {
  People: {
    icon: FiUsers,
    color: '#f2a900',
  },
  Objects: {
    icon: FiBook,
    color: '#ee8c28',
  },
  Matches: {
    icon: FiCheck,
    color: '#17e340',
  },
  Swipes: {
    icon: FiArrowRight,
    color: '',
  },
};

const Home = () => {
  const [statsQueryResult, statsParsedData] = useQueryWrapper({
    query: useStatsQuery,
    options: {},
    parser: useCallback<(data: StatsQuery) => StatsQuery['stats']>(
      (data) => data.stats,
      []
    ),
  });

  const stats = statsParsedData;

  const data: StatCardProps['data'][] = [
    {
      symbol: 'People',
      label: 'Registered Users',
      value: stats?.users ?? '',
    },
    {
      symbol: 'Objects',
      label: 'Active Objects',
      value: stats?.objects ?? '',
    },
    {
      symbol: 'Matches',
      label: 'Total matches',
      value: stats?.matches ?? '',
    },
    {
      symbol: 'Swipes',
      label: 'Total Swipes',
      value: stats?.swipes ?? '',
    },
  ];

  return (
    <Box as={'section'} p={'10'}>
      <Box maxW={'7xl'} mx={'auto'} px={{ base: '6', md: '0' }}>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 4 }}
          spacing={'4'}
          height={'fit-content'}>
          {data.map((stat, index) => {
            const { icon, color: accentColor } = icons[stat.symbol];
            return (
              <StatCard
                error={statsQueryResult.error}
                loading={statsQueryResult.loading}
                icon={icon}
                accentColor={accentColor}
                key={index}
                data={stat}
              />
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Home;
