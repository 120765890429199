import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Stack, Tag, Text, useColorModeValue as mode } from '@chakra-ui/react';
import { FiDownloadCloud } from 'react-icons/all';
import { ApolloQueryResult } from '@apollo/client';
import {
  ConsentAttributesQuery,
  ObjectDocumentNode,
  ObjectQuery,
  ObjectSwipesQuery,
  useConsentAttributesQuery,
  useDeleteObjectPictureMutation,
  useModifyObjectAutoConsentsMutation,
  useMultipleUploadObjectPictureMutation,
  useObjectQuery,
  useObjectSwipesQuery,
  useRejectObjectMutation,
  useReorderObjectPicturesMutation,
  useToggleObjectMutation,
  useUpdateInternalNoteMutation,
  useUpdateObjectLocationMutation,
  useVerifyObjectMutation,
} from '../../graphql/types-and-hooks';
import { parseObjectSwipes } from '../../util/query-data-parser/swipe-parser';
import { StaticMap } from '../../components/google/StaticMap';
import { EditableTag } from '../../components/form/EditableTag';
import { ClipboardButton } from '../../components/buttons/ClipboardButton';
import { PropertiesWithData } from '../../components/form/Properties';
import State from '../../components/loading/State';
import { ActionZone } from '../../components/action-zone/ActionZone';
import { FieldGroupViewSkeleton } from '../../components/loading/skeleton/FieldGroupSkeleton';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { Tabs } from '../../components/tabs/Tabs';
import Table from '../../components/table/Table';
import { ActionButtonGroup } from '../../components/buttons/ActionButtons';
import { Images } from '../../components/image/Images';
import { IImage } from '../../components/image/ImageField';
import { stringToBoolean } from '../../util/string-to-boolean';
import { TableActions } from '../../components/table/TableActions';
import { DateTag } from '../../components/tags/DateTag';
import { useFilter } from '../../util/hooks/useFilter';
import { swipeFilters } from './data/filters';
import { swipeColumns } from './data/columns';
import { TableContent } from '../../components/table/TableContent';
import { IFormMapping } from '../../types/types';
import { parseFormDataToAutoConsents } from '../../util/query-data-parser/auto-consents-parser';
import { AutoConsentForm } from '../../components/consent/AutoConsent';
import { parseConsentAttributesToFormMap } from '../../util/query-data-parser/consent-attributes-parser';
import { NothingChangedError } from '../../types/errors';
import { Form, FormWrapper } from '../../components/form/Form';
import { createObjectShareLink } from '../../util/object_share_link';

const ObjectView = () => {
  const { objectId } = useParams<{ objectId: string }>();
  const location = useLocation();

  // TODO: add action
  const actionButtonItems = [
    {
      'aria-label': 'Download Object Data',
      icon: <FiDownloadCloud />,
    },
  ];

  const { filterValues, statefulFilters } = useFilter<
    'positive' | 'matched',
    'true' | 'false' | null
  >({
    filters: swipeFilters,
  });

  const [objectQueryResult, objectParsedData] = useQueryWrapper({
    query: useObjectQuery,
    options: { variables: { objectId } },
    parser: useCallback<(data: ObjectQuery) => Map<string, any>>(
      (data) => new Map<string, any>(Object.entries(data.object)),
      []
    ),
  });

  const [consentAttributesResult, consentAttributesParsedData] = useQueryWrapper({
    query: useConsentAttributesQuery,
    parser: useCallback<(data: ConsentAttributesQuery) => IFormMapping>(
      (data) => parseConsentAttributesToFormMap(data),
      []
    ),
  });

  const [{ refetch: refetchSwipes, ...swipesQueryResult }, swipesParsedData] =
    useQueryWrapper({
      query: useObjectSwipesQuery,
      options: {
        variables: { objectId },
      },
      parser: useCallback<(data: ObjectSwipesQuery) => Map<string, any>[]>(
        (data) => parseObjectSwipes(data, objectId),
        [objectId]
      ),
    });

  const [toggleObject] = useToggleObjectMutation({
    variables: { id: objectId },
    refetchQueries: [ObjectDocumentNode],
    awaitRefetchQueries: true,
  });
  const [updateInternalNote] = useUpdateInternalNoteMutation();
  const [multipleUploadObjectPicture] = useMultipleUploadObjectPictureMutation();
  const [deleteObjectPicture] = useDeleteObjectPictureMutation();
  const [reorderObjectPictures] = useReorderObjectPicturesMutation();
  const [verifyObject] = useVerifyObjectMutation({
    variables: { objectId },
    refetchQueries: [ObjectDocumentNode],
    awaitRefetchQueries: true,
  });
  const [rejectObject] = useRejectObjectMutation({
    variables: { objectId },
    refetchQueries: [ObjectDocumentNode],
    awaitRefetchQueries: true,
  });
  const [modifyObjectAutoConsents] = useModifyObjectAutoConsentsMutation({
    refetchQueries: [ObjectDocumentNode],
    awaitRefetchQueries: true,
  });
  const [updateObjectLocation] = useUpdateObjectLocationMutation({
    refetchQueries: [ObjectDocumentNode],
    awaitRefetchQueries: true,
  });

  const toggleHandler = (
    newState: boolean,
    oldState: boolean,
    func: () => Promise<any>
  ) => {
    return new Promise((resolve, reject) => {
      if (oldState !== newState) {
        resolve(func());
      } else {
        reject(new Error('toggleHandler expects the opposite of current active state'));
      }
    });
  };
  const deleteImageHandler = useCallback(
    async (imageId: string) => {
      await deleteObjectPicture({
        variables: { objectId, id: imageId },
        refetchQueries: [ObjectDocumentNode],
      });
    },
    [deleteObjectPicture, objectId]
  );
  const updateInternalNoteHandler = (updatedNote?: string) => {
    return new Promise<Promise<ApolloQueryResult<any>> | ApolloQueryResult<any>>(
      (resolve, reject) => {
        if (objectId) {
          resolve(
            updateInternalNote({
              variables: { id: objectId, internalNote: updatedNote },
            }).then(() => objectQueryResult.refetch())
          );
        } else {
          reject(new Error('object id is not set'));
        }
      }
    );
  };
  const getUploadUrls = useCallback(
    (count: number) =>
      multipleUploadObjectPicture({ variables: { objectId, count } }).then(
        ({ data }) =>
          data?.multiple.map(({ uploadObjectPicture }) => uploadObjectPicture) ?? []
      ),
    [multipleUploadObjectPicture, objectId]
  );
  const reorderObjectPicturesCallback = useCallback(
    (imageIds) =>
      reorderObjectPictures({
        variables: { objectId, order: imageIds },
        refetchQueries: [ObjectDocumentNode],
      }),
    [reorderObjectPictures, objectId]
  );

  useEffect(() => {
    refetchSwipes({
      objectId,
      filter: {
        positive: stringToBoolean(filterValues.positive),
        matched: stringToBoolean(filterValues.matched),
      },
    })?.finally();
  }, [objectId, filterValues, refetchSwipes]);

  const memoizedImages = useMemo(
    () =>
      objectParsedData?.get('images').map(
        (image: IImage) =>
          ({
            ...image,
            deleteImageCallback: deleteImageHandler,
          } as IImage)
      ),
    [objectParsedData, deleteImageHandler]
  );

  return (
    <State
      loading={objectQueryResult.loading && !objectQueryResult.refetching}
      customLoadingElement={<FieldGroupViewSkeleton repeat={1} />}
      error={objectQueryResult.error}>
      {objectParsedData && (
        <Stack height={'full'}>
          <Stack direction={'row'} align={'center'} mt={1} flexWrap={'wrap'}>
            <EditableTag
              submitCallback={updateInternalNoteHandler}
              text={objectParsedData.get('internalNote')}
              placeholder={'Internal Note'}
            />
            <ClipboardButton
              title={'Copy ID'}
              valueToCopy={objectParsedData.get('id')}
            />
            <ClipboardButton
              title={'Copy Link'}
              valueToCopy={createObjectShareLink(objectParsedData.get('publicId'))}
            />
            <Tag
              fontSize={'xs'}
              colorScheme={objectParsedData.get('active') ? 'green' : 'red'}>
              {objectParsedData.get('active') ? 'Active' : 'Disabled'}
            </Tag>
            <Tag
              fontSize={'xs'}
              colorScheme={objectParsedData.get('verified') ? 'green' : 'red'}>
              {objectParsedData.get('verified') ? 'Verified' : 'Not Verified'}
            </Tag>
            <DateTag date={objectParsedData.get('createdAt')} title={'Created'} />
            {/* <RefetchButton size={'xs'} /> */}
          </Stack>
          <ActionButtonGroup items={actionButtonItems} includeRefetch={true} />
          <Tabs
            panels={[
              {
                title: 'Object Properties',
                child: <PropertiesWithData objectId={objectId} />,
              },
              {
                title: 'Swipes',
                child: (
                  <Table
                    apolloState={{
                      loading: swipesQueryResult.loading,
                      error: swipesQueryResult.error,
                    }}>
                    {({ apolloState }) => (
                      <>
                        <TableActions filters={statefulFilters} />
                        <TableContent
                          apolloState={apolloState}
                          columns={swipeColumns}
                          data={swipesParsedData ?? []}
                        />
                      </>
                    )}
                  </Table>
                ),
              },
              {
                title: 'Images',
                child: (
                  <Images
                    images={memoizedImages}
                    imageUpload={{
                      getUploadUrls,
                      uploadGroup: objectParsedData.get('internalNote')
                        ? {
                            id: objectParsedData
                              .get('internalNote')
                              .toLocaleLowerCase()
                              .replace(/\s/g, ''),
                            title: objectParsedData.get('internalNote'),
                          }
                        : undefined,
                      pathToMatchWhenRefetching: location.pathname,
                    }}
                    imageReorderMutation={reorderObjectPicturesCallback}
                  />
                ),
              },
              {
                title: 'Map',
                child: (
                  <>
                    <StaticMap
                      placeId={objectParsedData.get('location')?.placeId}
                      marker={{
                        location: {
                          long: objectParsedData.get('location').longitude,
                          lat: objectParsedData.get('location').latitude,
                        },
                      }}
                      address={objectParsedData.get('location')?.address}
                    />
                    <Stack align={'center'} mt={5} mx={{ sm: 10, md: 0, lg: 10 }}>
                      <FormWrapper
                        initialData={{
                          placeId: '',
                        }}
                        dataMapping={{
                          placeId: {
                            type: 'placeId',
                            isRequired: true,
                          },
                        }}
                        inEditMode={true}
                        validator={(data) => {
                          if (data.placeId === '')
                            return { placeId: 'You must provide a location.' };
                          if (typeof data.placeId !== 'string')
                            return {
                              placeId: 'Something is wrong with this input field.',
                            };
                          return {};
                        }}
                        submitFunction={(data) =>
                          updateObjectLocation({
                            variables: { objectId, placeId: data.placeId },
                          })
                        }>
                        {(props) => (
                          <Stack
                            width={'100%'}
                            align={'center'}
                            backgroundColor={mode('gray.50', 'gray.800')}
                            p={4}
                            borderRadius={12}>
                            <Text fontSize={'sm'}>Update Location</Text>
                            <Stack
                              width={'100%'}
                              maxW={'md'}
                              direction={'row'}
                              align={'center'}>
                              <Form {...props} />

                              <Button
                                isLoading={props.formik.isSubmitting}
                                onClick={props.formik.submitForm}
                                disabled={
                                  props.formik.isSubmitting ||
                                  props.formik.isValidating ||
                                  props.formik.values.placeId === ''
                                }
                                colorScheme={'green'}
                                size={'sm'}>
                                Update
                              </Button>
                            </Stack>
                          </Stack>
                        )}
                      </FormWrapper>
                    </Stack>
                  </>
                ),
              },
              {
                title: 'Auto Consent',
                child: (
                  <AutoConsentForm
                    initialData={objectParsedData.get('autoConsents')}
                    dataMapping={consentAttributesParsedData ?? {}}
                    submitFunction={(formData) =>
                      new Promise<any>((resolve, reject) => {
                        const autoConsents = parseFormDataToAutoConsents(
                          formData,
                          objectParsedData.get('autoConsents')
                        );
                        if (autoConsents.length === 0) {
                          reject(
                            new NothingChangedError(
                              'None of the data has changed. Cannot submit an empty array'
                            )
                          );
                          return;
                        }
                        resolve(
                          modifyObjectAutoConsents({
                            variables: {
                              objectId,
                              input: autoConsents,
                            },
                          })
                        );
                      })
                    }
                    loading={consentAttributesResult.loading}
                  />
                ),
              },
              {
                title: 'Actions',
                child: (
                  <ActionZone
                    actions={[
                      {
                        title: 'Toggle Status',
                        description: (
                          <>
                            <Text color={'orange'} fontWeight={'semibold'}>
                              Activating the object may make it visible in the App.
                            </Text>
                            <Text fontStyle={'oblique'}>
                              When the property &quot;Warm Rent&quot; is missing the
                              object will not turn active.
                            </Text>
                            <Text fontStyle={'oblique'}>
                              Note that an object must be &quot;active&quot; and
                              &quot;verified&quot; in order to show it in the App.
                            </Text>
                          </>
                        ),
                        actionType: 'toggle',
                        toggle: {
                          isChecked: objectParsedData.get('active'),
                          onToggle: ({ target: { checked } }) =>
                            toggleHandler(
                              objectParsedData.get('active'),
                              checked,
                              toggleObject
                            ),
                        },
                      },
                      {
                        title: 'Toggle Verified',
                        description: (
                          <>
                            <Text color={'orange'} fontWeight={'semibold'}>
                              Please make sure that the objects content is not offensive
                              before you verify it.
                            </Text>
                            <Text fontStyle={'oblique'}>
                              Note that an object must be &quot;active&quot; and
                              &quot;verified&quot; in order to show it in the App.
                            </Text>
                          </>
                        ),
                        actionType: 'toggle',
                        toggle: {
                          isChecked: objectParsedData.get('verified'),
                          onToggle: ({ target: { checked } }) =>
                            toggleHandler(
                              objectParsedData.get('verified'),
                              checked,
                              checked ? verifyObject : rejectObject
                            ),
                        },
                      },
                    ]}
                  />
                ),
              },
            ]}
          />
        </Stack>
      )}
    </State>
  );
};

export default ObjectView;
