import React from 'react';
import { Icon, useColorModeValue as mode } from '@chakra-ui/react';
import { BsCheck, BsCheckAll } from 'react-icons/all';
import { ChatQuery } from '../../graphql/types-and-hooks';

interface ICheckIfReadByAll {
  participants: ChatQuery['chat']['participants'];
  readBy: (string | null | undefined)[];
}

const checkIfReadByAll = ({ participants, readBy }: ICheckIfReadByAll) =>
  participants?.every((participant) => readBy?.includes(participant.id));

interface ReadCheckIconProps extends Partial<ICheckIfReadByAll> {
  isUnsent?: boolean;
}

export const ReadCheckIcon: React.FC<ReadCheckIconProps> = (props) => {
  const { isUnsent = false, participants, readBy } = props;

  let readByAll: boolean | undefined;

  if (participants && readBy) {
    readByAll = checkIfReadByAll({
      participants: participants.filter((participant) => !participant.me),
      readBy,
    });
  }

  return (
    <Icon
      opacity={isUnsent ? mode(1, 0.5) : 1}
      as={readByAll ? BsCheckAll : BsCheck}
      ml={1}
      color={isUnsent ? mode('gray.500', 'white') : mode('green.400', 'green.300')}
    />
  );
};
