import moment from 'moment';
import { IDict, PropertyType } from '../../types/types';
import { ObjectProperty } from '../../graphql/types-and-hooks';

export const propertyValidator = (property: ObjectProperty, values: any) => {
  const errors: IDict<any> = {};
  const { type, name, constraints } = property;
  let error = '';

  const value = values[name];
  if (value === '' || constraints?.enum ? !constraints?.enum?.includes(value) : false) {
    error = 'Required';
    errors[name] = error;
    return errors;
  }

  if (PropertyType.tags === type) {
    if (constraints?.minCount > value.length) {
      error = `Tag count does not meet the minimum constrain of ${constraints.minCount}`;
    } else if (constraints?.maxCount < value.length) {
      error = `Tag count does not meet the maximum constrain of${constraints.maxCount}`;
    }
  } else if (PropertyType.number === type) {
    if (!/^-?([0-9]*)(\.\d+)?$/.test(value)) {
      error = 'Not a valid number';
    } else if (constraints?.digits) {
      if (!new RegExp(`^-?([0-9]*)(\\.\\d{1,${constraints.digits}})?$`).test(value)) {
        error = `Number can not have more digits than ${constraints.digits}`;
      }
    } else if (constraints?.min > parseInt(value, 10)) {
      error = `Number does not meet the minimum constrain of ${constraints.min}`;
    } else if (constraints?.max < parseInt(value, 10)) {
      error = `Number does not meet the maximum constrain of${constraints.max}`;
    }
  } else if (PropertyType.string === type) {
    if (constraints?.minlength > value.length) {
      error = `Value does not meet the minimum length of ${constraints.minlength}`;
    } else if (constraints.maxLength < value.length) {
      error = `Value does not meet the maximum length of ${constraints.minlength}`;
    } else if (constraints.pattern) {
      if (new RegExp(constraints.pattern).test(value)) {
        error = `Value does not match the pattern ${constraints.pattern}`;
      }
    }
  } else if (PropertyType.bool === type) {
    if (!/^true$|^false$/.test(value)) {
      error = 'Must be true or false';
    }
  } else if (PropertyType.date === type || PropertyType.datetime === type) {
    if (constraints?.min) {
      error =
        moment(constraints.min) > moment(value)
          ? `Selected Time does not meet the minimum: ${moment(
              constraints.min
            ).format()}`
          : '';
    }
    if (constraints?.max) {
      error =
        moment(constraints.max) < moment(value)
          ? `Selected Time does not meet the maximum: ${moment(
              constraints.min
            ).format()}`
          : '';
    }
    if (constraints?.future) {
      error = moment() > moment(value) ? 'Selected Time is not in the future' : '';
    }
    if (constraints?.past) {
      error = moment() < moment(value) ? 'Selected Time is not in the past' : '';
    }
  } else if (PropertyType.text === type) {
    if (!value.some((text: any) => text.text !== '') || !value) {
      error = 'At least one text needs to contain something!';
    }
  }

  if (error !== '') {
    errors[name] = error;
  }
  return errors;
};
