import { ApolloCache } from '@apollo/client';
import {
  ChatMessagesDocumentNode,
  ChatMessagesQuery,
} from '../../graphql/types-and-hooks';

export const updateCacheWithNewMessage = <TSerialized>(
  chatId: string,
  cache: ApolloCache<TSerialized>,
  newMessage: ChatMessagesQuery['chatMessages'][0] | undefined | null
) => {
  if (newMessage) {
    cache.updateQuery(
      { query: ChatMessagesDocumentNode, variables: { chatId } },
      ({ chatMessages }) => {
        // If the cache was already updated it will remove the previous assigned fragment from the array
        const filteredChatMessages = chatMessages.filter(
          (chatMessage: { id: string }) => chatMessage.id !== newMessage.id
        );

        return {
          chatMessages: [...filteredChatMessages, newMessage],
        };
      }
    );
  }
};
