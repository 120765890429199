import { Badge, Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FiExternalLink } from 'react-icons/all';
import moment from 'moment';
import { ClipboardButton } from '../../../components/buttons/ClipboardButton';
import { Landlord } from '../../../graphql/types-and-hooks';
import { ObjectCell } from '../../../components/cell/ObjectCell';
import { ColumnProps } from '../../../components/table/TableContent';

export const objectColumns: Array<ColumnProps> = [
  {
    header: 'Object',
    accessors: ['images', 'id', 'internalNote'],
    Cell: ({
      id,
      images,
      internalNote,
    }: {
      images: { url: string }[];
      id: string;
      internalNote?: string;
    }) => (
      <ObjectCell
        imageUrl={images[0]?.url}
        pathnameToOpen={`/objects/${id}`}
        title={internalNote}
      />
    ),
  },
  {
    header: 'Address',
    accessors: ['location'],
    Cell: ({ address }: { address: string }) => (
      <Text>
        {address
          .split(', ')
          .slice(0, 2)
          .map((line: string, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
      </Text>
    ),
  },
  {
    header: 'Landlord',
    accessors: ['landlord'],
    Cell: ({ id, user, company }: Landlord) => (
      <>
        <Flex fontWeight={'bold'}>
          {user ? (
            <RouterLink to={`/users/${user.id}`}>
              {user.email}
              <Icon ml={1} as={FiExternalLink} />
            </RouterLink>
          ) : (
            <Text>{company?.name}</Text>
          )}
          <Badge fontSize={'xs'} ml={2} colorScheme={'blue'}>
            {user ? 'User' : 'Company'}
          </Badge>
        </Flex>
        <Box mt={2}>
          <ClipboardButton title={id} valueToCopy={id} colorScheme={'gray'} />
        </Box>
      </>
    ),
  },
  {
    header: 'Status',
    accessors: ['active', 'verified'],
    Cell: ({ active, verified }: { active: boolean; verified: boolean }) => (
      <Badge colorScheme={active && verified ? 'green' : 'red'}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {verified ? (active ? 'Active' : 'Disabled') : 'Not Verified'}
      </Badge>
    ),
  },
  {
    header: 'Created At',
    accessors: ['createdAt'],
    Cell: (data: string) => (
      <Tooltip hasArrow={true} label={moment(data).format('LLL')}>
        <Text width={'max-content'}>{moment(data).format('L')}</Text>
      </Tooltip>
    ),
  },
];
