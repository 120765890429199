import { FilterTypeEnum, IFilters } from '../../../types/types';

export const swipeFilters: IFilters<'positive' | 'matched'> = [
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Swipe',
    accessor: 'positive',
    options: [
      { label: 'All', value: null },
      { label: 'Positive', value: 'true' },
      { label: 'Negative', value: 'false' },
    ],
  },
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Matched',
    accessor: 'matched',
    options: [
      { label: 'All', value: null },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ],
  },
];
