import { ApolloError } from '@apollo/client';
import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from '@chakra-ui/react';
import { UserCell } from '../cell/UserCell';

interface MatchAlertDialogProps {
  makeMatch: () => void;
  open: boolean;
  onClose: () => void;
  matchWith?: any;
  loading: boolean;
  error?: ApolloError;
}

export const MatchAlertDialog: React.FC<MatchAlertDialogProps> = (props) => {
  const { open, makeMatch, onClose, matchWith, error, loading } = props;

  const cancelRef = React.useRef(null);

  return (
    <AlertDialog isOpen={open} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize={'lg'} fontWeight={'bold'}>
            Create Match
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text mb={4}>
              Do you really want to create a match{matchWith ? ' with:' : '?'}
            </Text>
            {matchWith && (
              <UserCell
                image={matchWith.pictures[0]?.url}
                firstName={matchWith.firstName}
                lastName={matchWith.lastName}
                email={matchWith.email}
              />
            )}
            {error && (
              <Text color={'red'}>Something went wrong. Could not create a match</Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button size={'xs'} onClick={onClose} ref={cancelRef}>
              Cancel
            </Button>
            <Button
              isLoading={loading}
              size={'xs'}
              colorScheme={'green'}
              onClick={makeMatch}
              ml={3}>
              Match
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
