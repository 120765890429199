import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FiRefreshCcw } from 'react-icons/all';
import { useApolloClient } from '@apollo/client';

interface RefetchButtonProps {
  size?: 'sm' | 'lg' | 'md' | 'xs';
  variant?: 'ghost' | 'solid';
  isRound?: boolean;
  colorScheme?: 'gray' | 'blue';
}

export const RefetchButton: React.FC<RefetchButtonProps> = (props) => {
  const client = useApolloClient();
  const {
    size = 'sm',
    isRound = true,
    variant = 'ghost',
    colorScheme = 'gray',
  } = props;

  return (
    <IconButton
      size={size}
      variant={variant}
      colorScheme={colorScheme}
      isRound={isRound}
      aria-label={'Refetch Active Queries'}
      icon={<FiRefreshCcw />}
      onClick={() => client.refetchQueries({ include: 'active' })}
    />
  );
};
