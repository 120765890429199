import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../util/hooks/useQuery';
import { ChatQuery, useChatQuery } from '../../graphql/types-and-hooks';
import { AvatarGroup } from '../avatar/AvatarGroup';
import { ReadCheckIcon } from './ReadCheck';
import State from '../loading/State';
import { IApolloState } from '../../types/types';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { useIntersection } from '../../util/hooks/useIntersection';

const ActiveChatCellSkeleton: React.FC<{
  padding?: number | string;
}> = ({ padding = 2 }) => (
  <Box p={padding} height={'min-content'} borderRadius={12}>
    <Flex justify={'space-between'}>
      <SkeletonCircle size={'24px'} />
      <SkeletonText mt={'2'} noOfLines={1} w={'80%'} spacing={'2'} />
    </Flex>
    <SkeletonText mt={'2'} noOfLines={1} spacing={'2'} />
  </Box>
);

interface ActiveChatCellProps {
  chatId: string;
  openCallback?: () => void;
  selected: boolean;
}

const ActiveChatCell = (props: ActiveChatCellProps) => {
  const { chatId, openCallback, selected } = props;

  const [firstRender, setFirstRender] = useState<boolean>(true);

  const cellRef = useRef<HTMLDivElement>(null);

  const [, hasBeenVisibleOnce] = useIntersection(cellRef, '0px');

  const [{ loading }, chat] = useQueryWrapper({
    query: useChatQuery,
    options: { variables: { id: chatId }, skip: !hasBeenVisibleOnce },
    parser: useCallback<(data: ChatQuery) => ChatQuery['chat']>(
      (data) => data.chat,
      []
    ),
  });

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (firstRender && selected) {
      interval = setInterval(() => {
        if (cellRef.current) {
          cellRef.current.scrollIntoView();
          clearInterval(interval);
          setFirstRender(false);
        }
      }, 50);
    }

    return () => clearInterval(interval);
  }, [selected, firstRender]);

  const history = useHistory();

  const lastMessage = chat?.lastMessage;

  // TODO: Doesn't work since "id" doesn't point to sub such as senderId is doing
  const senderOfLastMessage = chat?.participants.filter(
    (participant) => participant.id === lastMessage?.senderId
  )[0];

  const newMessages = [];

  return (
    <Flex
      ref={cellRef}
      bg={selected ? mode('gray.200', 'gray.800') : ''}
      h={'min-content'}
      onClick={() => {
        history.push(`/chats?chatId=${chat?.id}`);
        if (openCallback) {
          openCallback();
        }
      }}
      cursor={'pointer'}
      _hover={{ bg: selected ? '' : mode('gray.100', 'gray.600') }}
      p={2}
      borderRadius={12}
      justify={'space-between'}
      direction={'column'}>
      {chat && !loading && (
        <>
          <Flex align={'center'}>
            <AvatarGroup
              participants={chat.participants.filter((participant) => !participant.me)}
              size={'xs'}
            />
            <Tooltip
              colorScheme={'blue'}
              placement={'auto'}
              hasArrow
              label={
                <Text fontSize={'12'}>
                  {chat.participants
                    .filter((participant) => !participant.me)
                    .map((participant) => participant.name.text)
                    .join(', ')}
                </Text>
              }>
              <Text
                ml={2}
                fontSize={'12'}
                color={mode('', 'gray.200')}
                isTruncated={true}>
                {chat.participants
                  .filter((participant) => !participant.me)
                  .map((participant) => participant.name.text)
                  .join(', ')}
              </Text>
            </Tooltip>
            {lastMessage && (
              <Text
                ml={'auto'}
                whiteSpace={'nowrap'}
                fontSize={'11'}
                color={mode('', 'gray.200')}>
                {moment(lastMessage.sentAt).calendar()}
              </Text>
            )}
          </Flex>
          <Flex align={'center'} justify={'space-between'}>
            {lastMessage && (
              <Flex minW={0} flex={1} align={'center'}>
                {senderOfLastMessage?.me && (
                  <ReadCheckIcon
                    participants={chat.participants}
                    readBy={lastMessage.readBy}
                  />
                )}
                <Text
                  ml={1}
                  fontSize={'12'}
                  color={mode('gray.500', 'gray.400')}
                  isTruncated={true}>
                  {!senderOfLastMessage?.me && chat.participants.length > 2
                    ? `${senderOfLastMessage?.name.text}: `
                    : ''}
                  {lastMessage.simpleMessage}
                </Text>
              </Flex>
            )}
            {newMessages.length > 0 ? (
              <Tag
                ml={1}
                size={'sm'}
                borderRadius={10}
                colorScheme={'red'}
                align={'center'}>
                {newMessages.length}
              </Tag>
            ) : null}
          </Flex>
        </>
      )}
      {!chat && <ActiveChatCellSkeleton padding={0} />}
    </Flex>
  );
};

interface ActiveChatsProps {
  chats?: Map<string, { id: string }>;
  openCallback: () => void;
  apolloState: IApolloState;
}

const ActiveChats = (props: ActiveChatsProps) => {
  const { chats, openCallback, apolloState } = props;

  const { query } = useQuery();
  const queryChatId = query.get('chatId');

  return (
    <Stack width={'100%'} height={'full'} overflowY={'auto'}>
      <State
        {...apolloState}
        customLoadingElement={[0, 1, 2].map((key) => (
          <ActiveChatCellSkeleton key={key} />
        ))}>
        {chats &&
          Array.from(chats, ([, value]) => value).map(({ id: chatId }, index) => (
            <ActiveChatCell
              selected={chatId === queryChatId}
              chatId={chatId}
              openCallback={openCallback}
              key={index}
            />
          ))}
        {!chats && (
          <Text p={2} fontSize={'sm'} textAlign={'center'}>
            Select an object first to view chats!
          </Text>
        )}
      </State>
    </Stack>
  );
};

export default ActiveChats;
