import { FilterTypeEnum, IFilters } from '../../../types/types';
import { LandlordType } from '../../../graphql/types-and-hooks';

export type TObjectsFilterAccessors = 'landlordType' | 'verified' | 'active' | 'search';

export const objectsFilters: IFilters<TObjectsFilterAccessors> = [
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Active',
    options: [
      {
        label: 'All',
        value: null,
      },
      {
        label: 'Active',
        value: 'true',
      },
      {
        label: 'Disabled',
        value: 'false',
      },
    ],
    accessor: 'active',
  },
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Landlord Type',
    options: [
      {
        label: 'All',
        value: null,
      },
      {
        label: 'Company',
        value: LandlordType.Company,
      },
      {
        label: 'User',
        value: LandlordType.User,
      },
    ],
    accessor: 'landlordType',
  },
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Verified',
    options: [
      {
        label: 'All',
        value: null,
      },
      {
        label: 'Verified',
        value: 'true',
      },
      {
        label: 'Not Verified',
        value: 'false',
      },
    ],
    accessor: 'verified',
  },
  {
    __typename: FilterTypeEnum.SEARCH,
    label: 'Search',
    accessor: 'search',
    addedByDefault: true,
    hiddenInMenu: true,
  },
];
