import { LandlordType } from '../../../graphql/types-and-hooks';
import { FilterTypeEnum, IFilters } from '../../../types/types';

export const landlordFilters: IFilters<'landlordType'> = [
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'Landlord Type',
    options: [
      {
        label: 'All',
        value: null,
      },
      {
        label: 'User',
        value: LandlordType.User,
      },
      {
        label: 'Company',
        value: LandlordType.Company,
      },
    ],
    accessor: 'landlordType',
  },
];
