import { AspectRatio, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { IFileUpload } from '../../types/types';

interface ImageToastContentProps {
  image: IFileUpload;
  content: React.ReactNode | string;
}

export const ImageToastContent = ({ image, content }: ImageToastContentProps) => (
  <Flex align={'center'} width={'full'}>
    <AspectRatio w={'50px'} ratio={1} mr={2}>
      <Image src={image.url} alt={image.file.name} objectFit={'cover'} />
    </AspectRatio>
    {typeof content === 'string' ? <Text fontSize={'sm'}>{content}</Text> : content}
  </Flex>
);
