import React from 'react';
import { Tag, Tooltip } from '@chakra-ui/react';
import moment from 'moment';

interface DateTag {
  date: string;
  title?: string;
}

export const DateTag: React.FC<DateTag> = ({ date, title }) => {
  return (
    <Tooltip hasArrow={true} label={moment(date).format('LLL')}>
      <Tag width={'max-content'} fontSize={'xs'}>
        {title ? `${title}: ` : ''}
        {moment(date).format('L')}
      </Tag>
    </Tooltip>
  );
};
