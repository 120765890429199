import { Box, Button, Flex, LightMode, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { InputField } from './InputField';
import useAuth from '../../util/hooks/useAuth';

const SignInForm = () => {
  const { signIn, loading, error } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
      <Stack spacing={'8'}>
        <Text color={'red'} size={'xs'}>
          {error}
        </Text>
        <InputField
          label={'Email'}
          type={'email'}
          onChange={(e) => setUsername(e.target.value)}
        />
        <Box>
          <InputField
            label={'Password'}
            type={'password'}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                signIn(username, password);
              }
            }}
          />
        </Box>
      </Stack>
      <Flex
        spacing={'4'}
        direction={{ base: 'column-reverse', md: 'row' }}
        mt={'6'}
        align={'center'}
        justify={'space-between'}>
        <LightMode>
          <Button
            mb={{ base: '4', md: '0' }}
            w={{ base: 'full', md: 'auto' }}
            colorScheme={'blue'}
            size={'md'}
            fontSize={'sm'}
            fontWeight={'bold'}
            onClick={() => signIn(username, password)}
            isLoading={loading}>
            Sign in
          </Button>
        </LightMode>
      </Flex>
    </>
  );
};

export default SignInForm;
