import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  useColorModeValue as mode,
  WrapItem,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/all';
import { FilterTypeEnum, IFilterValue, IStatefulFilters } from '../../types/types';
import { filterCallback } from '../../util/filter-callback';

interface FiltersProps<A extends string, V extends IFilterValue> {
  filters: IStatefulFilters<A, V>;
  filterAccessorsInUse: string[];
}

export const Filters = <A extends string, V extends IFilterValue>({
  filters,
  filterAccessorsInUse,
}: FiltersProps<A, V>) => {
  return (
    <>
      {filters
        .filter(({ accessor }) => filterAccessorsInUse.includes(accessor))
        .map(
          (filter) =>
            filter.__typename === FilterTypeEnum.STATEFUL_SEARCH && (
              <WrapItem key={filter.accessor}>
                <FormControl
                  mr={2}
                  minW={'200px'}
                  maxW={'300px'}
                  w={''}
                  id={'search'}
                  key={filter.accessor}>
                  <InputGroup size={'sm'}>
                    <FormLabel srOnly>{filter?.label ?? 'Search'}</FormLabel>
                    <InputLeftElement pointerEvents={'none'} color={'gray.400'}>
                      <FiSearch />
                    </InputLeftElement>
                    <Input
                      disabled={!!filter.disabled}
                      onChange={(e) =>
                        filterCallback(e, filter.updateValue, filter.accessor, {
                          instantFeedback: false,
                        })
                      }
                      rounded={'base'}
                      type={'search'}
                      placeholder={'Search...'}
                      defaultValue={filter.value ?? ''}
                    />
                  </InputGroup>
                </FormControl>
              </WrapItem>
            )
        )}
      {filters
        .filter(({ accessor }) => filterAccessorsInUse.includes(accessor))
        .map(
          (filter) =>
            filter.__typename === FilterTypeEnum.STATEFUL_NUMBER && (
              <WrapItem key={filter.accessor}>
                <FormControl
                  mr={2}
                  minW={'200px'}
                  maxW={'300px'}
                  w={''}
                  id={filter.accessor}
                  key={filter.accessor}>
                  <InputGroup
                    me={'var(--chakra-space-2) !important'}
                    mt={'-0.45rem'}
                    flexDirection={'column'}
                    size={'sm'}
                    w={''}>
                    <FormLabel
                      width={'max-content'}
                      ml={2}
                      mr={2}
                      mb={'-0.45rem'}
                      fontSize={'0.6rem'}
                      zIndex={2}
                      pl={1}
                      pr={1}
                      overflow={'hidden'}
                      bg={mode('white', '#29354a')}>
                      {filter.label}
                    </FormLabel>
                    <Input
                      title={filter.label}
                      disabled={!!filter.disabled}
                      onChange={(e) =>
                        filterCallback(e, filter.updateValue, filter.accessor, {
                          instantFeedback: false,
                        })
                      }
                      rounded={'base'}
                      type={'number'}
                      placeholder={''}
                      defaultValue={filter.value ?? 0}
                    />
                  </InputGroup>
                </FormControl>
              </WrapItem>
            )
        )}
      {filters
        .filter(({ accessor }) => filterAccessorsInUse.includes(accessor))
        .map(
          (filter) =>
            filter.__typename === FilterTypeEnum.STATEFUL_SELECT && (
              <WrapItem key={filter.accessor}>
                <InputGroup
                  me={'var(--chakra-space-2) !important'}
                  mt={'-0.45rem'}
                  flexDirection={'column'}
                  w={''}>
                  {filter?.label && (
                    <FormLabel
                      width={'max-content'}
                      ml={2}
                      mr={2}
                      mb={'-0.45rem'}
                      fontSize={'0.6rem'}
                      zIndex={1}
                      pl={1}
                      pr={1}
                      overflow={'hidden'}
                      bg={mode('white', '#29354a')}>
                      {filter.label}
                    </FormLabel>
                  )}
                  <Select
                    disabled={!!filter.disabled}
                    onChange={(e) =>
                      filterCallback(e, filter.updateValue, filter.accessor)
                    }
                    minW={'fit-content'}
                    w={'full'}
                    maxW={{ base: '150px', md: '170px' }}
                    rounded={'base'}
                    size={'sm'}
                    value={filter.value ?? ''}>
                    {'options' in filter &&
                      filter.options.map(({ value, label }, optionIndex) => (
                        <option
                          key={value ?? `null-${optionIndex}`}
                          value={value ?? ''}
                          label={label}
                        />
                      ))}
                  </Select>
                </InputGroup>
              </WrapItem>
            )
        ) ?? null}
    </>
  );
};
