import { IDict, PropertyType } from '../../types/types';
import {
  ObjectProperty,
  ObjectPropertyValue,
  Tag,
} from '../../graphql/types-and-hooks';

export const parseValueToString = (value: any, type: string) => {
  switch (type) {
    case PropertyType.tags:
      return value;
    case PropertyType.bool:
    case PropertyType.number:
      return value.toString();
    default:
      return value;
  }
};

export const parseValueToType = (value: any, type: string) => {
  switch (type) {
    case PropertyType.tags:
      return value.map((tag: Tag) => tag.id);
    case PropertyType.number:
      return parseFloat(value);
    case PropertyType.bool:
      return value === 'true';
    default:
      return value;
  }
};

export interface ParsedObjectProperty extends ObjectProperty {
  constraints?: IDict<string>;
  displayName: string;
  locale?: string;
}

export const parseProperty = (property: ObjectProperty): ParsedObjectProperty => {
  const splitName = property.name.split(/(?=[A-Z])/).join(' ');
  let parsedConstraints: IDict<string> | undefined;

  if (property.constraints) {
    parsedConstraints = JSON.parse(property.constraints);
  }
  return { ...property, constraints: parsedConstraints, displayName: splitName };
};

export interface ParsedObjectPropertyValue extends ObjectPropertyValue {
  property: ParsedObjectProperty;
  value: [] | string;
}

export const parsePropertiesOfObject = (
  properties: ObjectPropertyValue[]
): ParsedObjectPropertyValue[] =>
  properties.map((objectProperty) => {
    return {
      ...objectProperty,
      property: parseProperty(objectProperty.property),
      value: JSON.parse(objectProperty.value),
    };
  });
