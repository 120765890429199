import axios from 'axios';
import { IFileUpload } from '../types/types';

export const uploadImageToS3 = (
  uploadURL: string,
  image: IFileUpload,
  progressCallback: (progress: number) => void
) => {
  return axios.put(uploadURL, image.file, {
    cancelToken: image.cancelSource.token,
    onUploadProgress: (progressEvent) =>
      progressCallback(progressEvent.loaded / progressEvent.total),
  });
};
