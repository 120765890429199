import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import {
  LandlordType,
  ObjectCreationMetaDataQuery,
  useCreateBaseObjectMutation,
  useObjectCreationMetaDataQuery,
} from '../../../graphql/types-and-hooks';
import { useQueryWrapper } from '../../../util/hooks/useQueryWrapper';
import { Form, FormWrapper } from '../../../components/form/Form';
import { baseObjectFormMapping } from '../../../data/valueMapping';
import { createObjectValidator } from '../../../util/form-validators/new-object';
import { Modal } from '../../../components/modal/Modal';
import { OpposingOption } from '../../../components/form/OpposingOption';
import State from '../../../components/loading/State';

export const CreateObjectFormModal = ({
  title,
  addObjectModalDisclosure,
}: {
  title: string;
  addObjectModalDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
  };
}) => {
  const [landlordType, setLandlordType] = useState(LandlordType.Company);
  const history = useHistory();

  const [{ loading: loadingMetaData }, objectCreationMetaDataParsedData] =
    useQueryWrapper({
      query: useObjectCreationMetaDataQuery,
      options: { variables: { landlordType } },
      parser: useCallback<
        (data: ObjectCreationMetaDataQuery) => {
          objectTypes: { value: string; title: string }[];
          landlords: { value: string; title: string }[];
        }
      >(
        ({ objectTypes, landlords }) => ({
          landlords: landlords.map((landlord) => ({
            value: landlord.id,
            title: landlord.company?.name ?? landlord.user?.email,
          })),
          objectTypes: objectTypes.map((type) => ({
            value: type.id,
            title: type.displayName.text,
          })),
        }),
        []
      ),
    });

  const [initialCreateObjectData, setInitialCreateObjectData] = useState<{
    internalNote: string;
    type: { options: { title: string; value: string }[]; value: string };
    landlord: { options: { title: string; value: string }[]; value: string };
    placeId: string;
  }>({
    internalNote: '',
    type: { options: [], value: '' },
    landlord: { options: [], value: '' },
    placeId: '',
  });

  React.useEffect(() => {
    setInitialCreateObjectData((oldState) => {
      if (objectCreationMetaDataParsedData) {
        return {
          ...oldState,
          type: {
            options: [...objectCreationMetaDataParsedData.objectTypes],
            value: '',
          },
          landlord: {
            options: objectCreationMetaDataParsedData?.landlords ?? [],
            value: '',
          },
        };
      }
      return oldState;
    });
  }, [objectCreationMetaDataParsedData]);

  const [createBaseObjectMutation, createBaseObjectMutationResult] =
    useCreateBaseObjectMutation();

  return (
    <FormWrapper
      initialData={initialCreateObjectData}
      dataMapping={baseObjectFormMapping}
      inEditMode={true}
      submitFunction={(formData) =>
        createBaseObjectMutation({
          variables: {
            internalNote: formData?.internalNote,
            landlord: formData?.landlord?.value,
            placeID: formData?.placeId,
            type: formData?.type?.value,
          },
        }).then(({ data }) => history.push(`/objects/${data?.createBaseObject}`))
      }
      validator={createObjectValidator}>
      {(props) => (
        <Modal
          title={`${title} ${
            landlordType === LandlordType.Company ? '(Company)' : '(User)'
          }`}
          primaryAction={{
            title: 'Create',
            callback: () => props.formik.submitForm(),
            loading: createBaseObjectMutationResult.loading,
          }}
          secondaryAction={{
            title: 'Cancel',
            callback: () => props.formik.resetForm(),
          }}
          disclosureOverwrite={addObjectModalDisclosure}>
          <OpposingOption
            leftOption={{ option: LandlordType.Company, title: 'Company' }}
            rightOption={{ option: LandlordType.User, title: 'User' }}
            changeCallback={(newOption) => setLandlordType(newOption)}
            initial={LandlordType.Company}
          />
          <Box mt={4} height={'300px'}>
            <State loading={loadingMetaData}>
              <Form {...props} />
            </State>
          </Box>
        </Modal>
      )}
    </FormWrapper>
  );
};
