import * as React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import { css, Global } from '@emotion/react';
import moment from 'moment';
import 'moment/locale/en-gb';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import Amplify, { Auth } from 'aws-amplify';
import {
  createHttpLink,
  ApolloLink,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { AuthOptions } from 'aws-appsync-auth-link/lib/auth-link';
import Routing from './components/routing/Routing';
import awsconfig from './aws-amplify-config';
import 'react-toastify/dist/ReactToastify.css';
import { useOnlineStatus } from './util/hooks/useOnlineStatus';
import { mutationToastLink, errorLink, retryLink } from './util/apollo/links';

Amplify.configure(awsconfig);

const url = awsconfig.aws_appsync_graphqlEndpoint;
const region = awsconfig.aws_appsync_region;
const auth: AuthOptions = {
  type: awsconfig.aws_appsync_authenticationType,
  jwtToken: async () => {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  },
};

const httpLink = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url }),
]);

const link = createSubscriptionHandshakeLink({ url, region, auth }, httpLink);

const client = new ApolloClient({
  link: from([mutationToastLink, errorLink, retryLink, link]),
  cache: new InMemoryCache(),
});

moment.locale('en-gb', {
  calendar: {
    lastDay: '[Yesterday] LT',
    sameDay: 'LT',
    nextDay: '[Tomorrow] LT',
    lastWeek: 'dddd',
    nextWeek: '[Next] dddd',
    sameElse: 'DD/MM/YYYY',
  },
});

export const App = () => {
  useOnlineStatus({
    client,
  });

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <Global
          // eslint-disable-next-line max-len
          // https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9
          styles={css`
            .js-focus-visible :focus:not([data-focus-visible-added]) {
              outline: none;
              box-shadow: none;
            }
          `}
        />
        <Routing />
      </ChakraProvider>
    </ApolloProvider>
  );
};
