import fileDownload from 'js-file-download';
import axios from 'axios';
import { toast } from 'react-toastify';

const generateFilename = (filename: string) =>
  filename.toLowerCase().trim().replaceAll(' ', '_');

const downloadFile = (url: string, filename: string) =>
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((response) => response.data)
    .then((blob) => {
      fileDownload(blob, generateFilename(filename), blob.type);
    })
    .catch((error) => {
      toast.error('Error downloading file!');
      throw error;
    });

export default downloadFile;
