import React from 'react';
import { Badge, Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { FiExternalLink, FiUserPlus } from 'react-icons/all';
import { Link } from 'react-router-dom';
import { ColumnProps } from '../../../components/table/TableContent';
import { CompanyLandlord, UserLandlord } from '../../../graphql/types-and-hooks';
import { ClipboardButton } from '../../../components/buttons/ClipboardButton';

export const landlordColumns: Array<ColumnProps> = [
  {
    header: 'Landlord',
    accessors: ['id', 'user', 'company'],
    Cell: ({ id, user, company }: any) => (
      <>
        <Box fontWeight={'bold'}>
          {user ? (
            <Flex>
              <Link to={`/users/${user.id}`}>
                {user.email}
                <Icon ml={1} as={FiExternalLink} />
              </Link>
            </Flex>
          ) : (
            <Text>{company?.name}</Text>
          )}
        </Box>
        <Box mt={2}>
          <ClipboardButton title={id} valueToCopy={id} colorScheme={'gray'} />
        </Box>
      </>
    ),
  },
  {
    header: 'Type',
    accessors: ['user'],
    Cell: (user?: UserLandlord) => (
      <Badge colorScheme={'blue'}>{user ? 'User' : 'Company'}</Badge>
    ),
  },
  {
    header: 'actions',
    accessors: ['company'],
    Cell: (company?: CompanyLandlord) => {
      if (company) {
        const { id } = company;
        return (
          <Link to={`/companies/${id}`}>
            <Button size={'sm'} leftIcon={<FiUserPlus />}>
              Manage Users
            </Button>
          </Link>
        );
      }
      return null;
    },
  },
];
