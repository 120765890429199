import { Box, Button, Flex, Stack, useColorModeValue as mode } from '@chakra-ui/react';
import React from 'react';
import {
  Book,
  HelpCircle,
  Home as HomeIcon,
  MessageSquare,
  Settings,
  Users,
} from 'react-feather';
import { matchPath, useLocation } from 'react-router-dom';
import { MobileMenuButton } from '../buttons/MobileMenuButton';
import { NavBreadcrumb } from './NavBreadcrumb';
import { useMobileMenuState } from '../../util/hooks/useMobileMenuState';
import { NavItem } from './NavItem';
import { NavGroup } from './NavGroup';
import useAuth from '../../util/hooks/useAuth';
import { breakpoints } from '../../data/breakpoints';
import { UserCell } from '../cell/UserCell';

const SidebarLayout: React.FC = ({ children }) => {
  const { isOpen, toggle } = useMobileMenuState();

  const { userAttributes, signOut } = useAuth();

  const location = useLocation();

  return (
    <Flex
      height={'100vh'}
      bg={mode('blue.800', 'gray.800')}
      overflow={'hidden'}
      sx={{
        '--sidebar-width': '16rem',
        '--content-p-inner-base': '1rem',
        '--content-p-inner-md': '2.5rem',
        '--content-p-outer-base': '0.1rem',
        '--content-p-outer-md': '0.5rem',
      }}>
      <Box
        as={'nav'}
        display={'block'}
        flex={'1'}
        width={'var(--sidebar-width)'}
        left={'0'}
        py={'5'}
        px={'3'}
        color={'gray.200'}
        position={'fixed'}
        height={'100%'}>
        <Box fontSize={'sm'} lineHeight={'tall'} height={'full'}>
          <Flex h={'full'} direction={'column'}>
            <Box
              as={'a'}
              href={'#'}
              p={'3'}
              display={'block'}
              transition={'background 0.1s'}
              rounded={'xl'}
              _hover={{ bg: 'whiteAlpha.200' }}
              whiteSpace={'nowrap'}>
              {userAttributes ? (
                <UserCell
                  firstName={userAttributes.given_name}
                  lastName={userAttributes.family_name}
                  email={userAttributes.email}
                  image={''}
                />
              ) : null}
            </Box>

            <Stack spacing={'8'} flex={'1'} overflow={'auto'} pt={'8'}>
              <Stack spacing={'1'}>
                <NavItem
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/'],
                      exact: true,
                    })
                  }
                  icon={<HomeIcon size={16} />}
                  label={'Home'}
                  href={'/'}
                />
                <NavItem
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/chats'],
                      exact: true,
                    })
                  }
                  icon={<MessageSquare size={16} />}
                  href={'/chats'}
                  label={'Chats'}
                />
              </Stack>
              <NavGroup label={'CRM'}>
                <NavItem
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/users', '/users/:userId'],
                      exact: true,
                    })
                  }
                  icon={<Users size={16} />}
                  label={'Users'}
                  href={'/users'}
                />
                <NavItem
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/objects', '/objects/:objectId'],
                      exact: true,
                    })
                  }
                  icon={<Book size={16} />}
                  label={'Objects'}
                  href={'/objects'}
                />
                <NavItem
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/landlords'],
                      exact: true,
                    })
                  }
                  icon={<HomeIcon size={16} />}
                  label={'Landlords'}
                  href={'/landlords'}
                />
              </NavGroup>
            </Stack>

            <Box>
              <Stack spacing={'1'}>
                <NavItem
                  subtle
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/settings'],
                      exact: true,
                    })
                  }
                  icon={<Settings size={16} />}
                  label={'Settings'}
                  href={'/settings'}
                />
                <NavItem
                  subtle
                  active={
                    !!matchPath(location.pathname, {
                      path: ['/support'],
                      exact: true,
                    })
                  }
                  icon={<HelpCircle size={16} />}
                  label={'Help & Support'}
                  href={'/support'}
                />
                <Button size={'xs'} color={'black'} onClick={() => signOut()}>
                  Sign Out
                </Button>
              </Stack>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box
        h={'full'}
        w={{
          base: '100%',
          [breakpoints.main]: 'calc(100% - var(--sidebar-width))',
        }}
        flex={'1'}
        p={{
          base: 'var(--content-p-outer-base)',
          [breakpoints.main]: 'var(--content-p-outer-md)',
        }}
        marginStart={{ [breakpoints.main]: 'var(--sidebar-width)' }}
        position={'relative'}
        left={isOpen ? 'var(--sidebar-width)' : '0'}
        transition={'left 0.2s'}>
        <Box
          maxW={'2560px'}
          bg={mode('white', '#29354a')}
          height={'100%'}
          pb={'6'}
          rounded={{ [breakpoints.main]: 'lg' }}>
          <Flex direction={'column'} height={'full'}>
            <Flex
              w={'full'}
              py={'4'}
              justify={'space-between'}
              align={'center'}
              px={{
                base: 'var(--content-p-inner-base)',
                [breakpoints.main]: 'var(--content-p-inner-md)',
              }}>
              <Flex align={'center'} minH={'8'}>
                <MobileMenuButton onClick={toggle} isOpen={isOpen} />
                <NavBreadcrumb location={location} />
              </Flex>
            </Flex>
            <Flex
              direction={'column'}
              flex={'1'}
              overflow={'auto'}
              px={{
                base: 'var(--content-p-inner-base)',
                [breakpoints.main]: 'var(--content-p-inner-md)',
              }}>
              <Box flex={'1'} height={'full'}>
                {children}
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default SidebarLayout;
