import React from 'react';
import { Button, useClipboard } from '@chakra-ui/react';
import { FiCheck, FiClipboard } from 'react-icons/all';
import { toast } from 'react-toastify';

interface ClipboardButtonProps {
  title: string;
  valueToCopy: string;
  colorScheme?: string;
  onCopyCallback?: () => void;
}

export const ClipboardButton: React.FC<ClipboardButtonProps> = (props) => {
  const { title, valueToCopy, colorScheme, onCopyCallback } = props;

  const { hasCopied, onCopy } = useClipboard(valueToCopy);

  const onCopyHandler = () => {
    onCopy();
    onCopyCallback?.();
    toast('Copied to clipboard');
  };

  return (
    <Button
      onClick={onCopyHandler}
      colorScheme={colorScheme ?? 'blue'}
      size={'xs'}
      rightIcon={hasCopied ? <FiCheck /> : <FiClipboard />}>
      {title}
    </Button>
  );
};
