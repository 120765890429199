import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { IDict } from '../../types/types';

export const useQuery = () => {
  const history = useHistory();

  const query = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location]
  );

  const removeQuery = useCallback(
    (key: string) => {
      const currentParams = new URLSearchParams(history.location.search);
      currentParams.delete(key);
      history.replace({
        pathname: history.location.pathname,
        search: currentParams.toString(),
      });
    },
    [history]
  );

  const addQuery = useCallback(
    (key: string, value: string) => {
      const currentParams = new URLSearchParams(history.location.search);
      currentParams.set(key, value);
      history.replace({
        pathname: history.location.pathname,
        search: currentParams.toString(),
      });
    },
    [history]
  );

  const updateQuery = useCallback(
    (queryObject: IDict<any>) => {
      const newParams = new URLSearchParams(queryObject);
      const currentParams = new URLSearchParams(history.location.search);
      const mergedParams = new URLSearchParams({
        ...Object.fromEntries(currentParams),
        ...Object.fromEntries(newParams),
      });

      history.replace({
        pathname: history.location.pathname,
        search: mergedParams.toString(),
      });

      return mergedParams;
    },
    [history]
  );

  return {
    query,
    updateQuery,
    removeQuery,
    addQuery,
  };
};
