import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import {
  ObjectSwipesDocumentNode,
  usePutTenantSwipeMutation,
} from '../../graphql/types-and-hooks';
import { MatchAlertDialog } from './MatchAlertDialog';

interface MatchButtonProps {
  objectId: string;
  userId: string;
  matchWith?: any;
  alert?: boolean;
  CustomMatchElement?: any;
}

export const MatchButton: React.FC<MatchButtonProps> = (props) => {
  const { CustomMatchElement, objectId, userId, alert, matchWith } = props;

  const [open, setOpen] = useState<boolean>(false);

  const [putTenantSwipe, { loading, error }] = usePutTenantSwipeMutation();

  const putTenantSwipeHandler = () => {
    putTenantSwipe({
      variables: { userId, objectId },
      refetchQueries: [ObjectSwipesDocumentNode],
    }).then(() => {
      if (alert) {
        setOpen(false);
      }
    });
  };

  const matchHandler = () => {
    if (alert) {
      setOpen(true);
    } else {
      putTenantSwipeHandler();
    }
  };

  return (
    <>
      {CustomMatchElement ? (
        <CustomMatchElement />
      ) : (
        <Button
          isLoading={loading}
          size={'xs'}
          colorScheme={'green'}
          onClick={matchHandler}>
          Match!
        </Button>
      )}
      {alert && (
        <MatchAlertDialog
          loading={loading}
          error={error}
          matchWith={matchWith}
          open={open}
          makeMatch={() => {
            putTenantSwipeHandler();
          }}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};
