import React from 'react';
import { chakra, useColorModeValue as mode } from '@chakra-ui/react';
import whiteLogo from './assets/white.svg';
import blackLogo from './assets/black.svg';

interface LogoProps {
  height?: string;
  width?: string;
}

const Logo = (props: LogoProps) => {
  const { height = '50', width = 'auto' } = props;

  return (
    <chakra.img
      height={`${height}px`}
      width={`${width}px`}
      src={mode(blackLogo, whiteLogo)}
    />
  );
};

export default Logo;
