import React from 'react';
import {
  Flex,
  Heading,
  Icon,
  IconButton,
  Progress,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FiCheck, FiRefreshCcw, FiX } from 'react-icons/all';
import { ImageToastContent } from './ImageToastContent';
import { FileUploadStatus } from '../../types/types';
import { useUploadContext } from '../../util/contexts/UploadContext';

export const ImageUploadToastContent = (): JSX.Element => {
  const { files, dispatch, uploadGroups } = useUploadContext();

  return (
    <Stack>
      {uploadGroups.map(
        (uploadGroup) =>
          files.some((file) => file.uploadGroupId === uploadGroup.id) && (
            <Stack key={uploadGroup.id} align={'start'}>
              <Heading size={'sm'}>{uploadGroup.title}</Heading>
              {files
                .filter((file) => file.uploadGroupId === uploadGroup.id)
                .map((file) => (
                  <ImageToastContent
                    key={file.id}
                    image={file}
                    content={
                      <Stack width={'full'}>
                        <Stack
                          direction={'row'}
                          justify={'space-between'}
                          minH={'24px'}>
                          <Text
                            fontSize={'sm'}
                            _hover={{ '--chakra-line-clamp': '3' }}
                            noOfLines={1}>
                            {file.file.name}
                          </Text>
                          {file.uploadStatus === FileUploadStatus.FAILED && (
                            <IconButton
                              size={'xs'}
                              aria-label={'Retry'}
                              variant={'ghost'}
                              icon={<FiRefreshCcw />}
                              onClick={() =>
                                dispatch({ type: 'retryUpload', id: file.id })
                              }
                            />
                          )}
                          {file.uploadStatus === FileUploadStatus.UPLOADING && (
                            <IconButton
                              size={'xs'}
                              aria-label={'Retry'}
                              variant={'ghost'}
                              color={'red'}
                              icon={<FiX />}
                              onClick={() =>
                                file.cancelSource.cancel('cancelled by user')
                              }
                            />
                          )}
                          {file.uploadStatus === FileUploadStatus.SUCCESS && (
                            <Flex
                              h={'24px'}
                              w={'24px'}
                              align={'center'}
                              justify={'center'}>
                              <Icon color={'green'} as={FiCheck} />
                            </Flex>
                          )}
                          {file.uploadStatus === FileUploadStatus.NOT_STARTED && (
                            <IconButton
                              size={'xs'}
                              aria-label={'Fail Upload'}
                              variant={'ghost'}
                              color={'red'}
                              icon={<FiX />}
                              onClick={() => dispatch({ type: 'failed', id: file.id })}
                            />
                          )}
                        </Stack>
                        <Progress
                          size={'xs'}
                          min={0}
                          max={1}
                          value={
                            file.uploadStatus === FileUploadStatus.FAILED
                              ? 1
                              : file.progress
                          }
                          colorScheme={(() => {
                            switch (file.uploadStatus) {
                              case FileUploadStatus.FAILED:
                                return 'red';
                              case FileUploadStatus.SUCCESS:
                                return 'green';
                              default:
                                return 'blue';
                            }
                          })()}
                          isIndeterminate={
                            file.uploadStatus === FileUploadStatus.NOT_STARTED
                          }
                        />
                      </Stack>
                    }
                  />
                ))}
            </Stack>
          )
      )}
    </Stack>
  );
};
