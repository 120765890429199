import {
  Box,
  Button,
  Flex,
  Heading,
  LightMode,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../components/logo/Logo';
import SignInForm from '../components/signin/SignInForm';
import useAuth from '../util/hooks/useAuth';
import { breakpoints } from '../data/breakpoints';
import NewPasswordRequiredForm from '../components/signin/NewPasswordRequiredForm';

const AuthView = () => {
  const { user, signOut, firstLogin } = useAuth();
  const history = useHistory();

  return (
    <Flex
      minH={'100vh'}
      justifyContent={'center'}
      align={'center'}
      bg={{ [breakpoints.main]: mode('gray.50', 'gray.800') }}>
      <Box maxW={'xl'} flex={'1'} p={{ base: '10' }}>
        <Box>
          <Box mb={'6'}>
            <Logo height={'50'} />
          </Box>
          <Heading
            color={mode('blue.600', 'blue.400')}
            as={'h1'}
            size={'lg'}
            fontWeight={'extrabold'}
            letterSpacing={'tight'}>
            Welcome back
          </Heading>
          <Text
            mt={'3'}
            fontSize={{ base: 'lg' }}
            fontWeight={'bold'}
            color={mode('gray.500', 'inherit')}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {user
              ? firstLogin
                ? 'You need to set a new password'
                : 'You are already signed in'
              : 'Sign in to continue'}
          </Text>
        </Box>

        <Box
          minW={{ [breakpoints.main]: '420px' }}
          mt={'10'}
          rounded={'xl'}
          bg={{ [breakpoints.main]: mode('white', 'gray.700') }}
          shadow={{ [breakpoints.main]: 'lg' }}
          px={{ [breakpoints.main]: '10' }}
          pt={{ base: '5', [breakpoints.main]: '8' }}
          pb={'8'}>
          {user && !firstLogin && (
            <Flex
              spacing={'4'}
              direction={{ base: 'column', [breakpoints.main]: 'column' }}
              align={'center'}
              justify={'space-evenly'}>
              <LightMode>
                <Button
                  w={{ base: 'full', [breakpoints.main]: 'full' }}
                  colorScheme={'blue'}
                  size={'sm'}
                  fontSize={'sm'}
                  onClick={() => history.push('/')}>
                  Access Platform
                </Button>
                <Button
                  w={{ base: 'full', [breakpoints.main]: 'full' }}
                  variant={'ghost'}
                  colorScheme={'blue'}
                  size={'sm'}
                  fontSize={'sm'}
                  onClick={() => signOut()}>
                  Sign out
                </Button>
              </LightMode>
            </Flex>
          )}
          {user && firstLogin && <NewPasswordRequiredForm />}
          {!user && <SignInForm />}
        </Box>
      </Box>
    </Flex>
  );
};

export default AuthView;
