import React, { useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/all';
import {
  LandlordType,
  ObjectsQuery,
  useObjectsQuery,
} from '../../graphql/types-and-hooks';
import { IDataCount, ITableActions } from '../../types/types';
import Table from '../../components/table/Table';
import { TableContent } from '../../components/table/TableContent';
import { usePagination } from '../../util/hooks/usePagination';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { TableActions } from '../../components/table/TableActions';
import { TablePagination } from '../../components/table/TablePagination';
import { stringToBoolean } from '../../util/string-to-boolean';
import { useFilter } from '../../util/hooks/useFilter';
import { objectsFilters, TObjectsFilterAccessors } from './data/filters';
import { objectColumns } from './data/columns';
import { CreateObjectFormModal } from './components/CreateObject';

const Objects = () => {
  const { page, take } = usePagination();

  const addObjectModalDisclosure = useDisclosure();

  const { filterValues, statefulFilters } = useFilter<
    TObjectsFilterAccessors,
    'true' | 'false' | LandlordType | string | null
  >({
    filters: objectsFilters,
  });

  const [objectsQueryResult, objectsParsedData] = useQueryWrapper({
    query: useObjectsQuery,
    options: {
      variables: {
        take,
        page: page - 1,
        filter: {
          search: filterValues.search,
          active: stringToBoolean(filterValues.active as 'true' | 'false' | null),
          verified: stringToBoolean(filterValues.verified as 'true' | 'false' | null),
          landlordType: filterValues.landlordType as LandlordType,
        },
      },
    },
    parser: useCallback<
      (data: ObjectsQuery) => { objectsCount: IDataCount; objects: Map<string, any>[] }
    >((data) => {
      const { data: objects, count } = data.objects;
      return {
        objects: objects.map((object) => new Map(Object.entries(object))),
        objectsCount: { ...count },
      };
    }, []),
  });

  const actions: ITableActions = [
    {
      title: 'New Object',
      icon: <FiPlus />,
      onClick: () => addObjectModalDisclosure.onOpen(),
    },
  ];

  return (
    <>
      {addObjectModalDisclosure && (
        <CreateObjectFormModal
          title={'Create New Object'}
          addObjectModalDisclosure={addObjectModalDisclosure}
        />
      )}
      <Table
        tableName={'Objects'}
        apolloState={{
          loading: objectsQueryResult.loading,
          error: objectsQueryResult.error,
        }}>
        {({ apolloState }) => (
          <>
            <TableActions filters={statefulFilters} actions={actions} />
            <TableContent
              apolloState={apolloState}
              columns={objectColumns}
              data={objectsParsedData?.objects}
            />
            <TablePagination
              apolloState={apolloState}
              dataCount={objectsParsedData?.objectsCount}
            />
          </>
        )}
      </Table>
    </>
  );
};

export default Objects;
