/**
 * User-Related Enums and Interfaces
 */
import { ReactElement, SyntheticEvent } from 'react';
import {
  CheckboxProps,
  InputProps,
  SelectProps,
  TextareaProps,
} from '@chakra-ui/react';
import { ApolloError } from '@apollo/client';
import { Moment } from 'moment';
import { CancelTokenSource } from 'axios';
import { IImage } from '../components/image/ImageField';

export enum Locales {
  EN = 'en-US',
  DE = 'de-DE',
}

export enum Status {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  ARCHIVED = 'ARCHIVED',
  COMPROMISED = 'COMPROMISED',
  UNKNOWN = 'UNKNOWN',
  RESET_REQUIRED = 'RESET_REQUIRED',
  FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export enum IdentityProvider {
  GOOGLE = 'google',
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum UserType {
  TENANT = 'tenant',
  LANDLORD = 'landlord',
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  identityProvider?: IdentityProvider;
  locale: Locales;
}

/**
 * Dictionary general type
 */
export interface IDict<T> {
  [id: string]: T | any;
}

/**
 * Filter Interfaces
 */
export enum FilterTypeEnum {
  SELECT = 'Select',
  SEARCH = 'Search',
  NUMBER = 'Number',
  STATEFUL_SELECT = `StatefulSelect`,
  STATEFUL_SEARCH = `StatefulSearch`,
  STATEFUL_NUMBER = `StatefulNumber`,
}

export type IBaseFilter<TAccessors extends string = string> = {
  label: string;
  accessor: TAccessors;
  defaultValue?: string | null;
  disabled?: boolean;
  addedByDefault?: boolean;
  hiddenInMenu?: boolean;
};

export type IFilterValue = string | number | null;

export type IFilter<TAccessors extends string = string> = IBaseFilter<TAccessors> &
  (
    | {
        __typename: FilterTypeEnum.SEARCH;
      }
    | {
        __typename: FilterTypeEnum.NUMBER;
      }
    | {
        __typename: FilterTypeEnum.SELECT;
        options: Array<{ label: string; value: string | null }>;
        loadingInitialOptions?: boolean;
      }
  );

export type IStatefulFilter<
  TAccessors extends string = string,
  TValue extends IFilterValue = string
> = IBaseFilter<TAccessors> &
  (
    | {
        __typename: FilterTypeEnum.STATEFUL_SEARCH;
      }
    | {
        __typename: FilterTypeEnum.STATEFUL_NUMBER;
      }
    | {
        __typename: FilterTypeEnum.STATEFUL_SELECT;
        options: Array<{ label: string; value: string | null }>;
        loadingInitialOptions?: boolean;
      }
  ) & {
    updateValue: (newValue: IFilterValue) => void;
    removeFilter: () => void;
    value: TValue | null;
    added: boolean;
  };

export type IFilters<TAccessors extends string = string> = IFilter<TAccessors>[];

export type IStatefulFilters<
  TAccessors extends string = string,
  TValues extends IFilterValue = string
> = IStatefulFilter<TAccessors, TValues>[];

/**
 * Table Action Interfaces
 * e.g. create new member, export as csv
 */
export interface ITableAction {
  icon?: ReactElement;
  title: string;
  onClick: (e: SyntheticEvent) => void;
}

export interface ITableActionMenu {
  actions: ITableAction[];
  icon?: ReactElement;
  title?: string;
}

export type ITableActions = Array<ITableAction | ITableActionMenu>;

/**
 * Form interfaces
 */

export type IFormMapping = IDict<
  InputProps | TextareaProps | SelectProps | CheckboxProps
>;

export type IFormData = IDict<IFormValue['value']>;

export interface IFormValue {
  value: string | boolean | number | ISelectValue;
}

export interface ISelectValue {
  value: string | number;
  options: ISelectOption;
}

export interface ISelectOption {
  value: string | number;
  title: string;
}

/**
 * Data Count interface
 * Used for table pagination
 */

export interface IDataCount {
  totalCount: number;
  pages: number;
  itemsPerPage: number;
}

/**
 * PropertyType enum
 * can be used for object and user
 * properties
 */

export enum PropertyType {
  string = 'string',
  text = 'text',
  number = 'number',
  tags = 'tags',
  date = 'date',
  datetime = 'datetime',
  bool = 'bool',
}

/**
 * Property Tag Values
 */

export type ITagValues = Array<ITagValue>;

export interface ITagValue {
  id: string;
  displayName: string;
  name: string;
  order: number;
  type: string;
}

/**
 * Apollo State Interface
 * including loading, and error
 */

export interface IApolloState {
  loading?: boolean;
  error?: ApolloError | Error;
}

/**
 * Chat Interfaces
 */

export type IUnsentMessage = {
  __typename: 'UnsentChatMessage';
  senderId: string;
  simpleMessage: string;
  sentAt: Moment;
  chatId: string;
  id: string;
  error?: ApolloError;
};

export interface IPostChatMessage {
  chatId: string;
  message: string;
  companyId: string;
  unsentMessageId: string;
}

/**
 * Upload Interfaces and enums
 */

export enum FileUploadStatus {
  NOT_STARTED = 'notStarted',
  UPLOADING = 'uploading',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export interface IFileUpload extends IImage {
  file: File;
  upload?: Promise<any>;
  uploadStatus: FileUploadStatus;
  progress?: number;
  cancelSource: CancelTokenSource;
  uploadGroupId: string;
  uploadFunction?: (url: string, file: IFileUpload) => Promise<void>;
  uploadUrl?: string;
  pathToMatchWhenRefetching?: string;
}

export interface IUploadGroup {
  id: string;
  title: string;
}

// Multi Checkbox

export enum CheckboxStates {
  CHECKED = 'Checked',
  INDETERMINATE = 'Indeterminate',
  EMPTY = 'Empty',
}
