import { useCallback, useMemo } from 'react';
import { useQuery } from './useQuery';

const parseParamToNumber = (searchParams: URLSearchParams, param: string) => {
  const parsedPageQuery = parseInt(searchParams.get(param) as string, 10);
  return Number.isNaN(parsedPageQuery) ? undefined : parsedPageQuery;
};

const getParam = (
  searchParams: URLSearchParams,
  param: string,
  defaultValue: number
) => {
  const parsedParam = parseParamToNumber(searchParams, param);
  return parsedParam ?? defaultValue;
};

const PAGE_DEFAULT = 1;
const TAKE_DEFAULT = 25;

export const usePagination = () => {
  const { query, updateQuery } = useQuery();

  const page = useMemo(() => getParam(query, 'page', PAGE_DEFAULT), [query]);
  const take = useMemo(() => getParam(query, 'take', TAKE_DEFAULT), [query]);

  const nextPage = useCallback(() => {
    updateQuery({ page: page + 1 });
  }, [page, updateQuery]);

  const prevPage = useCallback(() => {
    updateQuery({ page: page - 1 });
  }, [page, updateQuery]);

  const setTake = useCallback(
    (newTake: number) => {
      updateQuery({ take: newTake });
    },
    [updateQuery]
  );

  return {
    page,
    take,
    nextPage,
    prevPage,
    setTake,
  };
};
