import {
  Box,
  SkeletonText,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';
import { ApolloError } from '@apollo/client';
import State from '../loading/State';

export interface StatCardProps {
  icon: React.ElementType;
  accentColor: string;
  loading: boolean;
  error?: ApolloError;
  data: {
    symbol: string;
    label: string;
    value?: string | number;
  };
}

export const StatCard = (props: StatCardProps) => {
  const { data, accentColor, icon, loading, error } = props;
  const { label, value, symbol } = data;

  return (
    <Stat bg={mode('gray.50', 'gray.800')} p={'4'} shadow={'base'} rounded={'lg'}>
      <StatLabel>
        <Box as={icon} color={accentColor} />
        {label}
      </StatLabel>
      <StatNumber>
        <State
          loading={loading}
          error={error}
          customLoadingElement={
            <SkeletonText
              my={'0.375rem'}
              width={'50px'}
              skeletonHeight={'1.5rem'}
              noOfLines={1}
            />
          }>
          {value}
        </State>
      </StatNumber>
      <StatHelpText>{symbol}</StatHelpText>
    </Stat>
  );
};
