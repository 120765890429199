import { IDict, IFormData } from '../../types/types';

export const createCompanyValidator = (values: IFormData) => {
  const errors: IDict<any> = {};

  Object.entries(values).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value === '') {
        errors[key] = 'This Field is required!';
      }
    } else if (value?.value === '') {
      errors[key] = 'This Field is required!';
    }
  });

  return errors;
};
