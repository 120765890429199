import React, { useMemo } from 'react';
import { Skeleton, Wrap } from '@chakra-ui/react';
import {
  FilterTypeEnum,
  IFilterValue,
  IStatefulFilter,
  IStatefulFilters,
} from '../../types/types';

export interface FilterWrapperChildProps<
  A extends string = string,
  V extends IFilterValue = string
> {
  filters: IStatefulFilters<A, V>;
  filterAccessorsInUse: string[];
  addFilter: (filter: IStatefulFilter<A, V>) => void;
  removeFilter: (filter: IStatefulFilter<A, V>) => void;
}

interface FilterWrapperProps<
  A extends string = string,
  V extends IFilterValue = string
> {
  filters: IStatefulFilters<A, V>;
}

interface FilterWrapperPropsWithChildren<
  A extends string = string,
  V extends IFilterValue = string
> extends FilterWrapperProps<A, V> {
  children: (props: FilterWrapperChildProps<A, V>) => React.ReactNode;
}

export const FilterWrapper = <
  A extends string = string,
  V extends IFilterValue = string
>({
  filters,
  children,
}: FilterWrapperPropsWithChildren<A, V>) => {
  const filterAccessorsInUse = useMemo(
    () => filters.filter(({ added }) => added).map(({ accessor }) => accessor),
    [filters]
  );
  const loading = useMemo(
    () =>
      filters.some((filter) => {
        if (filter.__typename === FilterTypeEnum.STATEFUL_SELECT) {
          return filter.loadingInitialOptions;
        }
        return false;
      }),
    [filters]
  );

  const addFilter = (filter: IStatefulFilter<A, V>) => {
    if (!filter.added) {
      filter.updateValue(
        filter.defaultValue === '' ? null : filter.defaultValue ?? null
      );
    }
  };

  const removeFilter = (filter: IStatefulFilter<A, V>) => {
    filter.removeFilter();
  };

  return (
    <Wrap>
      {loading ? (
        <Skeleton height={'32px'} width={'32px'} />
      ) : (
        children({
          filterAccessorsInUse,
          filters,
          addFilter,
          removeFilter,
        })
      )}
    </Wrap>
  );
};
