import React, { useCallback } from 'react';
import { TableContent } from '../../components/table/TableContent';
import { IDataCount, ITableActions, UserType } from '../../types/types';
import Table from '../../components/table/Table';
import { parseQueriedUserToMap } from '../../util/query-data-parser/user-parser';
import { UsersQuery, useUsersQuery } from '../../graphql/types-and-hooks';
import { usePagination } from '../../util/hooks/usePagination';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { TableActions } from '../../components/table/TableActions';
import { TablePagination } from '../../components/table/TablePagination';
import { useFilter } from '../../util/hooks/useFilter';
import { TUsersFilterAccessors, userFilters } from './data/filters';
import { userColumns } from './data/columns';

const Users = () => {
  const { page, take } = usePagination();

  const { filterValues, statefulFilters } = useFilter<
    TUsersFilterAccessors,
    string | UserType | null
  >({
    filters: userFilters,
  });

  const [usersQueryResult, usersParsedData] = useQueryWrapper({
    query: useUsersQuery,
    options: {
      variables: {
        take,
        page: page - 1,
        filter: {
          email: filterValues.search,
          fullName: filterValues.search,
          phone: filterValues.search,
        },
      },
    },
    parser: useCallback<
      (data: UsersQuery) => {
        userDataCount: IDataCount;
        users: Map<string, any>[];
      }
    >((data) => {
      const { data: users, count } = data.users;
      return {
        userDataCount: { ...count },
        users: users.map((user) => parseQueriedUserToMap(user)),
      };
    }, []),
  });

  const actions: ITableActions = [];

  return (
    <Table
      tableName={'Users'}
      apolloState={{
        loading: usersQueryResult.loading,
        error: usersQueryResult.error,
      }}>
      {({ apolloState }) => (
        <>
          <TableActions filters={statefulFilters} actions={actions} />
          <TableContent
            apolloState={apolloState}
            columns={userColumns}
            data={usersParsedData?.users}
          />
          <TablePagination
            apolloState={apolloState}
            dataCount={usersParsedData?.userDataCount}
          />
        </>
      )}
    </Table>
  );
};
export default Users;
