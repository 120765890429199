import React from 'react';
import { Badge, Flex } from '@chakra-ui/react';
import moment from 'moment';
import { ColumnProps } from '../../../components/table/TableContent';
import { UserCell } from '../../../components/cell/UserCell';
import { Match as MatchType, User } from '../../../graphql/types-and-hooks';
import { ChatIconButton } from '../../../components/buttons/ChatIconButton';
import { MatchButton } from '../../../components/swipe/MatchButton';

export const swipeColumns: Array<ColumnProps> = [
  {
    header: 'Tenant',
    accessors: ['user'],
    Cell: ({ id, pictures, ...rest }: any) => (
      <UserCell image={pictures[0]?.url} pathnameToOpen={`/users/${id}`} {...rest} />
    ),
  },
  {
    header: 'Swipe Data',
    accessors: ['positive', 'swipedAt', 'match'],
    Cell: ({
      positive,
      swipedAt,
      match,
    }: {
      positive: boolean;
      swipedAt: string;
      match?: MatchType;
    }) => (
      <Flex flexFlow={'wrap'} maxW={'250px'} spacing={2} m={-1}>
        <Badge
          m={1}
          w={'min-content'}
          colorScheme={positive ? 'green' : 'orange'}
          variant={'solid'}>
          {positive ? 'Liked' : 'Disliked'}
        </Badge>
        {match && (
          <Badge m={1} w={'min-content'} colorScheme={'green'} variant={'solid'}>
            Matched
          </Badge>
        )}
        <Badge m={1} w={'min-content'} variant={'outline'}>
          Swiped: {moment(swipedAt).format('DD.MM.YYYY hh:mm')}
        </Badge>
        {match && (
          <Badge m={1} w={'min-content'} variant={'outline'}>
            Matched: {moment(match.matchedAt).format('DD.MM.YYYY hh:mm')}
          </Badge>
        )}
      </Flex>
    ),
  },
  {
    header: 'Action',
    accessors: ['user', 'objectId', 'match'],
    Cell: ({
      user,
      objectId,
      match,
    }: {
      user: User;
      objectId: string;
      match?: MatchType;
    }) =>
      match ? (
        <ChatIconButton chatId={match.chatId} />
      ) : (
        <MatchButton
          matchWith={user}
          userId={user.id}
          alert={true}
          objectId={objectId}
        />
      ),
  },
];
