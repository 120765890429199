import { Box, Flex } from '@chakra-ui/react';
import { Check, Shield } from 'react-feather';
import React from 'react';

interface IProps {
  verified: boolean;
}

const VERIFIED_COLOR = '#3182CE'; // blue.500
const UNVERIFIED_COLOR = '#A0AEC0'; // gray.400

export const VerifiedShieldBadge = ({ verified }: IProps) => {
  return (
    <Flex
      w={'20px'}
      h={'20px'}
      color={verified ? VERIFIED_COLOR : UNVERIFIED_COLOR}
      justify={'center'}
      align={'center'}
      position={'relative'}>
      <Box position={'absolute'}>
        <Shield
          fillRule={'nonzero'}
          fill={verified ? VERIFIED_COLOR : UNVERIFIED_COLOR}
          size={20}
        />
      </Box>
      <Box position={'absolute'}>
        <Check size={10} color={'white'} />
      </Box>
    </Flex>
  );
};
