import React from 'react';
import { Avatar, AvatarGroup as ChakraAvatarGroup } from '@chakra-ui/react';
import { ChatQuery } from '../../graphql/types-and-hooks';

interface AvatarGroupProps {
  participants: ChatQuery['chat']['participants'];
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'xs' | 'full' | '2xs';
}

export const AvatarGroup: React.FC<AvatarGroupProps> = (props) => {
  const { participants, size = 'sm' } = props;

  return (
    <ChakraAvatarGroup size={size} max={3}>
      {participants.map((participant, index) => (
        <Avatar
          key={index}
          name={participant.name?.text}
          src={participant.image?.url}
        />
      ))}
    </ChakraAvatarGroup>
  );
};
