import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { uploadImageToS3 } from '../upload-image-to-s3';
import { defaultUploadGroup, useUploadContext } from '../contexts/UploadContext';
import { IUploadGroup } from '../../types/types';

/**
 *
 * @param getUploadUrls Must be a React.useCallback!
 * @param uploadGroup This will allow you to group your uploads. `id` is unique.
 * @param pathToMatchWhenRefetching When changing the path while uploading
 * this will make sure a refetch is only done when on the right path
 */
export const useUploadImages = (
  getUploadUrls: (count: number) => Promise<string[]>,
  uploadGroup?: IUploadGroup,
  pathToMatchWhenRefetching?: string
) => {
  const { files, dispatch, addUploadGroup } = useUploadContext();

  const removeFile = useCallback(
    (fileId: string) => {
      dispatch({ type: 'removeFile', id: fileId });
    },
    [dispatch]
  );

  useEffect(() => {
    if (uploadGroup) {
      addUploadGroup(uploadGroup);
    }
  }, [uploadGroup, addUploadGroup]);

  const uploadFiles = useCallback(
    (acceptedFiles: File[]) => {
      const acceptedFilesWithIds = acceptedFiles.map((acceptedFile) => ({
        file: acceptedFile,
        id: uuidv4(),
      }));

      dispatch({
        type: 'addFilesLazy',
        filesWithId: acceptedFilesWithIds,
        pathToMatchWhenRefetching,
        uploadGroupId: uploadGroup?.id,
      });

      getUploadUrls(acceptedFiles.length).then((urls) => {
        if (urls.length === acceptedFiles.length) {
          acceptedFilesWithIds.forEach((acceptedFileWithId, index) => {
            dispatch({
              type: 'addUploadUrl',
              id: acceptedFileWithId.id,
              url: urls[index],
              uploadFunction: (url, file) =>
                uploadImageToS3(url, file, (progress) =>
                  dispatch({ type: 'updateProgress', id: file.id, progress })
                )
                  .then(() => {
                    dispatch({ type: 'success', id: file.id });
                  })
                  .catch((error) => {
                    dispatch({ type: 'failed', id: file.id });
                    throw error;
                  }),
            });
          });
        } else {
          throw new Error(
            "upload urls array length doesn't match length of files to upload"
          );
        }
      });
    },
    [dispatch, uploadGroup, getUploadUrls, pathToMatchWhenRefetching]
  );

  return useMemo(
    () => ({
      uploadFiles,
      files: files.filter(
        (file) => file.uploadGroupId === (uploadGroup?.id ?? defaultUploadGroup.id)
      ),
      allFiles: files,
      removeFile,
    }),
    [files, uploadFiles, removeFile, uploadGroup]
  );
};
