import React from 'react';
import { toast } from 'react-toastify';

/**
 *
 * @param toastId toastId that should be dismissed
 */
export const dismiss = (toastId?: React.ReactText) => {
  if (toastId) toast.dismiss(toastId);
};

/**
 * Dismiss all toasts
 */
export const dismissAll = () => toast.dismiss();
