import React from 'react';
import { Button, Checkbox, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import {
  AutoConsentsFormData,
  parseAutoConsentsToFormData,
  parseFormDataToAutoConsents,
} from '../../util/query-data-parser/auto-consents-parser';
import State from '../loading/State';
import { Form, FormWrapper } from '../form/Form';
import { AutoConsent } from '../../graphql/types-and-hooks';

interface AutoConsentFormProps {
  initialData: AutoConsent[];
  dataMapping: any;
  submitFunction: (data: AutoConsentsFormData) => Promise<any> | undefined;
  loading: boolean;
}

export const AutoConsentForm = ({
  initialData,
  dataMapping,
  submitFunction,
  loading,
}: AutoConsentFormProps) => {
  return (
    <FormWrapper
      initialData={parseAutoConsentsToFormData(initialData)}
      dataMapping={dataMapping}
      inEditMode={true}
      submitFunction={submitFunction}>
      {(props) => (
        <State loading={loading}>
          <Form {...props} />

          <Divider mt={4} variant={'dashed'} />

          {/* LEGEND */}
          <Stack
            mt={6}
            mb={2}
            direction={'row'}
            position={'relative'}
            justifyContent={'center'}
            alignItems={'center'}>
            <Stack ml={4} position={'absolute'} left={0}>
              <Stack direction={'row'}>
                <Checkbox size={'sm'} isChecked={true} disabled={true} />
                <Text fontSize={'sm'}>Required Consent</Text>
              </Stack>
              <Stack direction={'row'}>
                <Checkbox size={'sm'} isIndeterminate={true} disabled={true} />
                <Text fontSize={'sm'}>Optional Consent</Text>
              </Stack>
            </Stack>

            <Flex justifyContent={'center'}>
              <Button
                isLoading={props.formik.isSubmitting}
                onClick={props.formik.submitForm}
                disabled={
                  props.formik.isSubmitting ||
                  props.formik.isValidating ||
                  parseFormDataToAutoConsents(props.formik.values, initialData)
                    .length === 0
                }
                colorScheme={'green'}
                size={'sm'}>
                Save
              </Button>
            </Flex>
          </Stack>
        </State>
      )}
    </FormWrapper>
  );
};
