import { ObjectSwipesQuery } from '../../graphql/types-and-hooks';
import { parseQueriedUserToMap } from './user-parser';

export const parseObjectSwipeToMap = (
  swipe: ObjectSwipesQuery['objectSwipes'][0],
  objectId: string
) => {
  const swipeMap = new Map<string, any>(Object.entries(swipe));

  swipeMap.set('user', Object.fromEntries(parseQueriedUserToMap(swipe.user)));
  swipeMap.set('objectId', objectId);

  return swipeMap;
};

export const parseObjectSwipes = (data: ObjectSwipesQuery, objectId: string) =>
  data.objectSwipes
    .filter((objectSwipe) => objectSwipe.positive)
    .map((objectSwipe) => parseObjectSwipeToMap(objectSwipe, objectId));
