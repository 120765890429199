import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
  useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from '../../views/Home';
import Objects from '../../views/Objects/Objects';
import Users from '../../views/Users/Users';
import User from '../../views/User';
import Settings from '../../views/Settings';
import AuthView from '../../views/AuthView';
import SidebarLayout from '../nav/SidebarLayout';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from '../../util/hooks/useAuth';
import Chats from '../../views/Chats';
import Support from '../../views/Support';
import ObjectView from '../../views/Object/Object';
import { UploadProvider } from '../../util/contexts/UploadContext';
import Landlords from '../../views/Landlords/Landlords';
import Company from '../../views/Company/Company';

interface IPage {
  path: string;
  Component: any;
  title: string;
  sidebarLayout?: boolean;
  privateRoute?: boolean;
}

interface IRedirect {
  path: string;
  to: string;
  exact?: boolean;
}

const routes: (IPage | IRedirect)[] = [
  {
    path: '/',
    Component: Home,
    title: 'Home',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/users',
    Component: Users,
    title: 'Users',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/chats',
    Component: Chats,
    title: 'Chats',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/users/:userId',
    Component: User,
    title: 'User',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/objects',
    Component: Objects,
    title: 'Objects',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/objects/:objectId',
    Component: ObjectView,
    title: 'ObjectView',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/auth',
    Component: AuthView,
    title: 'Auth',
    sidebarLayout: false,
  },
  {
    path: '/settings',
    Component: Settings,
    title: 'Settings',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/support',
    Component: Support,
    title: 'Support',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/landlords',
    Component: Landlords,
    title: 'Landlords',
    sidebarLayout: true,
    privateRoute: true,
  },
  {
    path: '/companies',
    to: '/landlords?landlordType=company',
    exact: true,
  },
  {
    path: '/companies/:companyId',
    Component: Company,
    title: 'Company',
    sidebarLayout: true,
    privateRoute: true,
  },
];

const MainSwitch = () => {
  const pageRoutes = routes.filter((route) => !('to' in route)) as IPage[];
  const redirectRoutes = routes.filter((route) => 'to' in route) as IRedirect[];

  const sidebarLayoutRoutes = pageRoutes.filter((page) => page.sidebarLayout);
  const normalRoutes = pageRoutes.filter((page) => !page.sidebarLayout);

  const { pathname } = useLocation();

  return (
    <Switch>
      <Redirect from={'/:url*(/+)'} to={pathname.slice(0, -1)} />
      {redirectRoutes.map((route) => (
        <Redirect to={route.to} from={route.path} exact={route.exact} />
      ))}
      {normalRoutes.map((page, index) =>
        page.privateRoute ? (
          <PrivateRoute key={index} path={page.path} exact>
            {withRouter(page.Component)}
          </PrivateRoute>
        ) : (
          <Route key={index} path={page.path} exact>
            {withRouter(page.Component)}
          </Route>
        )
      )}
      <Route path={'/'}>
        <SidebarLayout>
          <Switch>
            {sidebarLayoutRoutes.map((page, index) =>
              page.privateRoute ? (
                <PrivateRoute key={index} path={page.path} exact>
                  {withRouter(page.Component)}
                </PrivateRoute>
              ) : (
                <Route key={index} path={page.path} exact>
                  {withRouter(page.Component)}
                </Route>
              )
            )}
            <Redirect to={'/'} />
          </Switch>
        </SidebarLayout>
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return (
    <Router>
      <AuthProvider>
        <UploadProvider>
          <ToastContainer style={{ fontSize: 'var(--chakra-fontSizes-sm)' }} />
          <MainSwitch />
        </UploadProvider>
      </AuthProvider>
    </Router>
  );
};

export default Routing;
