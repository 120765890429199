import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  StackDivider,
  StackProps,
  Tag,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { breakpoints } from '../../data/breakpoints';

interface FieldGroupProps extends StackProps {
  title?: string;
  description?: React.ReactElement | string;
  stickyElement?: React.ReactElement | boolean;
}

export interface ExtendableFieldGroupProps {
  fieldGroupProps?: FieldGroupProps;
}

export const FieldGroup = (props: FieldGroupProps) => {
  const { title, children, description, stickyElement, ...flexProps } = props;
  return (
    <Stack
      direction={{ base: 'column' }}
      spacing={'4'}
      pb={'4'}
      divider={<StackDivider />}
      {...flexProps}>
      <Box minW={'7rem'} width={{ [breakpoints.content]: '30%' }} maxW={'xs'}>
        {title && (
          <Heading as={'h1'} fontWeight={'semibold'} size={'md'} flexShrink={0}>
            {title}
          </Heading>
        )}
        {description &&
          (typeof description === 'string' ? (
            <Text fontSize={'md'} color={mode('gray.600', 'gray.400')} mt={'2'}>
              {description}
            </Text>
          ) : (
            description
          ))}
      </Box>
      <Box width={'full'} px={{ base: '2', [breakpoints.content]: '0' }}>
        <Stack direction={'row'} spacing={'4'}>
          <Box w={'full'} my={'4'}>
            {children || (
              <Flex justify={'center'}>
                <Tag fontSize={'sm'}>Nothing here...</Tag>
              </Flex>
            )}
          </Box>
          <Box>
            {stickyElement && (
              <Box position={'relative'} height={'full'}>
                <Box width={'full'} position={'sticky'} top={'2'}>
                  {stickyElement}
                </Box>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
