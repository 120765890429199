import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { IFilterValue, IStatefulFilters, ITableActions } from '../../types/types';
import { FilterWrapper } from '../filter/FilterWrapper';
import { AddFilter } from '../filter/AddFilter';
import { Filters } from '../filter/Filters';

interface TableActionsProps<A extends string, V extends IFilterValue> {
  filters?: IStatefulFilters<A, V>;
  actions?: ITableActions;
}

export const TableActions = <A extends string, V extends IFilterValue>({
  filters,
  actions,
}: TableActionsProps<A, V>) => {
  return (
    <Stack
      w={'full'}
      spacing={'4'}
      direction={{ base: 'column', lg: 'row' }}
      justify={'space-between'}>
      {filters && (
        <FilterWrapper filters={filters}>
          {({
            filters: filtersFromWrapper,
            filterAccessorsInUse,
            addFilter,
            removeFilter,
          }) => (
            <>
              <AddFilter
                filters={filtersFromWrapper}
                filterAccessorsInUse={filterAccessorsInUse}
                addFilter={addFilter}
                removeFilter={removeFilter}
              />
              <Filters
                filters={filtersFromWrapper}
                filterAccessorsInUse={filterAccessorsInUse}
              />
            </>
          )}
        </FilterWrapper>
      )}
      <ButtonGroup size={'sm'} variant={'outline'}>
        {actions &&
          actions.map((action, index) => {
            if ('actions' in action) {
              return (
                <Menu>
                  <MenuButton
                    as={action.icon && !action.title ? IconButton : Button}
                    rightIcon={action.title ? action.icon : undefined}
                    icon={!action.title ? action.icon : undefined}
                    size={'sm'}
                    aria-label={'Chats Filter'}>
                    {action.title}
                  </MenuButton>
                  <MenuList zIndex={11}>
                    {action.actions.map((menuAction, menuActionIndex) => (
                      <MenuItem
                        key={menuActionIndex}
                        // 24px   IconButton height when size "xs"
                        // 0.8rem Two times the y padding of MenuItem (2 x 0.4rem)
                        minH={'calc(24px + 0.8rem)'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        fontSize={'sm'}
                        onClick={menuAction.onClick}>
                        <Text>{menuAction.title}</Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              );
            }

            return (
              <Button
                iconSpacing={'1'}
                leftIcon={action.icon}
                onClick={action.onClick}
                key={index}>
                {action.title}
              </Button>
            );
          })}
      </ButtonGroup>
    </Stack>
  );
};
