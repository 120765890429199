import { Skeleton, Stack, StackDivider } from '@chakra-ui/react';
import React from 'react';

export const PropertiesSkeleton: React.FC = () => {
  return (
    <Stack
      spacing={4}
      m={'auto'}
      maxW={'xl'}
      divider={<StackDivider w={'80%'} alignSelf={'center'} />}>
      <Skeleton h={'20px'} w={'120px'} />
      <Stack spacing={10}>
        <Stack>
          <Skeleton h={'20px'} w={'60px'} />
          <Skeleton h={'35px'} />
        </Stack>
        <Stack>
          <Skeleton h={'20px'} w={'60px'} />
          <Skeleton h={'35px'} />
        </Stack>
        <Stack>
          <Skeleton h={'20px'} w={'60px'} />
          <Skeleton h={'35px'} />
        </Stack>
        <Stack>
          <Skeleton h={'20px'} w={'60px'} />
          <Skeleton h={'35px'} />
        </Stack>
      </Stack>
    </Stack>
  );
};
