import React from 'react';
import { Badge, Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IdentityProviderTag } from '../../../components/tags/IdentityProviderTag';
import { UserCell, UserCellProps } from '../../../components/cell/UserCell';
import { ColumnProps } from '../../../components/table/TableContent';
import { IdentityProvider } from '../../../types/types';
import { AccountDeletionRequest, UserMode } from '../../../graphql/types-and-hooks';
import { VerifiedShieldBadge } from '../../../components/badge/VerifiedShieldBadge';

export const userColumns: Array<ColumnProps> = [
  {
    header: 'User',
    accessors: ['firstName', 'lastName', 'email', 'id', 'pictures', 'verified'],
    Cell: ({
      id,
      pictures,
      ...data
    }: Partial<UserCellProps> & {
      id: string;
      pictures: { id: string; url: string }[];
      verified: boolean;
    }) => (
      <Flex align={'center'}>
        <UserCell pathnameToOpen={`/users/${id}`} image={pictures[0]?.url} {...data} />
        <Box ml={2}>
          <Link to={`/users/${id}?tab=4`}>
            <VerifiedShieldBadge verified={data.verified} />
          </Link>
        </Box>
      </Flex>
    ),
  },
  {
    header: 'User Type',
    accessors: ['landlordId', 'currentUserMode'],
    Cell: ({
      landlordId,
      currentUserMode,
    }: {
      landlordId?: string;
      currentUserMode?: UserMode;
    }) => {
      const isLandlord = !!landlordId;
      const otherMode =
        currentUserMode === UserMode.Tenant ? UserMode.Landlord : UserMode.Tenant;

      return (
        <Stack>
          {currentUserMode && (
            <Badge
              colorScheme={currentUserMode === UserMode.Tenant ? 'blue' : 'orange'}
              w={'min'}>
              <Text fontWeight={'bold'}>{currentUserMode}</Text>
            </Badge>
          )}
          {isLandlord && otherMode && (
            <Badge colorScheme={'gray'} opacity={0.4} w={'min'}>
              {otherMode}
            </Badge>
          )}
        </Stack>
      );
    },
  },
  {
    header: 'Status',
    accessors: ['onboardingFinished', 'accountDeletionRequest', 'id'],
    Cell: ({
      onboardingFinished,
      accountDeletionRequest,
      id,
    }: {
      onboardingFinished: boolean;
      accountDeletionRequest?: AccountDeletionRequest;
      id: string;
    }) => {
      return (
        <Stack>
          {onboardingFinished && (
            <Badge width={'min-content'} colorScheme={'green'}>
              Onboarded
            </Badge>
          )}
          {accountDeletionRequest && (
            <Tooltip
              hasArrow
              label={`Account gets deleted on: ${moment(
                accountDeletionRequest.timeOfDeletion
              ).format('LLL')}`}>
              <Link to={`/users/${id}?tab=4`}>
                <Badge width={'min-content'} colorScheme={'red'}>
                  Requested Deletion
                </Badge>
              </Link>
            </Tooltip>
          )}
        </Stack>
      );
    },
  },
  {
    header: 'Identity Provider',
    accessors: ['identityProvider'],
    Cell: (data: IdentityProvider) => {
      return (
        <Box width={'min-content'}>
          <Tooltip hasArrow label={data}>
            <Box>
              <IdentityProviderTag provider={data} />
            </Box>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    header: 'Work status',
    accessors: ['status'],
    Cell: (data: string) => <Badge colorScheme={'purple'}>{data}</Badge>,
  },
  {
    header: 'Signup Date',
    accessors: ['signupDate'],
    Cell: (data: string) => (
      <Tooltip hasArrow={true} label={moment(data).format('LLL')}>
        <Text width={'max-content'}>{moment(data).format('L')}</Text>
      </Tooltip>
    ),
  },
  {
    header: 'Locale',
    accessors: ['locale'],
    Cell: (data: string) => <Badge>{data}</Badge>,
  },
];
