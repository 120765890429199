import { ConsentAttributesQuery } from '../../graphql/types-and-hooks';
import { IFormMapping } from '../../types/types';

export const parseConsentAttributesToFormMap = (
  data: ConsentAttributesQuery
): IFormMapping => {
  const consentAttributesObject: IFormMapping = {
    document: { label: 'Documents', type: 'checkbox-group', checkboxes: [] },
    other: { label: 'Other', type: 'checkbox-group', checkboxes: [] },
    userProperty: {
      label: 'User Properties',
      type: 'checkbox-group',
      checkboxes: [],
    },
  };
  consentAttributesObject.document.checkboxes = data.consentAttributes
    .filter((attribute) => attribute.type === 'document')
    .map((attribute) => ({ key: attribute.id, label: attribute.displayName.text }));
  consentAttributesObject.other.checkboxes = data.consentAttributes
    .filter((attribute) => attribute.type === 'other')
    .map((attribute) => ({ key: attribute.id, label: attribute.displayName.text }));
  consentAttributesObject.userProperty.checkboxes = data.consentAttributes
    .filter((attribute) => attribute.type === 'user_property')
    .map((attribute) => ({ key: attribute.id, label: attribute.displayName.text }));
  return consentAttributesObject;
};
