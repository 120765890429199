import React, { useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { FiPlus } from 'react-icons/all';
import {
  LandlordsQuery,
  LandlordType,
  useLandlordsQuery,
} from '../../graphql/types-and-hooks';
import { ITableActions } from '../../types/types';
import Table from '../../components/table/Table';
import { useQueryWrapper } from '../../util/hooks/useQueryWrapper';
import { TableActions } from '../../components/table/TableActions';
import { TableContent } from '../../components/table/TableContent';
import { CreateCompanyFormModal } from './components/CreateLandlord';
import { useFilter } from '../../util/hooks/useFilter';
import { landlordFilters } from './data/filters';
import { landlordColumns } from './data/columns';

const Landlords = () => {
  const { filterValues, statefulFilters } = useFilter<
    'landlordType',
    LandlordType | null
  >({
    filters: landlordFilters,
  });

  const [landlordsQueryResult, landlordsParsedData] = useQueryWrapper({
    query: useLandlordsQuery,
    options: {
      variables: {
        type: filterValues.landlordType,
      },
    },
    parser: useCallback<(data: LandlordsQuery) => Map<string, any>[]>(
      (data) => data.landlords.map((object) => new Map(Object.entries(object))),
      []
    ),
  });

  const createCompanyModalDisclosure = useDisclosure();

  const actions: ITableActions = [
    {
      title: 'Create Company',
      icon: <FiPlus />,
      onClick: () => createCompanyModalDisclosure.onOpen(),
    },
  ];

  return (
    <>
      <CreateCompanyFormModal
        title={'Create Company/Landlord'}
        modalDisclosure={createCompanyModalDisclosure}
      />
      <Table
        tableName={'Landlords'}
        apolloState={{
          loading: landlordsQueryResult.loading,
          error: landlordsQueryResult.error,
        }}>
        {({ apolloState }) => (
          <>
            <TableActions filters={statefulFilters} actions={actions} />
            <TableContent
              apolloState={apolloState}
              columns={landlordColumns}
              data={landlordsParsedData}
            />
          </>
        )}
      </Table>
    </>
  );
};

export default Landlords;
