import { AmplifyConfig } from 'aws-amplify/lib/Common/types/types';

interface AmplifyConfigWithAppsync extends AmplifyConfig {
  // eslint-disable-next-line camelcase
  aws_appsync_graphqlEndpoint: string;
  // eslint-disable-next-line camelcase
  aws_appsync_region: string;
  // eslint-disable-next-line camelcase
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS';
}

const Auth = {
  // Amazon Cognito Region
  region: process.env.REACT_APP_REGION,
  // Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_AUTH_USER_POOL,
  // Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_AUTH_USER_CLIENT,
};

const awsconfig: AmplifyConfigWithAppsync = {
  Auth,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_API_ENDPOINT ?? '',
  aws_appsync_region: process.env.REACT_APP_REGION ?? '',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

export default awsconfig;
