import React from 'react';
import { Badge, HStack, Stack, Text } from '@chakra-ui/react';

interface StatFieldProps {
  label: string;
  data: string;
}

export const StatField = (props: StatFieldProps) => {
  const { label, data } = props;

  return (
    <HStack justify={'space-between'} width={'full'}>
      <Text fontSize={'sm'}>{label}:</Text>
      <Badge>{data}</Badge>
    </HStack>
  );
};

interface StatFieldGroupProps {
  statsList: Array<StatFieldProps>;
}

export const Stats = (props: StatFieldGroupProps) => {
  const { statsList } = props;

  return statsList.length > 0 ? (
    <Stack width={'full'} spacing={'4'}>
      {statsList.map((stat, index) => (
        <StatField key={index} label={stat.label} data={stat.data} />
      ))}
    </Stack>
  ) : null;
};
