import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { FiMessageSquare } from 'react-icons/all';
import { useHistory } from 'react-router-dom';

interface ChatIconButtonProps {
  chatId: string;
}

export const ChatIconButton: React.FC<ChatIconButtonProps> = (props) => {
  const { chatId } = props;
  const history = useHistory();

  return (
    <IconButton
      size={'sm'}
      aria-label={'Open Chat'}
      icon={<FiMessageSquare />}
      onClick={() => history.push(`/chats?chatId=${chatId}`)}
    />
  );
};
