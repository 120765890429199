import React, { useState, useEffect } from 'react';

export const useIntersection = (
  ref: React.RefObject<HTMLElement>,
  rootMargin?: string
): [boolean, boolean] => {
  const [hasBeenVisibleOnce, setHasBeenVisibleOnce] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          setHasBeenVisibleOnce(true);
        }
      },
      { rootMargin }
    );

    const element = ref.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isVisible, hasBeenVisibleOnce];
};
