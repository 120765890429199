import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FiEye, FiTrash, FiUpload, FiXCircle } from 'react-icons/all';
import { useDrag, useDrop } from 'react-dnd';
import { FileRejection, useDropzone } from 'react-dropzone';
import { IUploadGroup } from '../../types/types';
import { MediaModal } from '../modal/MediaModal';
import { useUploadImages } from '../../util/hooks/useUploadImages';

export interface IImage {
  url: string;
  id: string;
  largestUrl?: string;
  deleteImageCallback?: (id: string) => void;
}

export interface ImageFieldProps {
  image: IImage;
}

export const ImageField: React.FC<ImageFieldProps> = ({ image }) => {
  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <>
      <MediaModal
        defaultMimeType={'image/webp'}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        url={image?.largestUrl ?? image.url}
      />
      <Box
        borderRadius={12}
        w={'100%'}
        overflow={'hidden'}
        maxW={'150px'}
        boxShadow={'0 2px 5px #0f0f0f33'}>
        <AspectRatio ratio={1} w={'100%'}>
          <Image
            draggable={false}
            pointersevents={'none'}
            objectFit={'cover'}
            src={image.url}
            alt={image.id}
            loading={'lazy'}
          />
        </AspectRatio>
        <Flex
          mt={-9}
          position={'relative'}
          w={'100%'}
          opacity={0.9}
          bg={mode('gray.200', 'gray.800')}
          h={9}
          justify={'center'}
          align={'center'}>
          <IconButton
            mx={1}
            size={'sm'}
            aria-label={'Look at image'}
            variant={'ghost'}
            onClick={() => setOpen(true)}
            icon={<FiEye />}
          />
          {image?.deleteImageCallback && (
            <IconButton
              mx={1}
              size={'sm'}
              aria-label={'Remove image'}
              variant={'ghost'}
              icon={<FiTrash color={'red'} />}
              onClick={() => image.deleteImageCallback?.(image.id)}
            />
          )}
        </Flex>
      </Box>
    </>
  );
};

export interface ImageFieldDnDProps extends ImageFieldProps {
  orderIndex: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  setIsDragging: (isDragging: boolean) => void;
}

export const ImageFieldDnD: React.FC<ImageFieldDnDProps> = ({
  image,
  orderIndex,
  moveImage,
  setIsDragging,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'image',
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item: { index: number; id: string; type: string }) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = orderIndex;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => {
      return { id: image.id, index: orderIndex };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <Box
      onDragStart={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      w={'full'}
      maxW={'150px'}
      ref={ref}
      data-handler-id={handlerId}
      cursor={'move'}
      opacity={isDragging ? 0 : 1}>
      <ImageField image={image} />
    </Box>
  );
};

export interface ImageUploadFieldProps {
  getUploadUrls: (count: number) => Promise<string[]>;
  uploadGroup?: IUploadGroup;
  pathToMatchWhenRefetching?: string;
}

export const ImageUploadField: React.FC<ImageUploadFieldProps> = (props) => {
  const { getUploadUrls, uploadGroup, pathToMatchWhenRefetching } = props;

  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);

  const { uploadFiles, files } = useUploadImages(
    getUploadUrls,
    uploadGroup,
    pathToMatchWhenRefetching
  );

  const uploadingInProgress = useMemo(
    () =>
      files.some(({ uploadStatus }) => {
        return uploadStatus === 'uploading' || uploadStatus === 'notStarted';
      }),
    [files]
  );

  const onDrop = useCallback(
    (acceptedFiles, fileRejections: FileRejection[]) => {
      uploadFiles(acceptedFiles);
      setRejectedFiles((oldState) => [...oldState, ...fileRejections]);
    },
    [uploadFiles]
  );

  const { getRootProps, open, getInputProps, isDragActive } = useDropzone({
    disabled: false,
    noClick: true,
    accept: 'image/*',
    onDrop,
  });

  return (
    <Popover isOpen={rejectedFiles.length > 0} onClose={() => setRejectedFiles([])}>
      <PopoverTrigger>
        <Box
          {...getRootProps({ className: 'dropzone' })}
          onClick={open}
          borderRadius={12}
          w={'100%'}
          overflow={'hidden'}
          maxW={'150px'}
          boxShadow={'0 2px 5px #0f0f0f33'}>
          <input {...getInputProps()} />
          <AspectRatio ratio={1} w={'100%'}>
            <Flex
              bg={
                isDragActive
                  ? mode(`gray.200`, 'gray.700')
                  : mode('gray.100', 'gray.800')
              }
              w={'100%'}
              h={'100%'}
              direction={'column'}
              p={2}
              cursor={'pointer'}
              _hover={{ bg: mode('gray.200', 'gray.700') }}>
              {uploadingInProgress ? (
                <>
                  <Spinner size={'lg'} color={'gray'} />
                  <Text align={'center'} fontSize={'sm'} mt={2}>
                    Uploading...
                  </Text>
                </>
              ) : (
                <>
                  <FiUpload size={'3rem'} color={'gray'} />
                  <Text align={'center'} fontSize={'sm'} mt={2}>
                    Choose a file or drag it here.
                  </Text>
                </>
              )}
            </Flex>
          </AspectRatio>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontSize={'sm'} fontWeight={'semibold'}>
          Rejected Files:
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Stack>
            {rejectedFiles.map(({ file, errors }) => (
              <Stack direction={'row'} align={'center'}>
                <Icon boxSize={6} color={'red'} as={FiXCircle} />
                <Flex direction={'column'}>
                  <Text fontSize={'sm'}>File: {file.name}</Text>
                  {errors.map((error) => (
                    <Text fontSize={'sm'} color={'red'}>
                      {error.message}
                    </Text>
                  ))}
                </Flex>
              </Stack>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
