const EXPECTED_PUBLIC_ID_LENGTH = 10;

export const parsePublicId = (publicId: number): string => {
  const idLength = publicId.toString().length;
  const missingLeadingZeros = EXPECTED_PUBLIC_ID_LENGTH - idLength;
  return `${'0'.repeat(missingLeadingZeros)}${publicId}`;
};

export const createObjectShareLink = (publicId: number): string => {
  return `https://app.mietz.app/flats?id=${parsePublicId(publicId)}`;
};
