import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An extended ISO 8601 date and time string in the format YYYY-MM-DDThh:mm:ss.sssZ. */
  AWSDateTime: any;
  /** An email address in the format local-part@domain-part as defined by RFC 822. */
  AWSEmail: any;
  /**
   * A JSON string. Any valid JSON construct is automatically parsed and loaded in the resolver mapping templates as maps,
   * lists, or scalar values rather than as the literal input strings. Unquoted strings or otherwise invalid JSON result in
   * a GraphQL validation error.
   */
  AWSJSON: any;
  /**
   * A phone number. This value is stored as a string. Phone numbers can contain either spaces or hyphens to separate digit
   * groups. Phone numbers without a country code are assumed to be US/North American numbers adhering to the North American
   * Numbering Plan (NANP).
   */
  AWSPhone: any;
  /**
   * A URL as defined by RFC 1738. For example, https://www.amazon.com/dp/B000NZW3KC/ or mailto:example@example.com. URLs
   * must contain a schema (http, mailto) and can't contain two forward slashes (//) in the path part.
   */
  AWSURL: any;
};

export type AccountDeletionRequest = {
  __typename?: 'AccountDeletionRequest';
  nameOfDeletionJob: Scalars['String'];
  timeOfDeletion: Scalars['AWSDateTime'];
};

export type AdminAsset = {
  __typename?: 'AdminAsset';
  acl: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  owner?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['AWSURL']>;
};

export type AdminAssets = {
  __typename?: 'AdminAssets';
  count: Count;
  data: Array<AdminAsset>;
};

export type Asset = {
  __typename?: 'Asset';
  id: Scalars['ID'];
  url: Scalars['AWSURL'];
};

export type AssetFilter = {
  type?: InputMaybe<Scalars['String']>;
};

export enum AttributeType {
  Document = 'document',
  Other = 'other',
  UserProperty = 'user_property'
}

export type AutoConsent = {
  __typename?: 'AutoConsent';
  attribute: ConsentAttribute;
  required: Scalars['Boolean'];
};

export type B2bUser = CognitoUser & {
  __typename?: 'B2bUser';
  email?: Maybe<Scalars['AWSEmail']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  identityProvider: IdentityProvider;
  signupDate: Scalars['AWSDateTime'];
};

export type ChatBase = {
  id: Scalars['ID'];
  lastMessage?: Maybe<ChatMessage>;
  participants: Array<ChatParticipant>;
  unreadCount: Scalars['Int'];
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  chatId: Scalars['ID'];
  id: Scalars['ID'];
  readBy: Array<Maybe<Scalars['ID']>>;
  senderId: Scalars['ID'];
  sentAt: Scalars['AWSDateTime'];
  simpleMessage: Scalars['String'];
};

export type ChatObjectData = {
  __typename?: 'ChatObjectData';
  extraInformation: Array<LocalizedString>;
  id: Scalars['ID'];
  picture?: Maybe<Image>;
  title: LocalizedString;
};

export type ChatParticipant = {
  __typename?: 'ChatParticipant';
  companyId?: Maybe<Scalars['ID']>;
  extraInformation: Array<LocalizedString>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  me: Scalars['Boolean'];
  name: LocalizedString;
  type: ChatParticipantType;
  userId?: Maybe<Scalars['ID']>;
};

export enum ChatParticipantType {
  Flatmate = 'flatmate',
  Landlord = 'landlord',
  Support = 'support',
  Tenant = 'tenant',
  User = 'user'
}

export type ChatsFilter = {
  messageCount?: InputMaybe<Scalars['Int']>;
  objectId: Scalars['ID'];
};

export type CognitoUser = {
  email?: Maybe<Scalars['AWSEmail']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  identityProvider: IdentityProvider;
  signupDate: Scalars['AWSDateTime'];
};

export type CognitoUserGroup = {
  __typename?: 'CognitoUserGroup';
  description?: Maybe<Scalars['String']>;
  groupName: Scalars['String'];
};

export enum Color {
  Accent1 = 'accent1',
  Background = 'background',
  Cream = 'cream',
  Error = 'error',
  Foreground = 'foreground',
  Information = 'information',
  OnAccent1 = 'onAccent1',
  OnError = 'onError',
  OnInformation = 'onInformation',
  OnPrimary = 'onPrimary',
  OnSecondary = 'onSecondary',
  OnSuccess = 'onSuccess',
  OnWarning = 'onWarning',
  Primary = 'primary',
  PrimaryLight = 'primaryLight',
  Secondary = 'secondary',
  Success = 'success',
  Warning = 'warning',
  Widget = 'widget'
}

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  landlordId: Scalars['ID'];
  name: Scalars['String'];
  users: Array<B2bUser>;
};

export type CompanyLandlord = {
  __typename?: 'CompanyLandlord';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ConsentAttribute = {
  __typename?: 'ConsentAttribute';
  displayName: LocalizedString;
  id: Scalars['ID'];
  type: AttributeType;
};

export type Count = {
  __typename?: 'Count';
  itemsPerPage: Scalars['Int'];
  pages: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type CreateB2bUserResponse = {
  __typename?: 'CreateB2bUserResponse';
  email: Scalars['String'];
  id: Scalars['ID'];
  password: Scalars['String'];
};

export type CreateCompanyInput = {
  companyName: Scalars['String'];
};

export type CreateMicUserResponse = {
  __typename?: 'CreateMicUserResponse';
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum Gender {
  Diverse = 'diverse',
  Female = 'female',
  Male = 'male'
}

export enum IdentityProvider {
  Apple = 'apple',
  Email = 'email',
  Facebook = 'facebook',
  Google = 'google'
}

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  url: Scalars['AWSURL'];
};


export type ImageUrlArgs = {
  size: ImageSize;
};

export enum ImageSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export type Landlord = {
  __typename?: 'Landlord';
  company?: Maybe<CompanyLandlord>;
  id: Scalars['ID'];
  user?: Maybe<UserLandlord>;
};

export enum LandlordType {
  Company = 'company',
  User = 'user'
}

export type LocaleUserLocalizedString = {
  __typename?: 'LocaleUserLocalizedString';
  locale: Scalars['String'];
  machineTranslation: Scalars['Boolean'];
  originalLanguage?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type LocalizedString = {
  __typename?: 'LocalizedString';
  key: Scalars['String'];
  text: Scalars['String'];
};


export type LocalizedStringTextArgs = {
  locale: Scalars['String'];
};

export type Match = {
  __typename?: 'Match';
  chatId: Scalars['ID'];
  id: Scalars['ID'];
  matchedAt: Scalars['AWSDateTime'];
};

export enum ModifyAction {
  Add = 'add',
  Delete = 'delete',
  Update = 'update'
}

export type ModifyObjectAutoConsentsInput = {
  action: ModifyAction;
  attributeId: Scalars['ID'];
  required?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addB2bUserToCompany: Scalars['Boolean'];
  addMicToGroup: Scalars['Boolean'];
  createB2bUser: CreateB2bUserResponse;
  createBaseObject: Scalars['ID'];
  createCompany: Scalars['ID'];
  createMicUser: CreateMicUserResponse;
  deleteObjectPicture: Scalars['Boolean'];
  modifyObjectAutoConsents: Scalars['Boolean'];
  modifyObjectPropertyValues: Scalars['Boolean'];
  multiple: Array<Mutation>;
  postChatMessage: ChatMessage;
  publishMessage: ChatMessage;
  putTenantSwipe: Scalars['Boolean'];
  readMessage: Scalars['Boolean'];
  rejectAccountDeletion?: Maybe<Scalars['Boolean']>;
  rejectObject: Scalars['Boolean'];
  removeMicFromGroup: Scalars['Boolean'];
  reorderObjectPictures: Scalars['Boolean'];
  toggleObject: Scalars['Boolean'];
  toggleUserVerifiedStatus: Scalars['Boolean'];
  updateInternalNote: Scalars['Boolean'];
  updateObjectLocation: Scalars['Boolean'];
  uploadObjectPicture: Scalars['String'];
  verifyObject: Scalars['Boolean'];
};


export type MutationAddB2bUserToCompanyArgs = {
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddMicToGroupArgs = {
  groupName: Scalars['String'];
  micUser: Scalars['ID'];
};


export type MutationCreateB2bUserArgs = {
  email: Scalars['String'];
};


export type MutationCreateBaseObjectArgs = {
  internalNote: Scalars['String'];
  landlord: Scalars['ID'];
  placeID: Scalars['String'];
  type: Scalars['ID'];
};


export type MutationCreateCompanyArgs = {
  formData: CreateCompanyInput;
};


export type MutationCreateMicUserArgs = {
  email: Scalars['String'];
  familyName: Scalars['String'];
  gender: Gender;
  givenName: Scalars['String'];
};


export type MutationDeleteObjectPictureArgs = {
  id: Scalars['ID'];
  objectId: Scalars['ID'];
};


export type MutationModifyObjectAutoConsentsArgs = {
  input: Array<ModifyObjectAutoConsentsInput>;
  objectId: Scalars['ID'];
};


export type MutationModifyObjectPropertyValuesArgs = {
  input: Array<PropertyInput>;
  objectId: Scalars['ID'];
};


export type MutationMultipleArgs = {
  count: Scalars['Int'];
};


export type MutationPostChatMessageArgs = {
  chatId: Scalars['ID'];
  companyId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationPublishMessageArgs = {
  chatId: Scalars['ID'];
  id: Scalars['ID'];
  message: Scalars['String'];
  senderId: Scalars['ID'];
  sentAt: Scalars['AWSDateTime'];
};


export type MutationPutTenantSwipeArgs = {
  objectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationReadMessageArgs = {
  messageId: Scalars['ID'];
  readBy: Scalars['ID'];
};


export type MutationRejectAccountDeletionArgs = {
  userId: Scalars['ID'];
};


export type MutationRejectObjectArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveMicFromGroupArgs = {
  groupName: Scalars['String'];
  micUser: Scalars['ID'];
};


export type MutationReorderObjectPicturesArgs = {
  objectId: Scalars['ID'];
  order: Array<Scalars['ID']>;
};


export type MutationToggleObjectArgs = {
  id: Scalars['ID'];
};


export type MutationToggleUserVerifiedStatusArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdateInternalNoteArgs = {
  id: Scalars['ID'];
  internalNote?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateObjectLocationArgs = {
  objectId: Scalars['ID'];
  placeID: Scalars['String'];
};


export type MutationUploadObjectPictureArgs = {
  objectId: Scalars['ID'];
};


export type MutationVerifyObjectArgs = {
  id: Scalars['ID'];
};

export type NamedLink = {
  __typename?: 'NamedLink';
  link: Scalars['AWSURL'];
  name: Scalars['String'];
};

export type Object = {
  __typename?: 'Object';
  active: Scalars['Boolean'];
  autoConsents: Array<AutoConsent>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Image>>>;
  internalNote?: Maybe<Scalars['String']>;
  landlord: Landlord;
  locality: Scalars['String'];
  location: ObjectLocation;
  publicId: Scalars['Int'];
  type: ObjectType;
  verified: Scalars['Boolean'];
};

export type ObjectLocation = {
  __typename?: 'ObjectLocation';
  address?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  placeId?: Maybe<Scalars['String']>;
  raw: Scalars['String'];
};

export type ObjectProperty = {
  __typename?: 'ObjectProperty';
  constraints?: Maybe<Scalars['AWSJSON']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type ObjectPropertyValue = {
  __typename?: 'ObjectPropertyValue';
  id: Scalars['ID'];
  property: ObjectProperty;
  updated_at?: Maybe<Scalars['AWSDateTime']>;
  value?: Maybe<Scalars['AWSJSON']>;
};

export type ObjectSwipesFilter = {
  matched?: InputMaybe<Scalars['Boolean']>;
  positive?: InputMaybe<Scalars['Boolean']>;
};

export type ObjectType = {
  __typename?: 'ObjectType';
  displayName: LocalizedString;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Objects = {
  __typename?: 'Objects';
  count: Count;
  data: Array<Object>;
};

export type ObjectsFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  landlordType?: InputMaybe<LandlordType>;
  search?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

export type Padding = {
  __typename?: 'Padding';
  bottom?: Maybe<Scalars['Float']>;
  left?: Maybe<Scalars['Float']>;
  right?: Maybe<Scalars['Float']>;
  top?: Maybe<Scalars['Float']>;
};

export type PropertyInput = {
  propertyId: Scalars['ID'];
  value?: InputMaybe<Scalars['AWSJSON']>;
};

export type Query = {
  __typename?: 'Query';
  asset: AdminAsset;
  assets: AdminAssets;
  b2bUsers: Array<B2bUser>;
  chat: RentalChat;
  chatMessages: Array<ChatMessage>;
  chats: Array<RentalChat>;
  companies: Array<Company>;
  company: Company;
  consentAttributes: Array<ConsentAttribute>;
  landlord: Landlord;
  landlords: Array<Landlord>;
  micGroups: Array<CognitoUserGroup>;
  micUser: User;
  micUsers: Array<MicUser>;
  multiple: Array<Query>;
  object: Object;
  objectProperties: Array<ObjectProperty>;
  objectSwipes: Array<Swipe>;
  objectTypes: Array<ObjectType>;
  objects: Objects;
  propertiesOfObject: Array<ObjectPropertyValue>;
  stats: Stats;
  tag: Tag;
  tags: Array<Tag>;
  user: User;
  users: Users;
};


export type QueryAssetArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryAssetsArgs = {
  filter?: InputMaybe<AssetFilter>;
  page?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryChatArgs = {
  id: Scalars['ID'];
};


export type QueryChatMessagesArgs = {
  chatId: Scalars['ID'];
  pageFrom?: InputMaybe<Scalars['ID']>;
};


export type QueryChatsArgs = {
  filter: ChatsFilter;
  page?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryLandlordArgs = {
  id: Scalars['ID'];
};


export type QueryLandlordsArgs = {
  landlordType?: InputMaybe<LandlordType>;
};


export type QueryMicUserArgs = {
  id: Scalars['ID'];
};


export type QueryMultipleArgs = {
  count: Scalars['Int'];
};


export type QueryObjectArgs = {
  id: Scalars['ID'];
};


export type QueryObjectSwipesArgs = {
  filter?: InputMaybe<ObjectSwipesFilter>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryObjectsArgs = {
  filter?: InputMaybe<ObjectsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPropertiesOfObjectArgs = {
  objectId: Scalars['ID'];
};


export type QueryTagArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryTagsArgs = {
  type: Scalars['String'];
};


export type QueryUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type QueryParameter = {
  __typename?: 'QueryParameter';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type RentalChat = ChatBase & {
  __typename?: 'RentalChat';
  id: Scalars['ID'];
  lastMessage?: Maybe<ChatMessage>;
  messageCount: Scalars['Int'];
  objectData: ChatObjectData;
  participants: Array<ChatParticipant>;
  unreadCount: Scalars['Int'];
};

export type Stats = {
  __typename?: 'Stats';
  matches: Scalars['Int'];
  objects: Scalars['Int'];
  swipes: Scalars['Int'];
  users: Scalars['Int'];
};

export type Subscription = {
  __typename?: 'Subscription';
  chatMessage?: Maybe<ChatMessage>;
};


export type SubscriptionChatMessageArgs = {
  chatId: Scalars['ID'];
};

export type Swipe = {
  __typename?: 'Swipe';
  id: Scalars['ID'];
  match?: Maybe<Match>;
  object: Object;
  positive: Scalars['Boolean'];
  swipedAt: Scalars['AWSDateTime'];
  user: User;
};

export type Tag = {
  __typename?: 'Tag';
  displayName: LocalizedString;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  type: Scalars['String'];
};

export enum TextStyle {
  Body1 = 'body1',
  Body2 = 'body2',
  Button = 'button',
  Content = 'content',
  Heading1 = 'heading1',
  Heading2 = 'heading2',
  Heading3 = 'heading3',
  Subtitle1 = 'subtitle1',
  Subtitle2 = 'subtitle2',
  Title = 'title'
}

export type UpdateUserLocalizedString = {
  locale: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateUserValue = {
  property: Scalars['String'];
  value: Scalars['AWSJSON'];
};

export type User = {
  __typename?: 'User';
  accountDeletionRequest?: Maybe<AccountDeletionRequest>;
  currentUserMode?: Maybe<UserMode>;
  documents: Array<UserDocument>;
  email?: Maybe<Scalars['AWSEmail']>;
  id: Scalars['ID'];
  identityProvider: IdentityProvider;
  landlordId?: Maybe<Scalars['ID']>;
  locale?: Maybe<Scalars['String']>;
  onboardingFinished: Scalars['Boolean'];
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  pictures: Array<Image>;
  signupDate: Scalars['AWSDateTime'];
  status?: Maybe<Scalars['String']>;
  values: Array<UserValue>;
  verified: Scalars['Boolean'];
  verifiedAttributes: UserVerifiedAttributes;
};


export type UserValuesArgs = {
  properties?: InputMaybe<Array<Scalars['String']>>;
  propertyIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  asset?: Maybe<Asset>;
  displayName: LocalizedString;
  id: Scalars['ID'];
};

export type UserFilter = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UserLandlord = {
  __typename?: 'UserLandlord';
  email: Scalars['AWSEmail'];
  id: Scalars['ID'];
};

export type UserLocalizedString = {
  __typename?: 'UserLocalizedString';
  localeString: LocaleUserLocalizedString;
  textId: Scalars['ID'];
};


export type UserLocalizedStringLocaleStringArgs = {
  locale: Scalars['String'];
};

export enum UserMode {
  Landlord = 'landlord',
  Tenant = 'tenant'
}

export type UserProperty = {
  __typename?: 'UserProperty';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UserValue = {
  __typename?: 'UserValue';
  id: Scalars['ID'];
  mutable: Scalars['Boolean'];
  property: UserProperty;
  source: UserValueSource;
  value: Scalars['AWSJSON'];
};

export enum UserValueSource {
  Admin = 'admin',
  Document = 'document',
  User = 'user',
  Verification = 'verification'
}

export type UserVerifiedAttributes = {
  __typename?: 'UserVerifiedAttributes';
  email: Scalars['Boolean'];
  phone: Scalars['Boolean'];
};

export type Users = {
  __typename?: 'Users';
  count: Count;
  data: Array<User>;
};

export type MicUser = CognitoUser & {
  __typename?: 'micUser';
  email?: Maybe<Scalars['AWSEmail']>;
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  identityProvider: IdentityProvider;
  signupDate: Scalars['AWSDateTime'];
};

export type AddB2bUserToCompanyMutationVariables = Exact<{
  companyId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type AddB2bUserToCompanyMutation = { __typename?: 'Mutation', addB2bUserToCompany: boolean };

export type CreateB2bUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CreateB2bUserMutation = { __typename?: 'Mutation', createB2bUser: { __typename?: 'CreateB2bUserResponse', id: string, email: string, password: string } };

export type B2bUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type B2bUsersQuery = { __typename?: 'Query', b2bUsers: Array<{ __typename?: 'B2bUser', id: string, email?: any | null | undefined }> };

export type PostChatMessageMutationVariables = Exact<{
  chatId: Scalars['ID'];
  message: Scalars['String'];
  companyId: Scalars['ID'];
}>;


export type PostChatMessageMutation = { __typename?: 'Mutation', postChatMessage: { __typename?: 'ChatMessage', simpleMessage: string, sentAt: any, senderId: string, id: string } };

export type ChatsQueryVariables = Exact<{
  filter: ChatsFilter;
}>;


export type ChatsQuery = { __typename?: 'Query', chats: Array<{ __typename?: 'RentalChat', id: string }> };

export type ChatQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ChatQuery = { __typename?: 'Query', chat: { __typename?: 'RentalChat', id: string, objectData: { __typename?: 'ChatObjectData', id: string }, participants: Array<{ __typename?: 'ChatParticipant', id: string, me: boolean, companyId?: string | null | undefined, userId?: string | null | undefined, type: ChatParticipantType, name: { __typename?: 'LocalizedString', text: string }, image?: { __typename?: 'Image', url: any } | null | undefined }>, lastMessage?: { __typename?: 'ChatMessage', id: string, simpleMessage: string, sentAt: any, senderId: string, readBy: Array<string | null | undefined> } | null | undefined } };

export type ChatMessagesQueryVariables = Exact<{
  chatId: Scalars['ID'];
}>;


export type ChatMessagesQuery = { __typename?: 'Query', chatMessages: Array<{ __typename?: 'ChatMessage', simpleMessage: string, id: string, readBy: Array<string | null | undefined>, senderId: string, sentAt: any }> };

export type ChatsFilterOptionsQueryVariables = Exact<{
  objectsFilter?: InputMaybe<ObjectsFilter>;
}>;


export type ChatsFilterOptionsQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Company', landlordId: string, name: string }>, objects: { __typename?: 'Objects', data: Array<{ __typename?: 'Object', id: string, internalNote?: string | null | undefined }> } };

export type ChatMessageSubscriptionVariables = Exact<{
  chatId: Scalars['ID'];
}>;


export type ChatMessageSubscription = { __typename?: 'Subscription', chatMessage?: { __typename?: 'ChatMessage', simpleMessage: string, id: string, readBy: Array<string | null | undefined>, senderId: string, sentAt: any } | null | undefined };

export type CreateCompanyMutationVariables = Exact<{
  data: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: string };

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = { __typename?: 'Query', companies: Array<{ __typename?: 'Company', id: string, landlordId: string, name: string }> };

export type CompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, landlordId: string, name: string, users: Array<{ __typename?: 'B2bUser', id: string, email?: any | null | undefined, signupDate: any }> } };

export type ConsentAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsentAttributesQuery = { __typename?: 'Query', consentAttributes: Array<{ __typename?: 'ConsentAttribute', id: string, type: AttributeType, displayName: { __typename?: 'LocalizedString', text: string } }> };

export type LandlordsQueryVariables = Exact<{
  type?: InputMaybe<LandlordType>;
}>;


export type LandlordsQuery = { __typename?: 'Query', landlords: Array<{ __typename?: 'Landlord', id: string, user?: { __typename?: 'UserLandlord', id: string, email: any } | null | undefined, company?: { __typename?: 'CompanyLandlord', id: string, name: string } | null | undefined }> };

export type ToggleObjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleObjectMutation = { __typename?: 'Mutation', toggleObject: boolean };

export type UpdateInternalNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  internalNote?: InputMaybe<Scalars['String']>;
}>;


export type UpdateInternalNoteMutation = { __typename?: 'Mutation', updateInternalNote: boolean };

export type ModifyObjectPropertyValuesMutationVariables = Exact<{
  objectId: Scalars['ID'];
  input: Array<PropertyInput> | PropertyInput;
}>;


export type ModifyObjectPropertyValuesMutation = { __typename?: 'Mutation', modifyObjectPropertyValues: boolean };

export type CreateBaseObjectMutationVariables = Exact<{
  internalNote: Scalars['String'];
  landlord: Scalars['ID'];
  placeID: Scalars['String'];
  type: Scalars['ID'];
}>;


export type CreateBaseObjectMutation = { __typename?: 'Mutation', createBaseObject: string };

export type UploadObjectPictureMutationVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type UploadObjectPictureMutation = { __typename?: 'Mutation', uploadObjectPicture: string };

export type MultipleUploadObjectPictureMutationVariables = Exact<{
  count: Scalars['Int'];
  objectId: Scalars['ID'];
}>;


export type MultipleUploadObjectPictureMutation = { __typename?: 'Mutation', multiple: Array<{ __typename?: 'Mutation', uploadObjectPicture: string }> };

export type DeleteObjectPictureMutationVariables = Exact<{
  objectId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type DeleteObjectPictureMutation = { __typename?: 'Mutation', deleteObjectPicture: boolean };

export type ReorderObjectPicturesMutationVariables = Exact<{
  objectId: Scalars['ID'];
  order: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ReorderObjectPicturesMutation = { __typename?: 'Mutation', reorderObjectPictures: boolean };

export type VerifyObjectMutationVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type VerifyObjectMutation = { __typename?: 'Mutation', verifyObject: boolean };

export type RejectObjectMutationVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type RejectObjectMutation = { __typename?: 'Mutation', rejectObject: boolean };

export type ModifyObjectAutoConsentsMutationVariables = Exact<{
  objectId: Scalars['ID'];
  input: Array<ModifyObjectAutoConsentsInput> | ModifyObjectAutoConsentsInput;
}>;


export type ModifyObjectAutoConsentsMutation = { __typename?: 'Mutation', modifyObjectAutoConsents: boolean };

export type UpdateObjectLocationMutationVariables = Exact<{
  objectId: Scalars['ID'];
  placeId: Scalars['String'];
}>;


export type UpdateObjectLocationMutation = { __typename?: 'Mutation', updateObjectLocation: boolean };

export type ObjectQueryVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type ObjectQuery = { __typename?: 'Query', object: { __typename?: 'Object', active: boolean, verified: boolean, id: string, publicId: number, createdAt: any, locality: string, internalNote?: string | null | undefined, landlord: { __typename?: 'Landlord', id: string, user?: { __typename?: 'UserLandlord', id: string, email: any } | null | undefined, company?: { __typename?: 'CompanyLandlord', id: string, name: string } | null | undefined }, images?: Array<{ __typename?: 'Image', id: string, url: any, largestUrl: any } | null | undefined> | null | undefined, location: { __typename?: 'ObjectLocation', placeId?: string | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, address?: string | null | undefined, raw: string }, autoConsents: Array<{ __typename?: 'AutoConsent', required: boolean, attribute: { __typename?: 'ConsentAttribute', id: string, type: AttributeType, displayName: { __typename?: 'LocalizedString', text: string, key: string } } }> } };

export type ObjectsQueryVariables = Exact<{
  filter?: InputMaybe<ObjectsFilter>;
  take?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type ObjectsQuery = { __typename?: 'Query', objects: { __typename?: 'Objects', data: Array<{ __typename?: 'Object', id: string, publicId: number, active: boolean, verified: boolean, createdAt: any, locality: string, internalNote?: string | null | undefined, landlord: { __typename?: 'Landlord', id: string, user?: { __typename?: 'UserLandlord', id: string, email: any } | null | undefined, company?: { __typename?: 'CompanyLandlord', id: string, name: string } | null | undefined }, images?: Array<{ __typename?: 'Image', id: string, url: any } | null | undefined> | null | undefined, location: { __typename?: 'ObjectLocation', placeId?: string | null | undefined, latitude?: number | null | undefined, longitude?: number | null | undefined, address?: string | null | undefined, raw: string } }>, count: { __typename?: 'Count', totalCount: number, pages: number, itemsPerPage: number } } };

export type ObjectPropertiesQueryVariables = Exact<{ [key: string]: never; }>;


export type ObjectPropertiesQuery = { __typename?: 'Query', objectProperties: Array<{ __typename?: 'ObjectProperty', id: string, type: string, constraints?: any | null | undefined, name: string }> };

export type PropertiesOfObjectQueryVariables = Exact<{
  objectId: Scalars['ID'];
}>;


export type PropertiesOfObjectQuery = { __typename?: 'Query', propertiesOfObject: Array<{ __typename?: 'ObjectPropertyValue', id: string, value?: any | null | undefined, updated_at?: any | null | undefined, property: { __typename?: 'ObjectProperty', id: string, type: string, constraints?: any | null | undefined, name: string } }> };

export type TagsQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type TagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: string, type: string, name: string, order: number, displayName: { __typename?: 'LocalizedString', key: string, text: string } }> };

export type TagQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type TagQuery = { __typename?: 'Query', tag: { __typename?: 'Tag', id: string, type: string, name: string, order: number, displayName: { __typename?: 'LocalizedString', key: string, text: string } } };

export type ObjectTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ObjectTypesQuery = { __typename?: 'Query', objectTypes: Array<{ __typename?: 'ObjectType', id: string, displayName: { __typename?: 'LocalizedString', text: string } }> };

export type ObjectCreationMetaDataQueryVariables = Exact<{
  landlordType?: InputMaybe<LandlordType>;
}>;


export type ObjectCreationMetaDataQuery = { __typename?: 'Query', objectTypes: Array<{ __typename?: 'ObjectType', id: string, displayName: { __typename?: 'LocalizedString', text: string } }>, landlords: Array<{ __typename?: 'Landlord', id: string, company?: { __typename?: 'CompanyLandlord', name: string } | null | undefined, user?: { __typename?: 'UserLandlord', email: any } | null | undefined }> };

export type StatsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsQuery = { __typename?: 'Query', stats: { __typename?: 'Stats', matches: number, swipes: number, objects: number, users: number } };

export type PutTenantSwipeMutationVariables = Exact<{
  objectId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type PutTenantSwipeMutation = { __typename?: 'Mutation', putTenantSwipe: boolean };

export type ObjectSwipesQueryVariables = Exact<{
  objectId: Scalars['ID'];
  filter?: InputMaybe<ObjectSwipesFilter>;
}>;


export type ObjectSwipesQuery = { __typename?: 'Query', objectSwipes: Array<{ __typename?: 'Swipe', id: string, positive: boolean, swipedAt: any, user: { __typename?: 'User', id: string, email?: any | null | undefined, pictures: Array<{ __typename?: 'Image', id: string, url: any }>, values: Array<{ __typename?: 'UserValue', value: any, mutable: boolean, property: { __typename?: 'UserProperty', name: string } }> }, match?: { __typename?: 'Match', id: string, chatId: string, matchedAt: any } | null | undefined }> };

export type RejectAccountDeletionMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type RejectAccountDeletionMutation = { __typename?: 'Mutation', rejectAccountDeletion?: boolean | null | undefined };

export type ToggleUserVerifiedStatusMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type ToggleUserVerifiedStatusMutation = { __typename?: 'Mutation', toggleUserVerifiedStatus: boolean };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  take?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'Users', data: Array<{ __typename?: 'User', email?: any | null | undefined, id: string, landlordId?: string | null | undefined, currentUserMode?: UserMode | null | undefined, onboardingFinished: boolean, locale?: string | null | undefined, identityProvider: IdentityProvider, signupDate: any, verified: boolean, cognitoStatus?: string | null | undefined, pictures: Array<{ __typename?: 'Image', id: string, url: any }>, values: Array<{ __typename?: 'UserValue', value: any, property: { __typename?: 'UserProperty', name: string } }>, accountDeletionRequest?: { __typename?: 'AccountDeletionRequest', nameOfDeletionJob: string, timeOfDeletion: any } | null | undefined }>, count: { __typename?: 'Count', totalCount: number, pages: number, itemsPerPage: number } } };

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email?: any | null | undefined, identityProvider: IdentityProvider, phoneNumber?: any | null | undefined, landlordId?: string | null | undefined, currentUserMode?: UserMode | null | undefined, onboardingFinished: boolean, locale?: string | null | undefined, signupDate: any, verified: boolean, cognitoStatus?: string | null | undefined, pictures: Array<{ __typename?: 'Image', id: string, url: any, largestUrl: any }>, documents: Array<{ __typename?: 'UserDocument', asset?: { __typename?: 'Asset', url: any } | null | undefined, displayName: { __typename?: 'LocalizedString', text: string } }>, values: Array<{ __typename?: 'UserValue', value: any, mutable: boolean, property: { __typename?: 'UserProperty', name: string } }>, accountDeletionRequest?: { __typename?: 'AccountDeletionRequest', timeOfDeletion: any, nameOfDeletionJob: string } | null | undefined } };


export const AddB2bUserToCompanyDocumentNode = gql`
    mutation addB2bUserToCompany($companyId: ID!, $userId: ID!) {
  addB2bUserToCompany(companyId: $companyId, userId: $userId)
}
    `;
export type AddB2bUserToCompanyMutationFn = Apollo.MutationFunction<AddB2bUserToCompanyMutation, AddB2bUserToCompanyMutationVariables>;

/**
 * __useAddB2bUserToCompanyMutation__
 *
 * To run a mutation, you first call `useAddB2bUserToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddB2bUserToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addB2bUserToCompanyMutation, { data, loading, error }] = useAddB2bUserToCompanyMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddB2bUserToCompanyMutation(baseOptions?: Apollo.MutationHookOptions<AddB2bUserToCompanyMutation, AddB2bUserToCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddB2bUserToCompanyMutation, AddB2bUserToCompanyMutationVariables>(AddB2bUserToCompanyDocumentNode, options);
      }
export type AddB2bUserToCompanyMutationHookResult = ReturnType<typeof useAddB2bUserToCompanyMutation>;
export type AddB2bUserToCompanyMutationResult = Apollo.MutationResult<AddB2bUserToCompanyMutation>;
export type AddB2bUserToCompanyMutationOptions = Apollo.BaseMutationOptions<AddB2bUserToCompanyMutation, AddB2bUserToCompanyMutationVariables>;
export const CreateB2bUserDocumentNode = gql`
    mutation createB2bUser($email: String!) {
  createB2bUser(email: $email) {
    id
    email
    password
  }
}
    `;
export type CreateB2bUserMutationFn = Apollo.MutationFunction<CreateB2bUserMutation, CreateB2bUserMutationVariables>;

/**
 * __useCreateB2bUserMutation__
 *
 * To run a mutation, you first call `useCreateB2bUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateB2bUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createB2bUserMutation, { data, loading, error }] = useCreateB2bUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateB2bUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateB2bUserMutation, CreateB2bUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateB2bUserMutation, CreateB2bUserMutationVariables>(CreateB2bUserDocumentNode, options);
      }
export type CreateB2bUserMutationHookResult = ReturnType<typeof useCreateB2bUserMutation>;
export type CreateB2bUserMutationResult = Apollo.MutationResult<CreateB2bUserMutation>;
export type CreateB2bUserMutationOptions = Apollo.BaseMutationOptions<CreateB2bUserMutation, CreateB2bUserMutationVariables>;
export const B2bUsersDocumentNode = gql`
    query b2bUsers {
  b2bUsers {
    id
    email
  }
}
    `;

/**
 * __useB2bUsersQuery__
 *
 * To run a query within a React component, call `useB2bUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useB2bUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useB2bUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useB2bUsersQuery(baseOptions?: Apollo.QueryHookOptions<B2bUsersQuery, B2bUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<B2bUsersQuery, B2bUsersQueryVariables>(B2bUsersDocumentNode, options);
      }
export function useB2bUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<B2bUsersQuery, B2bUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<B2bUsersQuery, B2bUsersQueryVariables>(B2bUsersDocumentNode, options);
        }
export type B2bUsersQueryHookResult = ReturnType<typeof useB2bUsersQuery>;
export type B2bUsersLazyQueryHookResult = ReturnType<typeof useB2bUsersLazyQuery>;
export type B2bUsersQueryResult = Apollo.QueryResult<B2bUsersQuery, B2bUsersQueryVariables>;
export const PostChatMessageDocumentNode = gql`
    mutation postChatMessage($chatId: ID!, $message: String!, $companyId: ID!) {
  postChatMessage(chatId: $chatId, message: $message, companyId: $companyId) {
    simpleMessage
    sentAt
    senderId
    id
  }
}
    `;
export type PostChatMessageMutationFn = Apollo.MutationFunction<PostChatMessageMutation, PostChatMessageMutationVariables>;

/**
 * __usePostChatMessageMutation__
 *
 * To run a mutation, you first call `usePostChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postChatMessageMutation, { data, loading, error }] = usePostChatMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      message: // value for 'message'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePostChatMessageMutation(baseOptions?: Apollo.MutationHookOptions<PostChatMessageMutation, PostChatMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostChatMessageMutation, PostChatMessageMutationVariables>(PostChatMessageDocumentNode, options);
      }
export type PostChatMessageMutationHookResult = ReturnType<typeof usePostChatMessageMutation>;
export type PostChatMessageMutationResult = Apollo.MutationResult<PostChatMessageMutation>;
export type PostChatMessageMutationOptions = Apollo.BaseMutationOptions<PostChatMessageMutation, PostChatMessageMutationVariables>;
export const ChatsDocumentNode = gql`
    query chats($filter: ChatsFilter!) {
  chats(filter: $filter) {
    id
  }
}
    `;

/**
 * __useChatsQuery__
 *
 * To run a query within a React component, call `useChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChatsQuery(baseOptions: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocumentNode, options);
      }
export function useChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(ChatsDocumentNode, options);
        }
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsLazyQueryHookResult = ReturnType<typeof useChatsLazyQuery>;
export type ChatsQueryResult = Apollo.QueryResult<ChatsQuery, ChatsQueryVariables>;
export const ChatDocumentNode = gql`
    query chat($id: ID!) {
  chat(id: $id) {
    id
    participants {
      id
      me
      name {
        text(locale: "en-US")
      }
      image {
        url(size: small)
      }
      companyId
      userId
      type
    }
    lastMessage {
      id
      simpleMessage
      sentAt
      senderId
      readBy
    }
    ... on RentalChat {
      objectData {
        id
      }
    }
  }
}
    `;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatQuery(baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocumentNode, options);
      }
export function useChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(ChatDocumentNode, options);
        }
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const ChatMessagesDocumentNode = gql`
    query chatMessages($chatId: ID!) {
  chatMessages(chatId: $chatId) {
    simpleMessage
    id
    readBy
    senderId
    sentAt
  }
}
    `;

/**
 * __useChatMessagesQuery__
 *
 * To run a query within a React component, call `useChatMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessagesQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useChatMessagesQuery(baseOptions: Apollo.QueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocumentNode, options);
      }
export function useChatMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatMessagesQuery, ChatMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatMessagesQuery, ChatMessagesQueryVariables>(ChatMessagesDocumentNode, options);
        }
export type ChatMessagesQueryHookResult = ReturnType<typeof useChatMessagesQuery>;
export type ChatMessagesLazyQueryHookResult = ReturnType<typeof useChatMessagesLazyQuery>;
export type ChatMessagesQueryResult = Apollo.QueryResult<ChatMessagesQuery, ChatMessagesQueryVariables>;
export const ChatsFilterOptionsDocumentNode = gql`
    query chatsFilterOptions($objectsFilter: ObjectsFilter) {
  companies {
    landlordId
    name
  }
  objects(filter: $objectsFilter, take: 200) {
    data {
      id
      internalNote
    }
  }
}
    `;

/**
 * __useChatsFilterOptionsQuery__
 *
 * To run a query within a React component, call `useChatsFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsFilterOptionsQuery({
 *   variables: {
 *      objectsFilter: // value for 'objectsFilter'
 *   },
 * });
 */
export function useChatsFilterOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ChatsFilterOptionsQuery, ChatsFilterOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatsFilterOptionsQuery, ChatsFilterOptionsQueryVariables>(ChatsFilterOptionsDocumentNode, options);
      }
export function useChatsFilterOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatsFilterOptionsQuery, ChatsFilterOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatsFilterOptionsQuery, ChatsFilterOptionsQueryVariables>(ChatsFilterOptionsDocumentNode, options);
        }
export type ChatsFilterOptionsQueryHookResult = ReturnType<typeof useChatsFilterOptionsQuery>;
export type ChatsFilterOptionsLazyQueryHookResult = ReturnType<typeof useChatsFilterOptionsLazyQuery>;
export type ChatsFilterOptionsQueryResult = Apollo.QueryResult<ChatsFilterOptionsQuery, ChatsFilterOptionsQueryVariables>;
export const ChatMessageDocumentNode = gql`
    subscription chatMessage($chatId: ID!) {
  chatMessage(chatId: $chatId) {
    simpleMessage
    id
    readBy
    senderId
    sentAt
  }
}
    `;

/**
 * __useChatMessageSubscription__
 *
 * To run a query within a React component, call `useChatMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatMessageSubscription({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useChatMessageSubscription(baseOptions: Apollo.SubscriptionHookOptions<ChatMessageSubscription, ChatMessageSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatMessageSubscription, ChatMessageSubscriptionVariables>(ChatMessageDocumentNode, options);
      }
export type ChatMessageSubscriptionHookResult = ReturnType<typeof useChatMessageSubscription>;
export type ChatMessageSubscriptionResult = Apollo.SubscriptionResult<ChatMessageSubscription>;
export const CreateCompanyDocumentNode = gql`
    mutation createCompany($data: CreateCompanyInput!) {
  createCompany(formData: $data)
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocumentNode, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CompaniesDocumentNode = gql`
    query companies {
  companies {
    id
    landlordId
    name
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocumentNode, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocumentNode, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocumentNode = gql`
    query company($id: ID!) {
  company(id: $id) {
    id
    landlordId
    name
    users {
      id
      email
      signupDate
    }
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocumentNode, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocumentNode, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const ConsentAttributesDocumentNode = gql`
    query consentAttributes {
  consentAttributes {
    id
    type
    displayName {
      text(locale: "en-US")
    }
  }
}
    `;

/**
 * __useConsentAttributesQuery__
 *
 * To run a query within a React component, call `useConsentAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsentAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsentAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useConsentAttributesQuery(baseOptions?: Apollo.QueryHookOptions<ConsentAttributesQuery, ConsentAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConsentAttributesQuery, ConsentAttributesQueryVariables>(ConsentAttributesDocumentNode, options);
      }
export function useConsentAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsentAttributesQuery, ConsentAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConsentAttributesQuery, ConsentAttributesQueryVariables>(ConsentAttributesDocumentNode, options);
        }
export type ConsentAttributesQueryHookResult = ReturnType<typeof useConsentAttributesQuery>;
export type ConsentAttributesLazyQueryHookResult = ReturnType<typeof useConsentAttributesLazyQuery>;
export type ConsentAttributesQueryResult = Apollo.QueryResult<ConsentAttributesQuery, ConsentAttributesQueryVariables>;
export const LandlordsDocumentNode = gql`
    query landlords($type: LandlordType) {
  landlords(landlordType: $type) {
    id
    user {
      id
      email
    }
    company {
      id
      name
    }
  }
}
    `;

/**
 * __useLandlordsQuery__
 *
 * To run a query within a React component, call `useLandlordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLandlordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLandlordsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useLandlordsQuery(baseOptions?: Apollo.QueryHookOptions<LandlordsQuery, LandlordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LandlordsQuery, LandlordsQueryVariables>(LandlordsDocumentNode, options);
      }
export function useLandlordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LandlordsQuery, LandlordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LandlordsQuery, LandlordsQueryVariables>(LandlordsDocumentNode, options);
        }
export type LandlordsQueryHookResult = ReturnType<typeof useLandlordsQuery>;
export type LandlordsLazyQueryHookResult = ReturnType<typeof useLandlordsLazyQuery>;
export type LandlordsQueryResult = Apollo.QueryResult<LandlordsQuery, LandlordsQueryVariables>;
export const ToggleObjectDocumentNode = gql`
    mutation toggleObject($id: ID!) {
  toggleObject(id: $id)
}
    `;
export type ToggleObjectMutationFn = Apollo.MutationFunction<ToggleObjectMutation, ToggleObjectMutationVariables>;

/**
 * __useToggleObjectMutation__
 *
 * To run a mutation, you first call `useToggleObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleObjectMutation, { data, loading, error }] = useToggleObjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleObjectMutation(baseOptions?: Apollo.MutationHookOptions<ToggleObjectMutation, ToggleObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleObjectMutation, ToggleObjectMutationVariables>(ToggleObjectDocumentNode, options);
      }
export type ToggleObjectMutationHookResult = ReturnType<typeof useToggleObjectMutation>;
export type ToggleObjectMutationResult = Apollo.MutationResult<ToggleObjectMutation>;
export type ToggleObjectMutationOptions = Apollo.BaseMutationOptions<ToggleObjectMutation, ToggleObjectMutationVariables>;
export const UpdateInternalNoteDocumentNode = gql`
    mutation updateInternalNote($id: ID!, $internalNote: String) {
  updateInternalNote(internalNote: $internalNote, id: $id)
}
    `;
export type UpdateInternalNoteMutationFn = Apollo.MutationFunction<UpdateInternalNoteMutation, UpdateInternalNoteMutationVariables>;

/**
 * __useUpdateInternalNoteMutation__
 *
 * To run a mutation, you first call `useUpdateInternalNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInternalNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInternalNoteMutation, { data, loading, error }] = useUpdateInternalNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internalNote: // value for 'internalNote'
 *   },
 * });
 */
export function useUpdateInternalNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInternalNoteMutation, UpdateInternalNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInternalNoteMutation, UpdateInternalNoteMutationVariables>(UpdateInternalNoteDocumentNode, options);
      }
export type UpdateInternalNoteMutationHookResult = ReturnType<typeof useUpdateInternalNoteMutation>;
export type UpdateInternalNoteMutationResult = Apollo.MutationResult<UpdateInternalNoteMutation>;
export type UpdateInternalNoteMutationOptions = Apollo.BaseMutationOptions<UpdateInternalNoteMutation, UpdateInternalNoteMutationVariables>;
export const ModifyObjectPropertyValuesDocumentNode = gql`
    mutation modifyObjectPropertyValues($objectId: ID!, $input: [PropertyInput!]!) {
  modifyObjectPropertyValues(objectId: $objectId, input: $input)
}
    `;
export type ModifyObjectPropertyValuesMutationFn = Apollo.MutationFunction<ModifyObjectPropertyValuesMutation, ModifyObjectPropertyValuesMutationVariables>;

/**
 * __useModifyObjectPropertyValuesMutation__
 *
 * To run a mutation, you first call `useModifyObjectPropertyValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyObjectPropertyValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyObjectPropertyValuesMutation, { data, loading, error }] = useModifyObjectPropertyValuesMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyObjectPropertyValuesMutation(baseOptions?: Apollo.MutationHookOptions<ModifyObjectPropertyValuesMutation, ModifyObjectPropertyValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyObjectPropertyValuesMutation, ModifyObjectPropertyValuesMutationVariables>(ModifyObjectPropertyValuesDocumentNode, options);
      }
export type ModifyObjectPropertyValuesMutationHookResult = ReturnType<typeof useModifyObjectPropertyValuesMutation>;
export type ModifyObjectPropertyValuesMutationResult = Apollo.MutationResult<ModifyObjectPropertyValuesMutation>;
export type ModifyObjectPropertyValuesMutationOptions = Apollo.BaseMutationOptions<ModifyObjectPropertyValuesMutation, ModifyObjectPropertyValuesMutationVariables>;
export const CreateBaseObjectDocumentNode = gql`
    mutation createBaseObject($internalNote: String!, $landlord: ID!, $placeID: String!, $type: ID!) {
  createBaseObject(
    internalNote: $internalNote
    landlord: $landlord
    placeID: $placeID
    type: $type
  )
}
    `;
export type CreateBaseObjectMutationFn = Apollo.MutationFunction<CreateBaseObjectMutation, CreateBaseObjectMutationVariables>;

/**
 * __useCreateBaseObjectMutation__
 *
 * To run a mutation, you first call `useCreateBaseObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBaseObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBaseObjectMutation, { data, loading, error }] = useCreateBaseObjectMutation({
 *   variables: {
 *      internalNote: // value for 'internalNote'
 *      landlord: // value for 'landlord'
 *      placeID: // value for 'placeID'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateBaseObjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateBaseObjectMutation, CreateBaseObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBaseObjectMutation, CreateBaseObjectMutationVariables>(CreateBaseObjectDocumentNode, options);
      }
export type CreateBaseObjectMutationHookResult = ReturnType<typeof useCreateBaseObjectMutation>;
export type CreateBaseObjectMutationResult = Apollo.MutationResult<CreateBaseObjectMutation>;
export type CreateBaseObjectMutationOptions = Apollo.BaseMutationOptions<CreateBaseObjectMutation, CreateBaseObjectMutationVariables>;
export const UploadObjectPictureDocumentNode = gql`
    mutation uploadObjectPicture($objectId: ID!) {
  uploadObjectPicture(objectId: $objectId)
}
    `;
export type UploadObjectPictureMutationFn = Apollo.MutationFunction<UploadObjectPictureMutation, UploadObjectPictureMutationVariables>;

/**
 * __useUploadObjectPictureMutation__
 *
 * To run a mutation, you first call `useUploadObjectPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadObjectPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadObjectPictureMutation, { data, loading, error }] = useUploadObjectPictureMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useUploadObjectPictureMutation(baseOptions?: Apollo.MutationHookOptions<UploadObjectPictureMutation, UploadObjectPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadObjectPictureMutation, UploadObjectPictureMutationVariables>(UploadObjectPictureDocumentNode, options);
      }
export type UploadObjectPictureMutationHookResult = ReturnType<typeof useUploadObjectPictureMutation>;
export type UploadObjectPictureMutationResult = Apollo.MutationResult<UploadObjectPictureMutation>;
export type UploadObjectPictureMutationOptions = Apollo.BaseMutationOptions<UploadObjectPictureMutation, UploadObjectPictureMutationVariables>;
export const MultipleUploadObjectPictureDocumentNode = gql`
    mutation multipleUploadObjectPicture($count: Int!, $objectId: ID!) {
  multiple(count: $count) {
    uploadObjectPicture(objectId: $objectId)
  }
}
    `;
export type MultipleUploadObjectPictureMutationFn = Apollo.MutationFunction<MultipleUploadObjectPictureMutation, MultipleUploadObjectPictureMutationVariables>;

/**
 * __useMultipleUploadObjectPictureMutation__
 *
 * To run a mutation, you first call `useMultipleUploadObjectPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultipleUploadObjectPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multipleUploadObjectPictureMutation, { data, loading, error }] = useMultipleUploadObjectPictureMutation({
 *   variables: {
 *      count: // value for 'count'
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useMultipleUploadObjectPictureMutation(baseOptions?: Apollo.MutationHookOptions<MultipleUploadObjectPictureMutation, MultipleUploadObjectPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultipleUploadObjectPictureMutation, MultipleUploadObjectPictureMutationVariables>(MultipleUploadObjectPictureDocumentNode, options);
      }
export type MultipleUploadObjectPictureMutationHookResult = ReturnType<typeof useMultipleUploadObjectPictureMutation>;
export type MultipleUploadObjectPictureMutationResult = Apollo.MutationResult<MultipleUploadObjectPictureMutation>;
export type MultipleUploadObjectPictureMutationOptions = Apollo.BaseMutationOptions<MultipleUploadObjectPictureMutation, MultipleUploadObjectPictureMutationVariables>;
export const DeleteObjectPictureDocumentNode = gql`
    mutation deleteObjectPicture($objectId: ID!, $id: ID!) {
  deleteObjectPicture(objectId: $objectId, id: $id)
}
    `;
export type DeleteObjectPictureMutationFn = Apollo.MutationFunction<DeleteObjectPictureMutation, DeleteObjectPictureMutationVariables>;

/**
 * __useDeleteObjectPictureMutation__
 *
 * To run a mutation, you first call `useDeleteObjectPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectPictureMutation, { data, loading, error }] = useDeleteObjectPictureMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteObjectPictureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteObjectPictureMutation, DeleteObjectPictureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteObjectPictureMutation, DeleteObjectPictureMutationVariables>(DeleteObjectPictureDocumentNode, options);
      }
export type DeleteObjectPictureMutationHookResult = ReturnType<typeof useDeleteObjectPictureMutation>;
export type DeleteObjectPictureMutationResult = Apollo.MutationResult<DeleteObjectPictureMutation>;
export type DeleteObjectPictureMutationOptions = Apollo.BaseMutationOptions<DeleteObjectPictureMutation, DeleteObjectPictureMutationVariables>;
export const ReorderObjectPicturesDocumentNode = gql`
    mutation reorderObjectPictures($objectId: ID!, $order: [ID!]!) {
  reorderObjectPictures(objectId: $objectId, order: $order)
}
    `;
export type ReorderObjectPicturesMutationFn = Apollo.MutationFunction<ReorderObjectPicturesMutation, ReorderObjectPicturesMutationVariables>;

/**
 * __useReorderObjectPicturesMutation__
 *
 * To run a mutation, you first call `useReorderObjectPicturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderObjectPicturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderObjectPicturesMutation, { data, loading, error }] = useReorderObjectPicturesMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useReorderObjectPicturesMutation(baseOptions?: Apollo.MutationHookOptions<ReorderObjectPicturesMutation, ReorderObjectPicturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReorderObjectPicturesMutation, ReorderObjectPicturesMutationVariables>(ReorderObjectPicturesDocumentNode, options);
      }
export type ReorderObjectPicturesMutationHookResult = ReturnType<typeof useReorderObjectPicturesMutation>;
export type ReorderObjectPicturesMutationResult = Apollo.MutationResult<ReorderObjectPicturesMutation>;
export type ReorderObjectPicturesMutationOptions = Apollo.BaseMutationOptions<ReorderObjectPicturesMutation, ReorderObjectPicturesMutationVariables>;
export const VerifyObjectDocumentNode = gql`
    mutation verifyObject($objectId: ID!) {
  verifyObject(id: $objectId)
}
    `;
export type VerifyObjectMutationFn = Apollo.MutationFunction<VerifyObjectMutation, VerifyObjectMutationVariables>;

/**
 * __useVerifyObjectMutation__
 *
 * To run a mutation, you first call `useVerifyObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyObjectMutation, { data, loading, error }] = useVerifyObjectMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useVerifyObjectMutation(baseOptions?: Apollo.MutationHookOptions<VerifyObjectMutation, VerifyObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyObjectMutation, VerifyObjectMutationVariables>(VerifyObjectDocumentNode, options);
      }
export type VerifyObjectMutationHookResult = ReturnType<typeof useVerifyObjectMutation>;
export type VerifyObjectMutationResult = Apollo.MutationResult<VerifyObjectMutation>;
export type VerifyObjectMutationOptions = Apollo.BaseMutationOptions<VerifyObjectMutation, VerifyObjectMutationVariables>;
export const RejectObjectDocumentNode = gql`
    mutation rejectObject($objectId: ID!) {
  rejectObject(id: $objectId)
}
    `;
export type RejectObjectMutationFn = Apollo.MutationFunction<RejectObjectMutation, RejectObjectMutationVariables>;

/**
 * __useRejectObjectMutation__
 *
 * To run a mutation, you first call `useRejectObjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectObjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectObjectMutation, { data, loading, error }] = useRejectObjectMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useRejectObjectMutation(baseOptions?: Apollo.MutationHookOptions<RejectObjectMutation, RejectObjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectObjectMutation, RejectObjectMutationVariables>(RejectObjectDocumentNode, options);
      }
export type RejectObjectMutationHookResult = ReturnType<typeof useRejectObjectMutation>;
export type RejectObjectMutationResult = Apollo.MutationResult<RejectObjectMutation>;
export type RejectObjectMutationOptions = Apollo.BaseMutationOptions<RejectObjectMutation, RejectObjectMutationVariables>;
export const ModifyObjectAutoConsentsDocumentNode = gql`
    mutation modifyObjectAutoConsents($objectId: ID!, $input: [ModifyObjectAutoConsentsInput!]!) {
  modifyObjectAutoConsents(objectId: $objectId, input: $input)
}
    `;
export type ModifyObjectAutoConsentsMutationFn = Apollo.MutationFunction<ModifyObjectAutoConsentsMutation, ModifyObjectAutoConsentsMutationVariables>;

/**
 * __useModifyObjectAutoConsentsMutation__
 *
 * To run a mutation, you first call `useModifyObjectAutoConsentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyObjectAutoConsentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyObjectAutoConsentsMutation, { data, loading, error }] = useModifyObjectAutoConsentsMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModifyObjectAutoConsentsMutation(baseOptions?: Apollo.MutationHookOptions<ModifyObjectAutoConsentsMutation, ModifyObjectAutoConsentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyObjectAutoConsentsMutation, ModifyObjectAutoConsentsMutationVariables>(ModifyObjectAutoConsentsDocumentNode, options);
      }
export type ModifyObjectAutoConsentsMutationHookResult = ReturnType<typeof useModifyObjectAutoConsentsMutation>;
export type ModifyObjectAutoConsentsMutationResult = Apollo.MutationResult<ModifyObjectAutoConsentsMutation>;
export type ModifyObjectAutoConsentsMutationOptions = Apollo.BaseMutationOptions<ModifyObjectAutoConsentsMutation, ModifyObjectAutoConsentsMutationVariables>;
export const UpdateObjectLocationDocumentNode = gql`
    mutation updateObjectLocation($objectId: ID!, $placeId: String!) {
  updateObjectLocation(objectId: $objectId, placeID: $placeId)
}
    `;
export type UpdateObjectLocationMutationFn = Apollo.MutationFunction<UpdateObjectLocationMutation, UpdateObjectLocationMutationVariables>;

/**
 * __useUpdateObjectLocationMutation__
 *
 * To run a mutation, you first call `useUpdateObjectLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectLocationMutation, { data, loading, error }] = useUpdateObjectLocationMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      placeId: // value for 'placeId'
 *   },
 * });
 */
export function useUpdateObjectLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateObjectLocationMutation, UpdateObjectLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateObjectLocationMutation, UpdateObjectLocationMutationVariables>(UpdateObjectLocationDocumentNode, options);
      }
export type UpdateObjectLocationMutationHookResult = ReturnType<typeof useUpdateObjectLocationMutation>;
export type UpdateObjectLocationMutationResult = Apollo.MutationResult<UpdateObjectLocationMutation>;
export type UpdateObjectLocationMutationOptions = Apollo.BaseMutationOptions<UpdateObjectLocationMutation, UpdateObjectLocationMutationVariables>;
export const ObjectDocumentNode = gql`
    query object($objectId: ID!) {
  object(id: $objectId) {
    active
    verified
    id
    publicId
    createdAt
    landlord {
      id
      user {
        id
        email
      }
      company {
        id
        name
      }
    }
    locality
    internalNote
    images {
      id
      url(size: small)
      largestUrl: url(size: large)
    }
    location {
      placeId
      latitude
      longitude
      address
      raw
    }
    autoConsents {
      required
      attribute {
        displayName {
          text(locale: "en-US")
          key
        }
        id
        type
      }
    }
  }
}
    `;

/**
 * __useObjectQuery__
 *
 * To run a query within a React component, call `useObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useObjectQuery(baseOptions: Apollo.QueryHookOptions<ObjectQuery, ObjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectQuery, ObjectQueryVariables>(ObjectDocumentNode, options);
      }
export function useObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectQuery, ObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectQuery, ObjectQueryVariables>(ObjectDocumentNode, options);
        }
export type ObjectQueryHookResult = ReturnType<typeof useObjectQuery>;
export type ObjectLazyQueryHookResult = ReturnType<typeof useObjectLazyQuery>;
export type ObjectQueryResult = Apollo.QueryResult<ObjectQuery, ObjectQueryVariables>;
export const ObjectsDocumentNode = gql`
    query objects($filter: ObjectsFilter, $take: Int, $page: Int) {
  objects(filter: $filter, take: $take, page: $page) {
    data {
      id
      publicId
      active
      verified
      createdAt
      landlord {
        id
        user {
          id
          email
        }
        company {
          id
          name
        }
      }
      locality
      internalNote
      images {
        id
        url(size: small)
      }
      location {
        placeId
        latitude
        longitude
        address
        raw
      }
    }
    count {
      totalCount
      pages
      itemsPerPage
    }
  }
}
    `;

/**
 * __useObjectsQuery__
 *
 * To run a query within a React component, call `useObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useObjectsQuery(baseOptions?: Apollo.QueryHookOptions<ObjectsQuery, ObjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectsQuery, ObjectsQueryVariables>(ObjectsDocumentNode, options);
      }
export function useObjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectsQuery, ObjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectsQuery, ObjectsQueryVariables>(ObjectsDocumentNode, options);
        }
export type ObjectsQueryHookResult = ReturnType<typeof useObjectsQuery>;
export type ObjectsLazyQueryHookResult = ReturnType<typeof useObjectsLazyQuery>;
export type ObjectsQueryResult = Apollo.QueryResult<ObjectsQuery, ObjectsQueryVariables>;
export const ObjectPropertiesDocumentNode = gql`
    query objectProperties {
  objectProperties {
    id
    type
    constraints
    name
  }
}
    `;

/**
 * __useObjectPropertiesQuery__
 *
 * To run a query within a React component, call `useObjectPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectPropertiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectPropertiesQuery(baseOptions?: Apollo.QueryHookOptions<ObjectPropertiesQuery, ObjectPropertiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectPropertiesQuery, ObjectPropertiesQueryVariables>(ObjectPropertiesDocumentNode, options);
      }
export function useObjectPropertiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectPropertiesQuery, ObjectPropertiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectPropertiesQuery, ObjectPropertiesQueryVariables>(ObjectPropertiesDocumentNode, options);
        }
export type ObjectPropertiesQueryHookResult = ReturnType<typeof useObjectPropertiesQuery>;
export type ObjectPropertiesLazyQueryHookResult = ReturnType<typeof useObjectPropertiesLazyQuery>;
export type ObjectPropertiesQueryResult = Apollo.QueryResult<ObjectPropertiesQuery, ObjectPropertiesQueryVariables>;
export const PropertiesOfObjectDocumentNode = gql`
    query propertiesOfObject($objectId: ID!) {
  propertiesOfObject(objectId: $objectId) {
    id
    property {
      id
      type
      constraints
      name
    }
    value
    updated_at
  }
}
    `;

/**
 * __usePropertiesOfObjectQuery__
 *
 * To run a query within a React component, call `usePropertiesOfObjectQuery` and pass it any options that fit your needs.
 * When your component renders, `usePropertiesOfObjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePropertiesOfObjectQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function usePropertiesOfObjectQuery(baseOptions: Apollo.QueryHookOptions<PropertiesOfObjectQuery, PropertiesOfObjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PropertiesOfObjectQuery, PropertiesOfObjectQueryVariables>(PropertiesOfObjectDocumentNode, options);
      }
export function usePropertiesOfObjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PropertiesOfObjectQuery, PropertiesOfObjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PropertiesOfObjectQuery, PropertiesOfObjectQueryVariables>(PropertiesOfObjectDocumentNode, options);
        }
export type PropertiesOfObjectQueryHookResult = ReturnType<typeof usePropertiesOfObjectQuery>;
export type PropertiesOfObjectLazyQueryHookResult = ReturnType<typeof usePropertiesOfObjectLazyQuery>;
export type PropertiesOfObjectQueryResult = Apollo.QueryResult<PropertiesOfObjectQuery, PropertiesOfObjectQueryVariables>;
export const TagsDocumentNode = gql`
    query tags($type: String!) {
  tags(type: $type) {
    id
    type
    name
    displayName {
      key
      text(locale: "en-US")
    }
    order
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocumentNode, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocumentNode, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagDocumentNode = gql`
    query tag($id: ID) {
  tag(id: $id) {
    id
    type
    name
    displayName {
      key
      text(locale: "en-US")
    }
    order
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions?: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocumentNode, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocumentNode, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const ObjectTypesDocumentNode = gql`
    query objectTypes {
  objectTypes {
    id
    displayName {
      text(locale: "en-US")
    }
  }
}
    `;

/**
 * __useObjectTypesQuery__
 *
 * To run a query within a React component, call `useObjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useObjectTypesQuery(baseOptions?: Apollo.QueryHookOptions<ObjectTypesQuery, ObjectTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectTypesQuery, ObjectTypesQueryVariables>(ObjectTypesDocumentNode, options);
      }
export function useObjectTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectTypesQuery, ObjectTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectTypesQuery, ObjectTypesQueryVariables>(ObjectTypesDocumentNode, options);
        }
export type ObjectTypesQueryHookResult = ReturnType<typeof useObjectTypesQuery>;
export type ObjectTypesLazyQueryHookResult = ReturnType<typeof useObjectTypesLazyQuery>;
export type ObjectTypesQueryResult = Apollo.QueryResult<ObjectTypesQuery, ObjectTypesQueryVariables>;
export const ObjectCreationMetaDataDocumentNode = gql`
    query objectCreationMetaData($landlordType: LandlordType) {
  objectTypes {
    id
    displayName {
      text(locale: "en-US")
    }
  }
  landlords(landlordType: $landlordType) {
    id
    company {
      name
    }
    user {
      email
    }
  }
}
    `;

/**
 * __useObjectCreationMetaDataQuery__
 *
 * To run a query within a React component, call `useObjectCreationMetaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectCreationMetaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectCreationMetaDataQuery({
 *   variables: {
 *      landlordType: // value for 'landlordType'
 *   },
 * });
 */
export function useObjectCreationMetaDataQuery(baseOptions?: Apollo.QueryHookOptions<ObjectCreationMetaDataQuery, ObjectCreationMetaDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectCreationMetaDataQuery, ObjectCreationMetaDataQueryVariables>(ObjectCreationMetaDataDocumentNode, options);
      }
export function useObjectCreationMetaDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectCreationMetaDataQuery, ObjectCreationMetaDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectCreationMetaDataQuery, ObjectCreationMetaDataQueryVariables>(ObjectCreationMetaDataDocumentNode, options);
        }
export type ObjectCreationMetaDataQueryHookResult = ReturnType<typeof useObjectCreationMetaDataQuery>;
export type ObjectCreationMetaDataLazyQueryHookResult = ReturnType<typeof useObjectCreationMetaDataLazyQuery>;
export type ObjectCreationMetaDataQueryResult = Apollo.QueryResult<ObjectCreationMetaDataQuery, ObjectCreationMetaDataQueryVariables>;
export const StatsDocumentNode = gql`
    query stats {
  stats {
    matches
    swipes
    objects
    users
  }
}
    `;

/**
 * __useStatsQuery__
 *
 * To run a query within a React component, call `useStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsQuery(baseOptions?: Apollo.QueryHookOptions<StatsQuery, StatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StatsQuery, StatsQueryVariables>(StatsDocumentNode, options);
      }
export function useStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StatsQuery, StatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StatsQuery, StatsQueryVariables>(StatsDocumentNode, options);
        }
export type StatsQueryHookResult = ReturnType<typeof useStatsQuery>;
export type StatsLazyQueryHookResult = ReturnType<typeof useStatsLazyQuery>;
export type StatsQueryResult = Apollo.QueryResult<StatsQuery, StatsQueryVariables>;
export const PutTenantSwipeDocumentNode = gql`
    mutation putTenantSwipe($objectId: ID!, $userId: ID!) {
  putTenantSwipe(objectId: $objectId, userId: $userId)
}
    `;
export type PutTenantSwipeMutationFn = Apollo.MutationFunction<PutTenantSwipeMutation, PutTenantSwipeMutationVariables>;

/**
 * __usePutTenantSwipeMutation__
 *
 * To run a mutation, you first call `usePutTenantSwipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePutTenantSwipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [putTenantSwipeMutation, { data, loading, error }] = usePutTenantSwipeMutation({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function usePutTenantSwipeMutation(baseOptions?: Apollo.MutationHookOptions<PutTenantSwipeMutation, PutTenantSwipeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PutTenantSwipeMutation, PutTenantSwipeMutationVariables>(PutTenantSwipeDocumentNode, options);
      }
export type PutTenantSwipeMutationHookResult = ReturnType<typeof usePutTenantSwipeMutation>;
export type PutTenantSwipeMutationResult = Apollo.MutationResult<PutTenantSwipeMutation>;
export type PutTenantSwipeMutationOptions = Apollo.BaseMutationOptions<PutTenantSwipeMutation, PutTenantSwipeMutationVariables>;
export const ObjectSwipesDocumentNode = gql`
    query objectSwipes($objectId: ID!, $filter: ObjectSwipesFilter) {
  objectSwipes(id: $objectId, filter: $filter) {
    id
    positive
    swipedAt
    user {
      id
      email
      pictures {
        id
        url(size: small)
      }
      values(properties: "firstName, lastName") {
        property {
          name
        }
        value
        mutable
      }
    }
    match {
      id
      chatId
      matchedAt
    }
  }
}
    `;

/**
 * __useObjectSwipesQuery__
 *
 * To run a query within a React component, call `useObjectSwipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useObjectSwipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useObjectSwipesQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useObjectSwipesQuery(baseOptions: Apollo.QueryHookOptions<ObjectSwipesQuery, ObjectSwipesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ObjectSwipesQuery, ObjectSwipesQueryVariables>(ObjectSwipesDocumentNode, options);
      }
export function useObjectSwipesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ObjectSwipesQuery, ObjectSwipesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ObjectSwipesQuery, ObjectSwipesQueryVariables>(ObjectSwipesDocumentNode, options);
        }
export type ObjectSwipesQueryHookResult = ReturnType<typeof useObjectSwipesQuery>;
export type ObjectSwipesLazyQueryHookResult = ReturnType<typeof useObjectSwipesLazyQuery>;
export type ObjectSwipesQueryResult = Apollo.QueryResult<ObjectSwipesQuery, ObjectSwipesQueryVariables>;
export const RejectAccountDeletionDocumentNode = gql`
    mutation rejectAccountDeletion($userId: ID!) {
  rejectAccountDeletion(userId: $userId)
}
    `;
export type RejectAccountDeletionMutationFn = Apollo.MutationFunction<RejectAccountDeletionMutation, RejectAccountDeletionMutationVariables>;

/**
 * __useRejectAccountDeletionMutation__
 *
 * To run a mutation, you first call `useRejectAccountDeletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectAccountDeletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectAccountDeletionMutation, { data, loading, error }] = useRejectAccountDeletionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRejectAccountDeletionMutation(baseOptions?: Apollo.MutationHookOptions<RejectAccountDeletionMutation, RejectAccountDeletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectAccountDeletionMutation, RejectAccountDeletionMutationVariables>(RejectAccountDeletionDocumentNode, options);
      }
export type RejectAccountDeletionMutationHookResult = ReturnType<typeof useRejectAccountDeletionMutation>;
export type RejectAccountDeletionMutationResult = Apollo.MutationResult<RejectAccountDeletionMutation>;
export type RejectAccountDeletionMutationOptions = Apollo.BaseMutationOptions<RejectAccountDeletionMutation, RejectAccountDeletionMutationVariables>;
export const ToggleUserVerifiedStatusDocumentNode = gql`
    mutation toggleUserVerifiedStatus($userId: ID!) {
  toggleUserVerifiedStatus(userId: $userId)
}
    `;
export type ToggleUserVerifiedStatusMutationFn = Apollo.MutationFunction<ToggleUserVerifiedStatusMutation, ToggleUserVerifiedStatusMutationVariables>;

/**
 * __useToggleUserVerifiedStatusMutation__
 *
 * To run a mutation, you first call `useToggleUserVerifiedStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleUserVerifiedStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleUserVerifiedStatusMutation, { data, loading, error }] = useToggleUserVerifiedStatusMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useToggleUserVerifiedStatusMutation(baseOptions?: Apollo.MutationHookOptions<ToggleUserVerifiedStatusMutation, ToggleUserVerifiedStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleUserVerifiedStatusMutation, ToggleUserVerifiedStatusMutationVariables>(ToggleUserVerifiedStatusDocumentNode, options);
      }
export type ToggleUserVerifiedStatusMutationHookResult = ReturnType<typeof useToggleUserVerifiedStatusMutation>;
export type ToggleUserVerifiedStatusMutationResult = Apollo.MutationResult<ToggleUserVerifiedStatusMutation>;
export type ToggleUserVerifiedStatusMutationOptions = Apollo.BaseMutationOptions<ToggleUserVerifiedStatusMutation, ToggleUserVerifiedStatusMutationVariables>;
export const UsersDocumentNode = gql`
    query users($filter: UserFilter, $take: Int, $page: Int) {
  users(filter: $filter, take: $take, page: $page) {
    data {
      email
      id
      landlordId
      currentUserMode
      onboardingFinished
      locale
      identityProvider
      signupDate
      pictures {
        id
        url(size: small)
      }
      cognitoStatus: status
      values(properties: ["firstName", "lastName", "locale", "status"]) {
        value
        property {
          name
        }
      }
      verified
      accountDeletionRequest {
        nameOfDeletionJob
        timeOfDeletion
      }
    }
    count {
      totalCount
      pages
      itemsPerPage
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocumentNode, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocumentNode, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserDocumentNode = gql`
    query user($userId: ID!) {
  user(id: $userId) {
    id
    email
    identityProvider
    phoneNumber
    cognitoStatus: status
    landlordId
    currentUserMode
    onboardingFinished
    locale
    signupDate
    pictures {
      id
      url(size: small)
      largestUrl: url(size: large)
    }
    documents {
      asset {
        url
      }
      displayName {
        text(locale: "en-US")
      }
    }
    values(
      properties: ["firstName", "lastName", "birthdate", "tenantDescription", "status"]
    ) {
      property {
        name
      }
      value
      mutable
    }
    verified
    accountDeletionRequest {
      timeOfDeletion
      nameOfDeletionJob
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocumentNode, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocumentNode, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;