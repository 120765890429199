import {
  Box,
  Flex,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React from 'react';
import { IApolloState, IDict } from '../../types/types';
import State from '../loading/State';

interface TableInnerContentProps {
  columns: Array<ColumnProps>;
  data: Map<string, any>[];
}

export const TableInnerContent = (props: TableInnerContentProps) => {
  const { columns, data } = props;
  return (
    <ChakraTable fontSize={'sm'} variant={'simple'} size={'sm'}>
      <Thead
        bg={mode('gray.100', 'gray.800')}
        position={'sticky'}
        top={0}
        zIndex={'10'}>
        <Tr>
          {columns.map((column, index) => (
            <Th whiteSpace={'nowrap'} scope={'col'} p={2} key={index}>
              {column.header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row: Map<string, any>, rowIndex) => (
          <Tr key={row.get('id') ?? rowIndex}>
            {columns.map((column, columnIndex) => {
              let cell;

              if (column.accessors.length > 1) {
                const cellData: IDict<any> = {};
                column.accessors.forEach((accessor) => {
                  cellData[accessor] = row.get(accessor);
                });
                cell = cellData;
              } else {
                cell = row.get(column.accessors[0]);
              }

              const element = cell !== undefined ? column.Cell?.(cell) ?? cell : null;
              return (
                <Td
                  whiteSpace={'nowrap'}
                  p={2}
                  key={columnIndex}
                  borderColor={mode('gray.200', 'gray.800')}>
                  {element}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  );
};

export interface ColumnProps {
  header: string;
  accessors: Array<string>;
  Cell?: any;
}

interface TableContentProps {
  apolloState: IApolloState;
  columns: Array<ColumnProps>;
  data?: Map<string, any>[];
}

export const TableContent = (props: TableContentProps) => {
  const { columns, data, apolloState } = props;

  return (
    <Box
      w={'full'}
      h={'full'}
      minH={'180px'}
      overflowX={'auto'}
      borderRadius={'0.25rem'}
      border={'1px'}
      borderColor={mode('gray.200', 'gray.800')}
      my={'3'}>
      <State {...apolloState}>
        {data && data.length > 0 && <TableInnerContent columns={columns} data={data} />}
        {data?.length === 0 && (
          <Flex w={'full'} h={'full'} justify={'center'} align={'center'}>
            No Results Found
          </Flex>
        )}
      </State>
    </Box>
  );
};
