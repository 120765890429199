import { FilterTypeEnum, IFilters, UserType } from '../../../types/types';

export type TUsersFilterAccessors = 'userType' | 'search';

export const userFilters: IFilters<TUsersFilterAccessors> = [
  {
    __typename: FilterTypeEnum.SELECT,
    label: 'User Type',
    options: [
      {
        label: 'All',
        value: null,
      },
      {
        label: 'Tenants',
        value: UserType.TENANT,
      },
      {
        label: 'Landlords',
        value: UserType.LANDLORD,
      },
    ],
    accessor: 'userType',
    disabled: true,
  },
  {
    __typename: FilterTypeEnum.SEARCH,
    label: 'Search',
    accessor: 'search',
    addedByDefault: true,
    hiddenInMenu: true,
  },
];
