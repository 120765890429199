import { toast } from 'react-toastify';

export const authToast = (
  status: 'login-success' | 'login-failed' | 'logout-success',
  id = 'authToastId'
) => {
  const type = status.includes('success') ? toast.TYPE.SUCCESS : toast.TYPE.ERROR;
  const content = status.split('-').join(' ');

  if (toast.isActive(id)) {
    toast.update(id, {
      type,
      render: content,
    });
  } else {
    toast(content, {
      type,
      toastId: id,
    });
  }
};
