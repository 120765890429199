import axios from 'axios';

const mimeTypeFromURL = (url: string) =>
  axios
    .get(url, { responseType: 'blob' })
    .then((response) => response.data)
    .then((blob) => {
      if (blob.type === undefined) {
        throw TypeError("Type of response can't be undefined");
      }
      return blob.type;
    })
    .catch((error) => {
      throw error;
    });

export default mimeTypeFromURL;
