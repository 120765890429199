import { IDict } from '../types/types';

export interface IMutationMessages {
  loading: string;
  error: string;
  success: string;
}

export interface IMutationInstructions {
  loading: boolean;
  error: boolean;
  success: boolean;
}

export const customMutationToastMessages: IDict<IMutationMessages> = {
  postChatMessage: {
    loading: 'Sending message',
    error: 'Failed to send message',
    success: 'Message sent',
  },
  toggleObject: {
    loading: 'Toggling object',
    error: 'Failed to toggle object',
    success: 'Object toggled',
  },
  updateInternalNote: {
    loading: 'Update internal note',
    error: 'Failed to update internal note',
    success: 'Internal note updated',
  },
  modifyObjectPropertyValues: {
    loading: 'Modifying property',
    error: 'Failed to modify property',
    success: 'Property modified',
  },
  createBaseObject: {
    loading: 'Creating object',
    error: 'Failed to create object',
    success: 'Object created',
  },
  putTenantSwipe: {
    loading: 'Making swipe',
    error: 'Swipe failed',
    success: 'Swipe successful',
  },
  uploadObjectPicture: {
    loading: 'Creating Upload Url',
    error: 'Failed to create Upload Url',
    success: 'Upload Url created',
  },
  multipleUploadObjectPicture: {
    loading: 'Creating Upload Urls',
    error: 'Failed to create Upload Urls',
    success: 'Upload Urls created',
  },
  deleteObjectPicture: {
    loading: 'Deleting image',
    error: 'Failed to delete image',
    success: 'Image deleted',
  },
  reorderObjectPictures: {
    loading: 'Reordering images',
    error: 'Failed to reorder images',
    success: 'Images reordered',
  },
};

export const defaultMutationToastMessages: IMutationMessages = {
  loading: 'Running mutation',
  error: "Mutation failed'",
  success: 'Mutation successful',
};

export const mutationToastMessagesAppendix: IMutationMessages = {
  loading: '...',
  error: '',
  success: '',
};

export const mutationToastInstructions: IDict<IMutationInstructions> = {
  multipleUploadObjectPicture: {
    loading: false,
    success: false,
  },
};

export const defaultMutationToastInstructions: IMutationInstructions = {
  loading: true,
  error: true,
  success: true,
};
