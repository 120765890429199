import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  Tooltip,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FiAlertCircle, FiArrowDown, FiRefreshCcw } from 'react-icons/all';
import { MessageBox } from './MessageBox';
import { ChatMessagesQuery, ChatQuery } from '../../graphql/types-and-hooks';
import { IUnsentMessage } from '../../types/types';

const scrollToBottom = (
  target: HTMLDivElement | null,
  options?: { behavior: 'auto' | 'smooth' | undefined }
) => {
  target?.scroll({
    top: target?.scrollHeight,
    behavior: options?.behavior,
  });
};

export interface MessageFeedProps {
  unsentMessages: IUnsentMessage[];
  messages?: ChatMessagesQuery['chatMessages'];
  me?: ChatQuery['chat']['participants'][0];
  participants: ChatQuery['chat']['participants'];
  retrySendingMessage: (id: string) => void;
}

const MessageFeed: React.FC<MessageFeedProps> = ({
  unsentMessages,
  participants,
  me,
  messages = [],
  retrySendingMessage,
}) => {
  const messageEl = useRef<HTMLDivElement>(null);
  const [showScrollIcon, setShowScrollIcon] = useState<boolean>(false);

  const handleScroll = ({ currentTarget }: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setShowScrollIcon(
      currentTarget.scrollHeight -
        currentTarget.scrollTop -
        currentTarget.offsetHeight >
        150
    );
  };

  useEffect(() => {
    if (messageEl) {
      scrollToBottom(messageEl.current);
    }
  }, [messages]);

  useEffect(() => {
    if (messageEl) {
      if (unsentMessages?.length) {
        scrollToBottom(messageEl.current);
      }
    }
  }, [unsentMessages]);

  return (
    <>
      <Box
        px={2}
        width={'full'}
        height={'full'}
        overflowY={'auto'}
        ref={messageEl}
        onScroll={(e) => handleScroll(e)}>
        {messages?.map((message, index) => (
          <MessageBox
            prevMessage={messages[index - 1]}
            nextMessage={messages[index + 1]}
            message={message}
            isMine={message.senderId === me?.id}
            isUnsent={false}
            key={message.id}
            participants={participants}
          />
        ))}
        {unsentMessages?.map((message, index) => (
          <Flex direction={'row'} align={'end'} key={message.id}>
            <MessageBox
              prevMessage={unsentMessages[index - 1]}
              nextMessage={unsentMessages[index + 1]}
              message={message}
              isMine={message.senderId === me?.id}
              isUnsent={true}
              participants={participants}
            />
            {message?.error && (
              <Flex pb={2} pl={1} direction={'column'}>
                <Box pb={1} cursor={'pointer'}>
                  <Box
                    color={'gray'}
                    as={FiRefreshCcw}
                    onClick={() => retrySendingMessage(message.id)}
                  />
                </Box>
                <Tooltip
                  label={message?.error?.message ?? 'Error sending message'}
                  aria-label={'Sending Error'}>
                  <Box pb={1}>
                    <Box color={mode('red.400', 'red.600')} as={FiAlertCircle} />
                  </Box>
                </Tooltip>
              </Flex>
            )}
          </Flex>
        ))}
      </Box>
      {showScrollIcon && (
        <Flex height={0} bg={'transparent'} justify={'flex-end'}>
          <Box mt={-12} mr={4} position={'absolute'}>
            <IconButton
              variant={'solid'}
              isRound={true}
              colorScheme={'blue'}
              aria-label={'Scroll Down'}
              size={'sm'}
              icon={<FiArrowDown />}
              onClick={() => scrollToBottom(messageEl.current, { behavior: 'smooth' })}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default MessageFeed;
