import {
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  InputGroup,
  Select,
  SkeletonText,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { usePagination } from '../../util/hooks/usePagination';
import State from '../loading/State';
import { useQuery } from '../../util/hooks/useQuery';
import { IApolloState, IDataCount } from '../../types/types';

interface TablePaginationInnerContentProps {
  dataCount: IDataCount;
}

const TablePaginationInnerContent = (props: TablePaginationInnerContentProps) => {
  const { dataCount } = props;
  const { page, take, prevPage, nextPage, setTake } = usePagination();

  return (
    <>
      <Flex justify={'center'} align={'center'}>
        <InputGroup w={'unset'} mr={2} size={'xs'} alignItems={'center'}>
          <FormLabel
            color={mode('gray.600', 'gray.400')}
            fontSize={'sm'}
            fontWeight={'unset'}
            m={0}
            mr={2}>
            Show:
          </FormLabel>
          <Select
            value={take}
            w={'max-content'}
            onChange={({ target: { value } }) => setTake(parseInt(value, 10))}>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>75</option>
          </Select>
        </InputGroup>
        <Text color={mode('gray.600', 'gray.400')} fontSize={'sm'}>
          {dataCount.itemsPerPage * (page - 1) + 1}-
          {dataCount.pages === page
            ? dataCount.totalCount
            : dataCount.itemsPerPage * page}
          {' of '}
          {dataCount.totalCount}
          {', Page '}
          {page}
          {' of '}
          {dataCount.pages}
        </Text>
      </Flex>
      {dataCount?.pages !== 1 && (
        <ButtonGroup variant={'outline'} size={'sm'}>
          <Button
            as={'a'}
            rel={'prev'}
            onClick={() => {
              if (!(page <= 1)) {
                prevPage();
              }
            }}
            disabled={page === 1}>
            Previous
          </Button>
          <Button
            as={'a'}
            rel={'next'}
            onClick={() => {
              if (!(dataCount?.pages === page)) {
                nextPage();
              }
            }}
            disabled={dataCount?.pages === page}>
            Next
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

interface TablePaginationProps extends Partial<TablePaginationInnerContentProps> {
  apolloState: IApolloState;
}

export const TablePagination = (props: TablePaginationProps) => {
  const { dataCount, apolloState } = props;

  const [noResults, setNoResults] = useState<boolean>(false);

  const { page } = usePagination();

  const { updateQuery } = useQuery();
  useEffect(() => {
    if (dataCount) {
      if (dataCount.pages === 0 || dataCount.totalCount === 0) {
        setNoResults(true);
      } else {
        if (page > dataCount.pages) {
          updateQuery({ page: dataCount.pages });
        } else if (page < 1) {
          updateQuery({ page: 1 });
        }
        setNoResults(false);
      }
    }
  }, [page, dataCount, updateQuery]);

  return (
    <Flex align={'center'} justify={'space-between'}>
      <State
        customLoadingElement={
          <SkeletonText
            width={'100px'}
            color={mode('gray.600', 'gray.400')}
            skeletonHeight={'4'}
            noOfLines={1}
          />
        }
        customErrorElement={''}
        {...apolloState}>
        {dataCount && !noResults && (
          <TablePaginationInnerContent dataCount={dataCount} />
        )}
      </State>
    </Flex>
  );
};
