import React, { useEffect, useState } from 'react';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { Box, Input, InputProps, Text } from '@chakra-ui/react';

const staticLibraries: (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[] = ['places'];

const simulateInput = (ref: React.RefObject<HTMLInputElement>, newValue: string) => {
  if (ref.current !== null) {
    Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    )?.set?.call(ref.current, newValue);
    ref.current?.dispatchEvent(new Event('input', { bubbles: true }));
  }
};

interface PlaceAutocompleteProps {
  children: React.ReactChild;
  inputProps: InputProps;
}

export const PlaceAutocomplete: React.FC<PlaceAutocompleteProps> = (props) => {
  const { children, inputProps } = props;
  const placeIdInputRef = React.useRef<HTMLInputElement>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCv7oynHtfZAO3G6qEFyxZvmdw58XsjX0U',
    libraries: staticLibraries,
  });
  const [place, setPlace] = useState<google.maps.places.PlaceResult>();
  const [autocomplete, setAutocomplete] =
    React.useState<google.maps.places.Autocomplete>();

  useEffect(() => {
    simulateInput(placeIdInputRef, place?.place_id ?? '');
  }, [place]);

  return isLoaded ? (
    <Box w={'full'}>
      <Input ref={placeIdInputRef} {...inputProps} hidden />
      <Autocomplete
        fields={['place_id', 'formatted_address']}
        onPlaceChanged={() => {
          setPlace(autocomplete?.getPlace() ?? {});
        }}
        onLoad={(onLoadAutocomplete) => {
          setAutocomplete(onLoadAutocomplete);
        }}>
        {children}
      </Autocomplete>
      {place && (
        <>
          <Text color={'gray'} fontSize={'xs'}>
            Place ID: {place.place_id}
          </Text>
          <Text color={'gray'} fontSize={'xs'}>
            Address: {place.formatted_address}
          </Text>
        </>
      )}
    </Box>
  ) : null;
};
