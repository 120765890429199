import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import React, {
  ProviderProps,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { ImageUploadToastContent } from '../../components/toast-content/ImageUploadToastContent';
import { FileUploadStatus, IFileUpload, IUploadGroup } from '../../types/types';
import useAuth from '../hooks/useAuth';
import { dismiss } from '../toast/dismiss';

const initialUploadState: IFileUpload[] = [];

export const defaultUploadGroup = {
  id: 'default',
  title: '',
};

type Action =
  | { type: 'reset' }
  | {
      type: 'removeFile';
      id: string;
    }
  | { type: 'updateFile'; id: string; file: Partial<IFileUpload> }
  | { type: 'uploading'; id: string }
  | { type: 'success'; id: string }
  | { type: 'failed'; id: string }
  | {
      type: 'addFiles';
      files: File[];
      uploadGroupId?: string;
      urls: string[];
      uploadFunction: (url: string, file: IFileUpload) => Promise<void>;
      pathToMatchWhenRefetching?: string;
    }
  | {
      type: 'addFilesLazy';
      filesWithId: { id: string; file: File }[];
      uploadGroupId?: string;
      pathToMatchWhenRefetching?: string;
    }
  | {
      type: 'removeFiles';
      files: IFileUpload[];
    }
  | { type: 'updateProgress'; id: string; progress: number }
  | { type: 'removeSuccessOrFailed' }
  | {
      type: 'addUploadUrl';
      id: string;
      uploadFunction: (url: string, file: IFileUpload) => Promise<void>;
      url: string;
    }
  | { type: 'retryUpload'; id: string };

const reducer = (state: IFileUpload[], action: Action) => {
  const files = state;

  switch (action.type) {
    case 'reset':
      files.forEach((file) => {
        if (file.uploadStatus === FileUploadStatus.UPLOADING)
          file.cancelSource.cancel('cancelled by user due to sign out');
      });
      return [];
    case 'updateFile':
      return files.map((file) =>
        file.id === action.id ? { ...file, ...action.file } : file
      );
    case 'updateProgress':
      return files.map((file) =>
        file.id === action.id ? { ...file, progress: action.progress } : file
      );
    case 'uploading':
      return files.map((file) =>
        file.id === action.id
          ? { ...file, uploadStatus: FileUploadStatus.UPLOADING }
          : file
      );
    case 'success':
      return files.map((file) =>
        file.id === action.id
          ? { ...file, uploadStatus: FileUploadStatus.SUCCESS }
          : file
      );
    case 'failed':
      return files.map((file) =>
        file.id === action.id
          ? { ...file, uploadStatus: FileUploadStatus.FAILED }
          : file
      );
    case 'addUploadUrl':
      return files.map((file) =>
        file.id === action.id
          ? {
              ...file,
              uploadUrl: action.url,
              uploadFunction: action.uploadFunction,
              cancelSource: axios.CancelToken.source(),
            }
          : file
      );
    case 'retryUpload':
      return files.map((file) =>
        file.id === action.id
          ? {
              ...file,
              uploadStatus: FileUploadStatus.NOT_STARTED,
              cancelSource: axios.CancelToken.source(),
              progress: 0,
            }
          : file
      );
    case 'addFiles':
      return [
        ...files,
        ...((action.files.map((file, index) => ({
          file,
          url: URL.createObjectURL(file),
          id: uuidv4(),
          cancelSource: axios.CancelToken.source(),
          uploadStatus: FileUploadStatus.NOT_STARTED,
          uploadUrl: action.urls[index],
          uploadFunction: action.uploadFunction,
          uploadGroupId: action?.uploadGroupId ?? defaultUploadGroup.id,
          pathToMatchWhenRefetching: action.pathToMatchWhenRefetching,
        })) as IFileUpload[]) ?? []),
      ];
    case 'addFilesLazy':
      return [
        ...files,
        ...((action.filesWithId.map(({ file, id }) => ({
          file,
          url: URL.createObjectURL(file),
          id,
          uploadStatus: FileUploadStatus.NOT_STARTED,
          uploadGroupId: action?.uploadGroupId ?? defaultUploadGroup.id,
          pathToMatchWhenRefetching: action.pathToMatchWhenRefetching,
        })) as IFileUpload[]) ?? []),
      ];
    case 'removeFiles':
      return files.filter(
        (file) => !action.files.some((fileToRemove) => fileToRemove.id === file.id)
      );
    case 'removeSuccessOrFailed':
      return files.filter(
        (file) =>
          !(
            file.uploadStatus === FileUploadStatus.FAILED ||
            file.uploadStatus === FileUploadStatus.SUCCESS
          )
      );
    default:
      return files;
  }
};

export const UploadContext = React.createContext<{
  files: IFileUpload[];
  dispatch: React.Dispatch<Action>;
  toastId?: React.ReactText;
  uploadGroups: IUploadGroup[];
  addUploadGroup: (uploadGroup: IUploadGroup) => void;
} | null>(null);

export const useUploadContext = () => {
  const context = React.useContext(UploadContext);
  if (context === null) {
    throw new Error('useUploadContext must be within UploadProvider');
  }
  return context;
};

export const UploadProvider = (props: Partial<ProviderProps<any>>) => {
  const [files, dispatch] = useReducer(reducer, initialUploadState);
  const [toastId, setToastId] = useState<React.ReactText>();
  const [uploadGroups, setUploadGroups] = useState<IUploadGroup[]>([
    defaultUploadGroup,
  ]);
  const location = useLocation();
  const client = useApolloClient();
  const { user } = useAuth();

  const initToast = useCallback(() => {
    setToastId(
      toast(() => <ImageUploadToastContent />, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        onClose: () => {
          setToastId(undefined);
          dispatch({ type: 'removeSuccessOrFailed' });
        },
      })
    );
  }, []);

  const addUploadGroup = (uploadGroup: IUploadGroup) =>
    setUploadGroups((oldState) =>
      oldState.findIndex((uG) => uG.id === uploadGroup.id) === -1
        ? [...oldState, uploadGroup]
        : oldState
    );

  useEffect(() => {
    if (user === null) {
      dispatch({ type: 'reset' });
      dismiss(toastId);
      setToastId(undefined);
    }
  }, [user, toastId]);

  useEffect(() => {
    if (files.length > 0) {
      if (!toastId) {
        initToast();
      }
    } else if (files.length === 0) {
      dismiss(toastId);
    }

    const notStarted = files.filter(
      (file) => file.uploadStatus === FileUploadStatus.NOT_STARTED
    );

    if (notStarted.length > 0) {
      notStarted.forEach((file) => {
        if (file?.uploadUrl && file?.uploadFunction) {
          file.uploadFunction(file.uploadUrl, file).then(() => {
            setTimeout(() => {
              if (location.pathname === file.pathToMatchWhenRefetching) {
                client.refetchQueries({
                  include: 'active',
                });
              }
            }, 3000);
          });
          dispatch({ type: 'uploading', id: file.id });
        }
      });
    }

    const shouldRefetch = files.some(
      (file) =>
        file.uploadStatus === FileUploadStatus.SUCCESS &&
        location.pathname === file.pathToMatchWhenRefetching
    );

    if (shouldRefetch) {
      client.refetchQueries({
        include: 'active',
      });
    }
  }, [initToast, files, toastId, client, location]);

  return (
    <UploadContext.Provider
      {...props}
      value={React.useMemo(
        () => ({
          files,
          toastId,
          dispatch: (action: Action) => {
            if (user === null) {
              throw new Error(
                'useUploadContext must only be used when user is logged in'
              );
            } else {
              dispatch(action);
            }
          },
          uploadGroups,
          addUploadGroup: (uploadGroup) => {
            if (user === null) {
              throw new Error(
                'useUploadContext must only be used when user is logged in'
              );
            } else {
              addUploadGroup(uploadGroup);
            }
          },
        }),
        [files, toastId, dispatch, uploadGroups, user]
      )}
    />
  );
};
