import React, { useCallback, useRef } from 'react';
import { AspectRatio, Box, Flex, Heading, Image, Stack, Text } from '@chakra-ui/react';
import moment from 'moment';
import { toBlob } from 'html-to-image';
import { toast } from 'react-toastify';
import logo from '../../assets/images/mietz_app_logo.png';
import { createUserPdf } from '../user-pdf-creator';

interface UserPdfCoverProps {
  userData: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    birthdate: string;
    tenantDescription: string;
    status: string;
    locale: string;
    imageUrl: string;
  };
  scale?: 1 | 2 | 3 | 4 | 5;
}

const UserPdfCover = React.forwardRef<HTMLDivElement, UserPdfCoverProps>(
  ({ userData, scale = 2 }, ref) => {
    return (
      <Box overflow={'hidden'} height={0} width={0}>
        <Box
          ref={ref}
          width={`calc(800px * ${scale})`}
          height={`calc(1120px * ${scale})`}>
          <Box
            width={'800px'}
            height={'1120'}
            bg={'#F4F4F4'}
            padding={'50px'}
            transform={`scale(${scale}) translate(calc((50% / ${scale}) * ${
              scale - 1
            }), calc((50% / ${scale}) * ${scale - 1}))`}>
            <Flex
              fontSize={'sm'}
              color={'black'}
              fontWeight={'semibold'}
              width={'100%'}
              justifyContent={'space-between'}>
              <Flex>
                <Box overflow={'hidden'} borderRadius={12} w={'80px'} h={'80px'}>
                  <Image src={logo} w={'80px'} h={'80px'} />
                </Box>
                <Flex ml={6} justifyContent={'center'} direction={'column'}>
                  <Text>Mietz Gmbh</Text>
                  <Text mt={1}>Rheinsberger Str. 76/77</Text>
                  <Text>10115 Berlin</Text>
                </Flex>
              </Flex>
              <Flex justifyContent={'center'} direction={'column'} alignItems={'end'}>
                <Text>Exportiert am</Text>
                <Text mt={1} fontWeight={'normal'}>
                  {moment().format('dddd, MMM Do YYYY')}
                </Text>
                <Text fontWeight={'normal'}>{moment().format('HH:mm:ss [GMT]Z')}</Text>
              </Flex>
            </Flex>
            <Flex
              overflow={'hidden'}
              mt={'50px'}
              borderRadius={12}
              width={'100%'}
              height={'320px'}
              color={'white'}
              bg={'#1A232E'}>
              <AspectRatio ratio={8 / 9} maxW={'320px'} minW={'320px'} width={'320px'}>
                <Image src={userData?.imageUrl} />
              </AspectRatio>
              <Flex
                direction={'column'}
                width={'100%'}
                height={'100%'}
                padding={'50px'}>
                <Heading
                  textTransform={
                    'uppercase'
                  }>{`${userData.firstName} ${userData.lastName}`}</Heading>
                <Text mt={2} fontSize={'lg'} textTransform={'capitalize'}>
                  {userData.status}
                </Text>
                <Text mt={2} fontSize={'xs'}>
                  {userData.tenantDescription}
                </Text>
              </Flex>
            </Flex>
            <Flex
              overflow={'hidden'}
              mt={'50px'}
              borderRadius={12}
              width={'100%'}
              padding={'40px'}
              color={'white'}
              fontSize={'xl'}
              bg={'#1A232E'}>
              <Stack fontWeight={'semibold'}>
                <Text>Email:</Text>
                <Text>Telefon:</Text>
                <Text>Sprache:</Text>
                <Text>Geburtsdatum:</Text>
              </Stack>
              <Stack ml={20}>
                <Text>{userData?.email ?? '-'}</Text>
                <Text>{userData?.phoneNumber ?? '-'}</Text>
                <Text>{userData?.locale ?? '-'}</Text>
                <Text>{userData?.birthdate ?? '-'}</Text>
              </Stack>
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  }
);

interface IUseUserPdf extends UserPdfCoverProps {
  documentName: string;
}

export const useUserPdf = ({ userData, documentName, scale }: IUseUserPdf) => {
  const ref = useRef<HTMLDivElement>(null);

  const exportPdf = useCallback(
    (documents?: string[]) => {
      if (ref.current === null) {
        toast.error('Failed to export PDF');
        return;
      }

      toBlob(ref.current, { cacheBust: true })
        .then((blob) => {
          if (blob !== null) {
            return createUserPdf(blob, documentName, documents);
          }
          throw new Error('htmlToImage.toBlob() returned null');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error('Failed to export PDF', err);
          toast.error(`Failed to export PDF`);
        });
    },
    [ref, documentName]
  );

  return {
    exportPdf,
    UserPdfCoverComponent: () =>
      React.createElement(UserPdfCover, { userData, scale, ref }),
  };
};
